import { createAction } from "redux-actions";
import {
  register,
  registerSchool,
  registerProfessor,
  registerParent,
  login,
  textbook,
  challenges,
  koolskools_activities,
  koolskools_exams,
  koolskools_school_books,
  challengesFriendsScore,
  levels,
  changeLevels,
  avatar,
  changeAvatar,
  changePassword,
  listFriends,
  uploadImage,
  listImage,
  challengeListExercice,
  CRUDFriend,
  editProfile,
  challengeXblock,
  chaptersList,
  textbookValidate,
  chapterItem,
  exerciseOfStage,
  forgotPassword,
  levelGroups,
  loginWithClasse,
  statistics
} from "./api";
import { get, remove } from "./storage";

export const REGISTER = "session/REGISTER";
export const REGISTERSCHOOL = "session/REGISTERSCHOOL";
export const REGISTERPARENT = "session/REGISTERPARENT";
export const REGISTERPROF = "session/REGISTERPROF";
export const LOGOUT_SUCCESS = "session/LOGOUT_SUCCESS";
export const LOGINPROF = "session/LOGINPROF";
export const LOGIN = "session/LOGIN";
export const LOGINWITHCLASSE = "session/LOGINWITHCLASSE";
export const FORGOTPASSWORD = "session/FORGOTPASSWORD";
export const TEXTBOOK = "session/TEXTBOOK";
export const TEXTBOOKVALIDATE = "session/TEXTBOOKVALIDATE";
export const CHELLENGE = "session/CHELLENGE";
export const CHELLENGELISTEXERCICE = "session/CHELLENGELISTEXERCICE";
export const CHELLENGESCORE = "session/CHELLENGESCORE";
export const STATISTICS = "session/STATISTICS";
export const CHELLENGEXBLOCK = "session/CHELLENGEXBLOCK";
export const LEVELS = "session/LEVELS";
export const CHANGELEVEL = "session/CHANGELEVEL";
export const AVATAR = "session/AVATAR";
export const CHANGEAVATAR = "session/CHANGEAVATAR";
export const CHANGEPASSWORD = "session/CHANGEPASSWORD";
export const LISTFRIENDS = "session/LISTFRIENDS";
export const UPLOADIMAGE = "session/UPLOADIMAGE";
export const LISTIMAGES = "session/LISTIMAGES";
export const CRUDFFRIEND = "session/CRUDFFRIEND";
export const EDITPROFILE = "session/EDITPROFILE";
export const CHAPTERSLIST = "session/CHAPTERSLIST";
export const CHAPTERITEM = "session/CHAPTERITEM";
export const EXERCISESTAGE = "session/EXERCISESTAGE";
export const MODIFIER = "session/MODIFIER";
export const MODIFIERFIRSTNAME = "session/MODIFIERFIRSTNAME";
export const CHANGEAIMAGEVATAR = "session/CHANGEAIMAGEVATAR";
export const LEVELGROUPS = "session/LEVELGROUPS";

// professeur
// export const loginProfessor = createAction(LOGINPROF,  obj => {
// //    return  dispatch => {
// //         dispatch(checkAuthTimeout());
// //         login(obj);
// //    };
// });

export const loginProfessor = createAction(LOGIN, async obj => {
  return await login(obj);
});

export const RegisterParent = createAction(REGISTERPARENT, async obj => {
  return registerParent(obj);
});

export const RegisterSchool = createAction(REGISTERSCHOOL, async obj => {
  return registerSchool(obj);
});

export const RegisterProfessor = createAction(REGISTERPROF, async obj => {
  return registerProfessor(obj);
});

export const RegisterAction = createAction(REGISTER, async obj => {
  return register(obj);
});

export const loginAction = createAction(LOGIN, async obj => {
  return await login(obj);
});

export const loginWithClasseAction = createAction(
  LOGINWITHCLASSE,
  async obj => {
    const user = get("session_user");
    return await loginWithClasse(user.token, obj);
  }
);

export const levelGroupsAction = createAction(LEVELGROUPS, async () => {
  return await levelGroups();
});

export const forgotPasswordAction = createAction(
  FORGOTPASSWORD,
  async password => {
    return await forgotPassword(password);
  }
);

export const textbookAction = createAction(
  TEXTBOOK,
  async (classId, subjectId, data) => {
    const user = get("session_user");
    return await textbook(user.token, classId, subjectId, data);
  }
);

export const textbookValidateAction = createAction(
  TEXTBOOKVALIDATE,
  async (data) => {
    return await textbookValidate(data);
  }
);

export const challengesAction = createAction(CHELLENGE, async () => {
  const user = get("session_user");
  return await challenges(user.token);
});

export const koolskoolsActivitiesAction = createAction(CHELLENGE, async (object) => {
  const user = get("session_user");
  return await koolskools_activities(user.token, object);
});

export const koolskoolsExamsAction = createAction(CHELLENGE, async (object) => {
  const user = get("session_user");
  return await koolskools_exams(user.token, object);
});

export const koolskoolsSchoolBooksAction = createAction(CHELLENGE, async (object) => {
  const user = get("session_user");
  return await koolskools_school_books(user.token, object);
});

export const challengesListExerciceAction = createAction(
  CHELLENGELISTEXERCICE,
  async type => {
    const user = get("session_user");
    return await challengeListExercice(user.token, type);
  }
);

export const levelsAction = createAction(LEVELS, async () => {
  const user = get("session_user");
  return await levels(user.token);
});

export const changelevelsAction = createAction(CHANGELEVEL, async idLevel => {
  const user = get("session_user");
  return await changeLevels(user.token, idLevel);
});

export const avatarAction = createAction(AVATAR, async () => {
  const user = get("session_user");
  return await avatar(user.token);
});

export const EditProfileAction = createAction(
  EDITPROFILE,
  async (username, obj) => {
    const user = get("session_user");
    return await editProfile(user.token, username, obj);
  }
);

export const changeAvatarAction = createAction(CHANGEAVATAR, async slug => {
  const user = get("session_user");
  return await changeAvatar(user.token, slug);
});

export const changePasswordAction = createAction(CHANGEPASSWORD, async obj => {
  const user = get("session_user");
  return await changePassword(user.token, obj);
});

export const challengesFriendsScoreAction = createAction(
  CHELLENGESCORE,
  async () => {
    const user = get("session_user");
    return await challengesFriendsScore(user.token);
  }
);

export const statisticsAction = createAction(STATISTICS, async data => {
  const user = get("session_user");
  return await statistics(user.token, data);
});

export const challengesXblockAction = createAction(
  CHELLENGEXBLOCK,
  async (course, exercise, data) => {
    const user = get("session_user");
    return await challengeXblock(user.token, course, exercise, data);
  }
);

// export const submitXblockAction = createAction(
//   CHELLENGEXBLOCK,
//   async (course, exercise, data) => {
//     const user = get("session_user");
//     return await submitXblock(user.token, course, exercise, data);
//   }
// );

export const listFriendsAction = createAction(LISTFRIENDS, async () => {
  const user = get("session_user");
  return await listFriends(user.token);
});

export const CRUDFriendsAction = createAction(CRUDFFRIEND, async friend => {
  const user = get("session_user");
  return await CRUDFriend(user.token, friend);
});

export const listImagesAction = createAction(LISTIMAGES, async (data) => {
  const user = get("session_user");
  return await listImage(user.token, data);
});

export const uploadImageAction = createAction(UPLOADIMAGE, async file => {
  return await uploadImage(file);
});

export const chaptersListAction = createAction(
  CHAPTERSLIST,
  async (levelId, subjectId) => {
    return await chaptersList(levelId, subjectId);
  }
);

export const chapterItemAction = createAction(CHAPTERITEM, async chapterId => {
  const user = get("session_user");
  return await chapterItem(user.token, chapterId);
});

export const exerciseOfStageAction = createAction(
  EXERCISESTAGE,
  async stageId => {
    return await exerciseOfStage(stageId);
  }
);

export const logoutAction = () => ({
  type: LOGOUT_SUCCESS
});

export const MODIFIERAction = classLevel => ({
  type: MODIFIER,
  classLevel
});

export const checkAuthTimeout = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logoutAction());
    }, 3000);
  };
};
