import React, { Component } from "react";
import Modal from "react-responsive-modal";

import "react-datepicker/dist/react-datepicker.css";
import { withTranslation, Trans } from 'react-i18next';

 class FonctionalityNotExistModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      added_message: props.addedMessage
    };
  }

  componentWillReceiveProps(newProps){
    if(this.state.added_message !== newProps.addedMessage){
      this.setState({
        added_message: newProps.addedMessage
      });
    }
  }
  render() {
    const {added_message} = this.state;

    return (
      <>
        <Modal
          open={this.props.open}
          onClose={() => this.props.handleCloseModal()}
          showCloseIcon={false}
          center
          classNames={{
            overlay: { dialogOverlay: "white", dialogFormOverlay: "white", dialogFonctionalityDontExist: "white" },
            modal: {
              dialog: "white",
              dialogAddSchool: "white",
              dialogParents: "white"
            }
          }}
        >
          <div className="add-school">
            <div className="add-school-top">
              <h2>{ this.props.t("Fonctionnalité non disponible") }</h2>
              <span
                className="schoolClose"
                onClick={() => this.props.handleCloseModal()}
              >
                &times;
              </span>
            </div>
            <div className="add-school-form">
              {this.props.t("Cette fonctionnalité n'est pas disponible pour le moment")}.
            </div>
            <div className="add-school-form">
              {added_message}.
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default withTranslation('translations')(FonctionalityNotExistModal);

