import store from 'store'
import axios from "axios";
import localforage from 'localforage';

const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });

export const set = (key, value) => {
  store.set(key, value)
}

export const get = (key) => {
  return store.get(key)
}

export const remove = (key) => {
  store.remove(key)
}

export const clear = () => {
  store.clearAll()
}


const unsubscribe_fcm_api = data => {
  return instance.post('/unsubscribe_fcm_api', data)
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}

export const logout = () => {
  var lev = get("level_groups"),
    current_language = get("current_language"),
    device_token = get('device_token'),
    topics = get('topics') || {};
  unsubscribe_fcm_api({
    device_token: device_token,
    topics : Object.keys(topics)
  })
  clear();
  localforage.clear()
  if(global.ws_notification)
    global.ws_notification.close();
  // set("device_token", device_token);
  set('topics', {});
  set("level_groups", lev);
  set("current_language", current_language);
  window.location = "/home";
}
