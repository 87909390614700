import React from "react"
import MediaQuery from "react-responsive"
import styled from "styled-components"
import Carousel from 'nuka-carousel';
import { withTranslation, Trans } from 'react-i18next';
import {colors} from "../../../../assets/variables/colors"
import {images} from "../_resources";

const AvantagesKoolskool= (props) => {
  // TODO: TRANSLATIONS
    return(
        <AvantagesKoolskools>
            <h3>{ props.t('Koolskools Advantages') } </h3>
            <div className="container">

                <MediaQuery query="(min-width: 768px)">
                    <div className="section-feeds">
                        <div className="section-feed feed-left">
                            <div className="news-img">
                                <img src={images.feedImage} className="img-fluid" />
                            </div>

                            <div className="news-article">
                                <p>Lance-toi en relevant
                                    des défis et gagne
                                    des récompenses
                                    amusantes à chaque
                                    étape. Plus tu
                                    t'entraîneras, plus tu
                                    en gagneras !</p>
                                <p className="news-date">06 DEC, 2019</p>
                                <p className="link planete">
                                <a href="">{ props.t('Awards') }</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                {/* <a href="">Récompenses</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}

                            </div>
                        </div>

                        <div className="section-feed feed-right">
                            <div className="news-img">
                                <img src={images.feedImage} className="img-fluid" />
                            </div>

                            <div className="news-article">
                                <p>Avec KoolsKools,
                                    apprends en t’amusant et
                                    augmente tes notes dans
                                    toutes les matières</p>
                                <p className="news-date">06 DEC, 2019</p>
                                {/* <p className="link monde"><a href="">Apprentissage</a> <i className="fa fa-long-arrow-left" aria-hidden="true"></i></p> */}
                                <p className="link monde"><a href="">{ props.t('Learning') }</a> <i className="fa fa-long-arrow-left" aria-hidden="true"></i></p>
                            </div>
                        </div>

                        <div className="section-feed feed-left">
                            <div className="news-img">
                                <img src={images.feedImage} className="img-fluid" />
                            </div>

                            <div className="news-article">
                                <p>Avec Kools’UP reste
                                    connecté avec tes
                                    amis et échanges des
                                    messages et des
                                    images de manière
                                    instantannée</p>
                                <p className="news-date">06 DEC, 2019</p>
                                <p className="link science"><a href="">Kools’UP</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div>
                        </div>

                        <div className="section-feed feed-right">
                            <div className="news-img">
                                <img src={images.feedImage} className="img-fluid" />
                            </div>

                            <div className="news-article">
                                <p>Des réponses instantanées te
                                    permettent de corriger tes
                                    erreurs et de mieux
                                    comprendre tes cours</p>
                                <p className="news-date">06 DEC, 2019</p>
                                <p className="link sport"><a href="">{ props.t('answers') }</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                {/* <p className="link sport"><a href="">Réponses</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}
                            </div>
                        </div>

                        <div className="section-feed feed-left">
                            <div className="news-img">
                                <img src={images.feedImage} className="img-fluid" />
                            </div>

                            <div className="news-article">
                                <p>Crée des challenges
                                    avec tes amis et
                                    gagnes un maximum
                                    de points</p>
                                <p className="news-date">06 DEC, 2019</p>
                                <p className="link cinema"><a href="">{ props.t('Challenges') }</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                {/* <p className="link cinema"><a href="">Challenges</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(max-width: 767px)">
                    <div className="section-feeds">
                        <Carousel>
                            <div className="section-feed">
                                <div className="news-img">
                                    <img src={images.feedImage} className="img-fluid" />
                                </div>
                                <div className="news-article">
                                    <p>Lance-toi en relevant
                                        des défis et gagne
                                        des récompenses
                                        amusantes à chaque
                                        étape. Plus tu
                                        t'entraîneras, plus tu
                                        en gagneras !</p>
                                    <p className="news-date">06 DEC, 2019</p>
                                    <p className="link planete"><a href=""> { props.t('Awards') }</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                    {/* <p className="link planete"><a href="">Récompenses</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}

                                </div>
                            </div>

                            <div className="section-feed">
                                <div className="news-img">
                                    <img src={images.feedImage} className="img-fluid" />
                                </div>
                                <div className="news-article">
                                    <p>Avec KoolsKools,
                                        apprends en t’amusant et
                                        augmente tes notes dans
                                        toutes les matières</p>
                                    <p className="news-date">06 DEC, 2019</p>
                                    <p className="link monde"><a href=""> { props.t('Learning') }</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                    {/* <p className="link monde"><a href="">Apprentissage</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}

                                </div>
                            </div>

                            <div className="section-feed">
                                <div className="news-img">
                                    <img src={images.feedImage} className="img-fluid" />
                                </div>
                                <div className="news-article">
                                    <p>Avec Kools’UP reste
                                        connecté avec tes
                                        amis et échanges des
                                        messages et des
                                        images de manière
                                        instantannée</p>
                                    <p className="news-date">06 DEC, 2019</p>
                                    <p className="link science"><a href="">Kools’UP</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                </div>
                            </div>

                            <div className="section-feed">
                                <div className="news-img">
                                    <img src={images.feedImage} className="img-fluid" />
                                </div>
                                <div className="news-article">
                                    <p>Des réponses instantanées te
                                        permettent de corriger tes
                                        erreurs et de mieux
                                        comprendre tes cours</p>
                                    <p className="news-date">06 DEC, 2019</p>
                                    <p className="link sport"><a href="">{ props.t('answers') }</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                    {/* <p className="link sport"><a href="">Réponses</a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p> */}

                                </div>
                            </div>

                            <div className="section-feed">
                                <div className="news-img">
                                    <img src={images.feedImage} className="img-fluid" />
                                </div>
                                <div className="news-article">
                                    <p>Crée des challenges
                                        avec tes amis et
                                        gagnes un maximum
                                        de points</p>
                                    <p className="news-date">06 DEC, 2019</p>
                                    <p className="link cinema"><a href="">{ props.t('Challenges') } </a> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                </div>
                            </div>

                        </Carousel>
                    </div>
                </MediaQuery>

            </div>
        </AvantagesKoolskools>
    )
}

const AvantagesKoolskools = styled.section`
    background-color: ${colors.primary};
    padding: 100px 0 145px;
    position: relative;
    overflow: hidden;
    &:before{
      content: "";
      background: ${colors.secondary};
      border-radius: 100%;
      position: absolute;
      top: -50px;
      bottom: -50px;
      width: 70%;
      right: -400px;
    }
    h3{
      font-family: 'Arvo', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      text-align: right;
      color: ${colors.white};
      padding: 21px 45px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      background-color: rgba(255, 255, 255, 0.31);
      position: absolute;
      right: 0;
      top: 74px;
      z-index: 999;
      margin: 0;
    }
    .section-feeds{
      position: relative;
      z-index: 999;
      padding: 0 110px;
      .section-feed{
        max-width: 45%;
        box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.2);
        margin-top: -20px;
        border-radius: 7px;
        .news-img{
          width: 53%;
          height: 160px;
          overflow: hidden;
          img{
            height: 100%;
          }
        }
        &.feed-left{
          float: left;
          margin-right: 60px;
          .news-img{
            float: right;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
          }
          .news-article{
            float: left;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            p{
              text-align: left;
              &.link{
                a{
                  float: left;
                }
                i{
                  margin-left: 0;
                  float: right;
                }
              }
            }
          }
        }
        &.feed-right{
          float: right;
          margin-left: 60px;
          .news-img{
            float: left;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
          }
          .news-article{
            float: right;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            p{
              text-align: right;
              &.link{
                a{
                  float: right;
                }
                i{
                  float: left;
                  margin-right: 0;
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
        .news-article{
          height: 160px;
          background-color: ${colors.white};
          position: relative;
          width: 47%;
          padding: 9px 12px;
          &:before{
            content: "";
            position: absolute;
            bottom: -87px;
            left:0;
            background: url(${images.NewsFeed});
            background-size: cover;
            width: 100%;
            min-height: 120px;
            z-index: 0;
            -webkit-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            -moz-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            -ms-filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.15));
            -o-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
          }
          &:after{
            content: "";
            position: absolute;
            bottom: 0;
            background: ${colors.white};
            left: 0;
            right: 0;
            height: 14px;
            border-radius: 10px;
            z-index: 999;
          }
          p{
            opacity: 0.9;
            font-family: 'Montserrat', sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: normal;
            color: #000000;
            margin: 0;
            &.news-date{
              font-size: 10px;
              margin-top: 5px;
              font-weight: 500;
              letter-spacing: 0.6px;
              color: #b9b9b9;
            }
            &.link{
              opacity: 0.9;
              font-family: 'Montserrat', sans-serif;
              font-size: 12px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.09;
              letter-spacing: 0.2px;
              color: ${colors.white};
              position: absolute;
              bottom: 9px;
              left: 12px;
              right: 12px;
              z-index: 99999;
              a{
                color: ${colors.white};
                padding: 0 12px;
                border-radius: 25px;
                line-height: 24px;
                font-size: 11.5px;
                min-width: 127px;
                text-align: center;
                text-transform: uppercase;
              }
              i{
                margin-top: 9px;
                font-size: 22px;
                content: '';
                width: 39px;
                height: 8px;
                display: inline-block;
                -webkit-mask: url(${images.ArrowNews}) no-repeat 50% 50%;
                mask: url(${images.ArrowNews}) no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                position: relative;
              }
              &.planete{
                a,i{
                  background-color: #00b0c4;
                }
              }
              &.monde{
                a,i{
                  background-color: #e62721;
                }
              }
              &.science{
                a,i{
                  background-color: #ff6735;
                }
              }
              &.sport{
                a,i{
                  background-color: #9172ff;
                }
              }
              &.cinema{
                a,i{
                  background-color: #ffa400;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 1199px){
      text-align: center;
      padding: 40px 0 85px;
      h3{
        position: relative;
        margin: 0 0 53px;
        top: 0;
        border: 1.5px solid ${colors.white};
        background: ${colors.primary};
        display: inline-block;
        padding: 12px 30px;
        border-radius: 50px;
        font-size: 17px;
      }
      .section-feeds{
        padding: 0 60px;
        .section-feed{
          max-width: 47%;
          .news-article{
            &:before{
              left: 5%;
              width: 90%;
              bottom: -85px;
            }
            p{
              font-size: 11px;
              line-height: 12px;
              &.news-date{
                display: none;
              }
              &.link{
                a{
                  font-size: 9px;
                  min-width: 110px;
                }
              }
            }
          }
          &.feed-left{
            margin-right: 30px;
          }
          &.feed-right{
            margin-left: 30px;
          }
        }
      }
    }
    @media (max-width: 992px) {
      &:before{
        width: 80%;
      }
      .section-feeds {
        padding: 0;
        .section-feed {
          .news-article{
            p{
              &.link{
                a{
                  min-width: 92px;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    
   @media (max-width: 767px) {
      &:before{
        width:450px;
        right: -150px;
      }
      h3{
        margin: 0 0 30px;
      }
      .section-feeds {
        padding-bottom: 15px;
        .slider-frame{
            overflow: inherit!important;
            margin: 0 auto!important;
        }
        .section-feed{
          max-width: 290px;
          margin: auto;
          border-radius: 6px;
          padding-bottom: 10px;
          background: #fff;
          .news-img{
            width: 100%;
            height: 220px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            box-shadow: 0px 0px 15px 0px rgba(0,0,0,.4);
          }
          .news-article{
            width: 100%;
            height: auto;
            padding-bottom: 40px;
            &:before{
              left: 0;
              width: 112px;
              bottom: -83px;
              min-height: 94px;
            }
            &:after{
              bottom: -6px;
            }
            p{
              font-size: 12px;
              line-height: 16px;
              text-align: left;
              &.link{
                text-align: right;
                a{
                  padding: 5px 10px;
                  font-size: 10px;
                }
                i{
                  margin: 0;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
      .slider-control-bottomcenter{
        bottom: -50px!important;
        right: 31px!important;
        transform: translateX(0%)!important;
        ul{
          li{
            button{
              font-size: 53px!important;
              line-height: 38px!important;
              color: #297a74!important;
              padding: 2px!important;
            }
          }
        }
      }
      .slider-control-centerright,.slider-control-centerleft{
        button{
          background: ${colors.secondary} !important;
          padding: 0!important;
          font-size: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 5px solid ${colors.white} !important;
          position: relative;
          &:before{
            font: normal normal normal 14px/1 FontAwesome;
            color: ${colors.white};
            line-height: 14px;
            font-size: 24px
          }
        }
      }
      .slider-control-centerright{
        button{
          &:before{
            content: "\\f105";
          }
        }
      }
      .slider-control-centerleft{
        button{
          &:before{
            content: "\\f104";
          }
        }
      }
   }
   @media(max-width: 370px){
      h3{
        font-size: 15px;
      }
      .section-feeds{
         .section-feed{
            max-width: 260px;
         }
      }
   }
`
export default withTranslation('translations')(AvantagesKoolskool);
