import video from "../../assets/video/home.mp4";
import video_fmps from "../../assets/video/home_fmps.mp4";
import logo_laraki from "../../assets/img/logo_laraki.png";
import home_laraki from "../../assets/img/home_laraki.png";
import login_background from "../../assets/img/fmps/login_background.png";
import menu_home_connexion from "../../assets/img/fmps/menu_home_connexion.png";
import bg_unconnected from "../../assets/img/fmps/bg_unconnected.jpg";
import all_plateforme_img from "../../assets/img/fmps/all_plateforme_img.png";
import logo_fmps from "../../assets/img/fmps/logo_fmps.png";
import logo_atfalouna_learning from "../../assets/img/fmps/logo_atfalouna_learning.png";
import home_form from "../../assets/img/fmps/home_form.png";

export const videos = {
  video, video_fmps, logo_laraki, home_laraki
};

export const images = {
  login_background,
  menu_home_connexion,
  bg_unconnected,
  all_plateforme_img,
  logo_fmps,
  logo_atfalouna_learning,
  home_form
}