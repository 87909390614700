import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { images } from "../_resources";
import TextInput from "../../../../components/TextInput";
import Button from "../../../../components/Button";
import {get} from "services/storage"
import { loginAction } from "../../../../services/actions";
import { globalData } from "services/cms/actions";
import ModalPassword from "./ModalPassword";
import ModalCompteSuccess from "./ModalCompteSuccess";
import ModalChoix from "./ModalChoix";
import { withTranslation, Trans } from 'react-i18next';
import { set } from "../../../../services/storage";
import LdsSpinner from 'components/LdsSpinner';

class ModalSigninDesktop extends Component {
  constructor(props){
    super(props);
  }
  state = {
    open: false,
    openChoix: false,
    success: false,
    messageSuccess: "",
    passwordError: 0,
    captcha: {
      min: Math.floor(Math.random() * 10),
      max: Math.floor(Math.random() * 10)
    },
    errorCaptcha: false,
    errorGlobal: "",
    captchaInput: null,
    isSubmitting: false
  };

  handleCloseModalCnxChoix = () => {
    this.setState({
      openChoix: false
    });
  };

  onOpenModalPassword = () => {
    this.setState({
      open: true
    });
  };

  handlePasswordReseted = message => {
    this.setState({
      success: true,
      messageSuccess: message,
      open: false
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false,
      messageSuccess: ""
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  onCloseModal = () => {
    this.setState({
      errorCaptcha: false,
      errorGlobal: "",
      passwordError: 0
    });
    this.props.onCloseModal();
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      id: Yup.string().required(this.props.t("Username required")),
      password: Yup.string().required(this.props.t("Password required"))
    });
  };

  handleChangeCaptcha(e) {
    this.setState({ captchaInput: e.target.value });
  }

  handleSubmit = form => {
    let login = null;
    if (this.state.passwordError <= 3) {
      login = {
        username: form.id,
        password: form.password
      };
      return this.props
        .dispatch(loginAction(login))
        .then(data => {
          if (data.payload.success) {
            if (data.payload.user.choose_account) {
              set("loginChoix", login);
              this.onCloseModal();
              this.setState({
                openChoix: true
              });
            } else {
              const user = get('session_user');
              if(user.profile.is_program_system || user.profile.is_sanady_assistant)
                this.props.history.push("/school/program/list");
              else if(user.profile.is_school_admin)
                this.props.history.push("/school/list");
              else if(user.is_escort){
                this.props.history.push("/escort/transport");
              }
              else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
                
                if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0)){
                  this.props.history.push(`/professor/info_profile`);
                }
                else{
                  this.props.dispatch(globalData({}))
                  if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                      this.props.history.push(`/professor/activities_prescolaire`);
                  else
                      this.props.history.push(`/professor/classes`);
                }
              }
              else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0)
                this.props.history.push(`/parents/dashboard`);
              else if(['ch'].indexOf(user.account_type) >= 0){
                if (data.payload.user.first_login) {
                  this.props.history.push({
                    pathname: "/child/profile",
                    search: "?login=first_login"
                  });
                } else {
                  this.props.history.push("/child/dashboard");
                }
              }
            }
          }
        })
        .catch(err => {
          console.log("err", err);
          this.setState(state => ({
            passwordError: state.passwordError + 1,
            errorGlobal: err.message,
            isSubmitting: false
          }));
        });
    } else {
      if (
        this.state.captchaInput ==
        this.state.captcha.min + this.state.captcha.max
      ) {
        login = {
          username: form.id,
          password: form.password
        };
        return this.props
          .dispatch(loginAction(login))
          .then(data => {
            if (data.payload.success) {
              const user = get('session_user');
              if(user.profile.is_school_admin){
                this.props.history.push("/school/list");
              }
              else if(user.is_escort){
                this.props.history.push("/escort/transport");
              }
              else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
                
                if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0)){
                  this.props.history.push(`/professor/info_profile`);
                }
                else{
                  this.props.dispatch(globalData({}))
                  if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                      this.props.history.push(`/professor/activities_prescolaire`);
                  else
                      this.props.history.push(`/professor/classes`);
                }
              }
              else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0)
                this.props.history.push(`/parents/dashboard`);
              else if(['ch'].indexOf(user.account_type) >= 0){
                if (data.payload.user.first_login) {
                  this.props.history.push({
                    pathname: "/child/profile",
                    search: "?login=first_login"
                  });
                } else {
                  this.props.history.push("/child/dashboard");
                }
              }
            }
          })
          .catch(err => {
            console.log("err", err);
            this.setState(state => ({
              passwordError: state.passwordError + 1,
              errorGlobal: err.message,
              isSubmitting: false
            }));
          });
      } else {
        this.setState({
          isSubmitting: false,
          errorCaptcha: true,
          captcha: {
            min: Math.floor(Math.random() * 10),
            max: Math.floor(Math.random() * 10)
          }
        });
      }
    }
  };

  render() {
    const {isSubmitting} = this.state;
    return (
      <>
        <Modal
          open={this.props.open || false}
          onClose={this.onCloseModal}
          showCloseIcon={false}
          center
          classNames={{
            overlay: { dialogOverlay: "white" },
            modal: { dialog: "white", dialogSignIn: "white" }
          }}
        >
          <div className="dialog-left">
            <h3>{ this.props.t('LEARNING BECOMES GREAT') }</h3>
            <Formik
              initialValues={{
                id: "",
                password: ""
              }}
              validationSchema={this.getValidationSchema}
              onSubmit={values => {
                this.setState({
                  isSubmitting: true
                })
                this.handleSubmit(values);
              }}
            >
              {({ errors, touched, values, handleChange }) => {
                return (
                  <Form className="dialog-form">
                    {this.state.errorGlobal && (
                      <div className="textErrors">{this.state.errorGlobal}</div>
                    )}
                    <TextInput
                      className={`textInput ${errors.id && "textInputError"}`}
                      name="id"
                      label="ENTER YOUR ID"
                      value={values.id}
                      onChange={handleChange}
                    />
                    {errors.id && touched.id ? (
                      <div className="textErrors">{errors.id}</div>
                    ) : null}
                    <TextInput
                      className={`textInput ${errors.password &&
                        "textInputError"}`}
                      type="password"
                      name="password"
                      label="MOT DE PASSE"
                      value={values.password}
                      onChange={handleChange}
                    />
                    {errors.password && touched.password ? (
                      <div className="textErrors">{errors.password}</div>
                    ) : null}
                    {this.state.passwordError >= 3 && (
                      <div className="captcha">
                        <p>
                          {this.state.captcha.min} + {this.state.captcha.max} ={" "}
                        </p>
                        <input
                          name="captchaInput"
                          type="text"
                          value={this.state.captchaInput}
                          onChange={this.handleChangeCaptcha.bind(this)}
                          className="captchaInput"
                        />
                      </div>
                    )}
                    {this.state.errorCaptcha && (
                      <div className="textErrors">{ this.props.t('Captcha Invalid') }</div>
                    )}
                    {errors.captcha && touched.captcha ? (
                      <div className="textErrors">{errors.captcha}</div>
                    ) : null}
                    {isSubmitting && <LdsSpinner type='relative'/>}
                    <Button disabled={isSubmitting}>{ this.props.t('SIGN IN') }</Button>
                  </Form>
                );
              }}
            </Formik>
            {/*
            <p className="password-forgot">
              <a onClick={() => this.onOpenModalPassword()}>
              { this.props.t('Forgot your password?') }
              </a>{" "}
            </p>
            */}
          </div>

          <div className="dialog-right">
            <span>
              <img src={images.imgSignin} className="img-fluid" />
            </span>
          </div>

          {this.state.open && (
            <ModalPassword
              open={this.state.open}
              onCloseModalPassword={this.onCloseModalPassword}
              handlePasswordReseted={message =>
                this.handlePasswordReseted(message)
              }
            />
          )}

          <ModalCompteSuccess
            success={this.state.success}
            message={this.state.messageSuccess}
            handleCloseSuccess={this.handleCloseSuccess}
          />
        </Modal>
        <ModalChoix
          openChoix={this.state.openChoix}
          handleCloseModalCnxChoix={this.handleCloseModalCnxChoix}
          onCloseModal={this.onCloseModal}
        />
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(ModalSigninDesktop)));
