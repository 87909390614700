import React, { Component } from "react";
import Modal from "react-responsive-modal";
import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation, Trans } from 'react-i18next';
import TextInput from "../../../../components/TextInput";

import FormLabel from "@material-ui/core/FormLabel";

import { images, messages, form } from "../_resources";
import Button from "../../../../components/Button";

//import Form from "../../../../components/Form";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { RegisterAction } from "../../../../services/actions";
import ModalCompteSuccess from "./ModalCompteSuccess";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class ModalRegisterDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      selectedValue: "a",
      captcha: {
        min: Math.floor(Math.random() * 10),
        max: Math.floor(Math.random() * 10)
      },
      errorCaptcha: false,
      errorGlobal: ""
    };
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required( this.props.t("First name required")),
      lastName: Yup.string().required( this.props.t("Last Name required")),
      id: Yup.string().required( this.props.t("ID required")),
      password: Yup.string()
        .min(5,  this.props.t("Password must be greater than 5 character"))
        .required( this.props.t("Password required")),
      test: Yup.string().required(),
      email: Yup.string().when("test", {
        is: "mail", // alternatively: (val) => val == true
        then: Yup.string()
          .email( this.props.t("Email format incorrect"))
          .required( this.props.t("Email required"))
      }),
      phone: Yup.number().when("test", {
        is:  this.props.t("phone"), // alternatively: (val) => val == true
        then: Yup.number().required( this.props.t("Phone required"))
      }),
      //phone: Yup.string().when('test', buildRequiredForDiffAddress("How will we know where to send your order?")),

      captcha: Yup.string().required( this.props.t("Captcha required"))
    });
  };

  handleOpenSuccess = () => {
    this.setState({
      success: true
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false
    });
  };

  handleChangeCheck = event => {
    this.setState({ selectedValue: event.target.value });
  };

  onCloseModalRegister = () => {
    this.setState({
      errorCaptcha: false,
      errorGlobal: ""
    });
    this.props.onCloseModalRegister();
  };

  handleSubmit = form => {
    if (form.captcha == this.state.captcha.min + this.state.captcha.max) {
      let register;
      register = {
        username: form.id,
        password: form.password,
        first_name: form.firstName,
        last_name: form.lastName,
        mail_or_phone: form.test,
        parent_email: form.email,
        parent_phone_number: null
      };
      return this.props
        .dispatch(RegisterAction(register))
        .then(data => {
          if (data.payload.success) {
            this.onCloseModalRegister();
            setTimeout(() => {
              this.handleOpenSuccess();
            }, 1000);
          }
        })
        .catch(err => {
          this.setState({
            errorGlobal: err.message
          });
        });
    } else {
      this.setState({
        errorCaptcha: true,
        captcha: {
          min: Math.floor(Math.random() * 10),
          max: Math.floor(Math.random() * 10)
        }
      });
    }
  };

  render() {
    return (
      <>
        <Modal
          open={this.props.register}
          onClose={this.onCloseModalRegister}
          showCloseIcon={false}
          center
          classNames={{
            overlay: { dialogOverlay: "white" },
            modal: { dialog: "white", dialogRegister: "white" }
          }}
        >
          <div className="dialog-left">
            <h3>{ this.props.t('LEARNING BECOMES GREAT') }</h3>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                id: "",
                password: "",
                test: "mail",
                email: "",
                phone: "",
                captcha: ""
              }}
              validationSchema={this.getValidationSchema}
              onSubmit={values => {
                this.handleSubmit(values);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue
              }) => {
                return (
                  <Form className="dialog-form">
                    <TextInput
                      className={`textInput ${
                        errors.firstName && touched.firstName
                          ? "textInputError"
                          : ""
                      }`}
                      name="firstName"
                      label= {this.props.t('First Name *')}
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="textErrors">{errors.firstName}</div>
                    ) : null}
                    <TextInput
                      className={`textInput ${
                        errors.lastName && touched.lastName
                          ? "textInputError"
                          : null
                      }`}
                      name="lastName"
                      label={this.props.t('Last Name *')}
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="textErrors">{errors.lastName}</div>
                    ) : null}
                    <TextInput
                      className={`textInput ${
                        errors.id && touched.id ? "textInputError" : null
                      }`}
                      name="id"
                      label="ENTER YOUR ID"
                      value={values.id}
                      onChange={handleChange}
                    />
                    {errors.id && touched.id ? (
                      <div className="textErrors">{errors.id}</div>
                    ) : null}
                    {this.state.errorGlobal && (
                      <div className="textErrors">{this.state.errorGlobal}</div>
                    )}
                    <TextInput
                      className={`textInput ${
                        errors.password && touched.password
                          ? "textInputError"
                          : null
                      }`}
                      type="password"
                      name="password"
                      label={this.props.t("PassWord")}
                      value={values.password}
                      onChange={handleChange}
                    />
                    {errors.password && touched.password ? (
                      <div className="textErrors">{errors.password}</div>
                    ) : null}

                    <MuiThemeProvider theme={theme}>
                      <div className="w12">
                        <div className="RadioLegend">
                          <Radio
                            color="secondary"
                            name="test"
                            value="mail"
                            checked={values.test === "mail"}
                            onChange={() => setFieldValue("test", "mail")}
                          />
                          <FormLabel component="legend">
                          { this.props.t('Email Address') }
                          </FormLabel>
                        </div>
                        <div className="RadioLegend">
                          <Radio
                            color="secondary"
                            name="test"
                            value="phone"
                            checked={values.test === "phone"}
                            onChange={() => setFieldValue("test", "phone")}
                          />
                          <FormLabel component="legend"> { this.props.t('Phone number') }</FormLabel>
                        </div>
                      </div>
                    </MuiThemeProvider>

                    {values.test === "mail" ? (
                      <>
                        <TextInput
                          className={`textInput ${
                            errors.email && touched.email
                              ? "textInputError"
                              : null
                          }`}
                          name="email"
                          label={this.props.t("EMAIL ADDRESS*")}
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <div className="textErrors">{errors.email}</div>
                        ) : null}{" "}
                      </>
                    ) : (
                      <>
                        <TextInput
                          className={`textInput ${
                            errors.phone && touched.phone
                              ? "textInputError"
                              : null
                          }`}
                          name="phone"
                          label={this.props.t("PHONE NUMBER *")}
                          value={values.phone}
                          onChange={handleChange}
                        />
                        {errors.phone && touched.phone ? (
                          <div className="textErrors">{errors.phone}</div>
                        ) : null}{" "}
                      </>
                    )}
                    <div className="captcha">
                      <p>
                        {this.state.captcha.min} + {this.state.captcha.max} ={" "}
                      </p>
                      <input
                        name="captcha"
                        type="text"
                        placeholder={" required *"}
                        value={values.captcha}
                        onChange={handleChange}
                        className="captchaInput"
                      />
                    </div>
                    {this.state.errorCaptcha && (
                      <div className="textErrors captcha"> { this.props.t('Captcha Invalid') }</div>
                    )}
                    {errors.captcha && touched.captcha ? (
                      <div className="textErrors captcha">{errors.captcha}</div>
                    ) : null}
                    <Button>{ this.props.t('REGISTER') }</Button>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <div className="dialog-right">
            <img src={images.registerKids} className="img-fluid" />
          </div>
        </Modal>
        <ModalCompteSuccess
          success={this.state.success}
          handleCloseSuccess={this.handleCloseSuccess}
        />
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withTranslation('translations')(ModalRegisterDesktop));
