import React from "react"
import {images} from "../_resources"
import styled from "styled-components"

export default () => (
    <Group>
        <img src={images.Group} className="img-fluid" />
    </Group>
)

const Group = styled.div`
      position: fixed;
      bottom: 20px;
      right: 20px;
      width: 60px;
      z-index: 9990;
      cursor: pointer;
      img{
        -webkit-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
        -moz-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
        -ms-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
        -o-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
        filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
      }
      
      @media screen and (max-width: 1199px){
        bottom: 8px;
        right: 8px;
        width: 50px;
      }
`