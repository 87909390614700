import logo from "../../../assets/img/logo.svg";
import iconConnexion from "../../../assets/img/login_icon.svg";
import slider from "../../../assets/img/first-page-image.jpg";
import slider2 from "../../../assets/img/img-children.jpg";
import slider3 from "../../../assets/img/children2.jpg";
import gameboy from "../../../assets/img/gameboy.jpg";
import viewAll from "../../../assets/img/view-all-arrow.svg";
import childSlider1 from "../../../assets/img/image-slider01.jpg";
import childSlider2 from "../../../assets/img/image-slider02.jpg";
import childSlider3 from "../../../assets/img/image-slider03.jpg";

import logoFooter from "../../../assets/img/logo-footer.svg";
import feedImage from "../../../assets/img/img-children.jpg";
import imgSignin from "../../../assets/img/sign-in-kids.png";
import registerKids from "../../../assets/img/register-kids.png";
import editIcon from "../../../assets/img/editIcon.png";

import chimie from "../../../assets/img/Chimie.gif";
import geographie from "../../../assets/img/Geography.gif";
import physique from "../../../assets/img/Physics.gif";
import english from "../../../assets/img/English.gif";
import frensh from "../../../assets/img/French.gif";
import history from "../../../assets/img/History.gif";
import maths from "../../../assets/img/Maths.gif";
import sciences from "../../../assets/img/Sciences.gif";
import arabic from "../../../assets/img/Arabic.gif";
import iconUser from "../../../assets/img/user.png";
import iconGroup from "../../../assets/img/group.png";
import Group from "../../../assets/img/chat_bot.svg";
import NewsFeed from "../../../assets/img/shape-news-feed.svg";
import ArrowNews from "../../../assets/img/news_arrow.svg";

import Arrowback from "../../../assets/img/arrow-back.svg";
import iconClose from "../../../assets/img/close-calc-icon.png";

import iconMale from "../../../assets/img/iconMale.svg";
import iconFemale from "../../../assets/img/iconFemale.svg";
import profSlider1 from "../../../assets/img/Caroussels/Profs/1.png";
import profSlider2 from "../../../assets/img/Caroussels/Profs/2.png";
import profSlider3 from "../../../assets/img/Caroussels/Profs/3.png";
import profSlider4 from "../../../assets/img/Caroussels/Profs/4.png";

import parentSlider1 from "../../../assets/img/Caroussels/Parents/1.png";
import parentSlider2 from "../../../assets/img/Caroussels/Parents/2.png";
import parentSlider3 from "../../../assets/img/Caroussels/Parents/3.png";
import parentSlider4 from "../../../assets/img/Caroussels/Parents/4.png";
import parentSlider5 from "../../../assets/img/Caroussels/Parents/5.png";



export const images = {
  logo,
  iconConnexion,
  slider,
  slider2,
  slider3,
  gameboy,
  viewAll,
  childSlider1,
  childSlider2,
  childSlider3,
  logoFooter,
  feedImage,
  imgSignin,
  registerKids,
  editIcon,
  chimie,
  geographie,
  physique,
  english,
  frensh,
  history,
  maths,
  sciences,
  arabic,
  iconUser,
  iconGroup,
  Group,
  NewsFeed,
  ArrowNews,
  Arrowback,
  iconClose,
  iconMale,
  iconFemale,
  profSlider1,
  profSlider2,
  profSlider3,
  profSlider4,
  parentSlider1,
  parentSlider2,
  parentSlider3,
  parentSlider4,
  parentSlider5
};

export const messages = {
  button: "REGISTER",
  labelNom: "Nom",
  labelNom: "Prénom"
};

export const form = {
  nom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "nom"
  },
  prenom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "prenom"
  }
};
