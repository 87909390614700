import React, { Component } from "react";
import { withTranslation, Trans } from 'react-i18next';
import Modal from "react-responsive-modal";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

import { images } from "../_resources";
import ModalSigninDesktop from "./ModalSigninDesktop";
import { Formik, Form } from "formik";
import TextInput from "../../../../components/TextInput";
import Button from "../../../../components/Button";
import {
  loginWithClasseAction,
  loginAction
} from "../../../../services/actions";
import { globalData } from "services/cms/actions";
import { get } from "../../../../services/storage";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class ModalChoix extends Component {
  constructor(props){
    super(props);
  }
  state = {
    switch: "",
    open: false,
    errorGlobal: false
  };

  handleSwitchCnx = type => {
    const loginChoix = get("loginChoix");
    this.props.handleCloseModalCnxChoix();
    if (type === "continue") {
      this.setState({
        switch: type,
        open: true
      });
    } else if (type === "ready") {
      let obj = {
        login: loginChoix.username,
        password: loginChoix.password,
        link: true
      };
      this.props
        .dispatch(loginWithClasseAction(obj))
        .then(data => {
          console.log("data", data);
          // if (data.payload.success) {
          //   this.props.history.push("/dashboard");
          // }
          this.setState({
            switch: type
          });
        })
        .catch(err => {
          this.setState(state => ({
            errorGlobal: err.message
          }));
        });
    }
  };
  onOpenModal = () => {
    this.props.onOpenModal();
  };

  onCloseModal = () => {
    this.setState({
      open: false
    });
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required(this.props.t("First name required")),
      lastName: Yup.string().required(this.props.t("Last Name required")),
      email: Yup.string()
        .email("Email format incorrect")
        .required(this.props.t("Email required"))
    });
  };

  handleSubmit = values => {
    let obj = {
      pa_email: values.email,
      pa_phone: "0660000000",
      f_name: values.firstName,
      l_name: values.lastName,
      link: false
    };
    const loginChoix = get("loginChoix");
    this.props
      .dispatch(loginWithClasseAction(obj))
      .then(data => {
        console.log("data", data);
        if (data.payload.success) {
          this.props.dispatch(loginAction(loginChoix)).then(data => {
            if (data.payload.success) {
              const user = get('session_user');
              if(user.profile.is_school_admin){
                this.props.history.push("/school/list");
              }
              else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 && get('professor_subjects').length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
                this.props.dispatch(globalData({}))
                if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                    this.props.history.push(`/professor/activities_prescolaire`);
                else
                    this.props.history.push(`/professor/classes`);
              }
              else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0)
                this.props.history.push(`/parents/dashboard`);
              else if(['ch'].indexOf(user.account_type) >= 0){
                if (data.payload.user.first_login) {
                  this.props.history.push({
                    pathname: "/child/profile",
                    search: "?login=first_login"
                  });
                } else {
                  this.props.history.push("/child/dashboard");
                }
              }
            }
          });
        }
      })
      .catch(err => {
        this.setState(state => ({
          errorGlobal: err.message
        }));
      });
  };

  render() {
    return (
      <>
        <Modal
          open={this.props.openChoix}
          onClose={this.props.handleCloseModalCnxChoix}
          showCloseIcon={false}
          center
          classNames={{
            overlay: { dialogOverlay: "white" },
            modal: {
              dialog: "white",
              dialogSignIn: "white",
              dialogChoixCnx: "white"
            }
          }}
        >
          <a
            className="active"
            onClick={() => this.handleSwitchCnx("continue")}
          >
          { this.props.t('Continue with this account') }
          </a>
          <a onClick={() => this.handleSwitchCnx("ready")}>
          { this.props.t('Already have an account') }
          </a>
        </Modal>
        {this.state.switch === "continue" ? (
          <Modal
            open={this.state.open}
            onClose={this.onCloseModal}
            showCloseIcon={false}
            center
            classNames={{
              overlay: { dialogOverlay: "white" },
              modal: {
                dialog: "white",
                dialogRegister: "white",
                dialogContinueCompte: "white"
              }
            }}
          >
            <div className="dialog-left">
              <h3>{ this.props.t('CONTINUE WITH THIS ACCOUNT') }</h3>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  test: "mail",
                  phone: ""
                }}
                //validationSchema={this.getValidationSchema}
                onSubmit={values => {
                  this.handleSubmit(values);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue
                }) => {
                  return (
                    <Form className="dialog-form">
                      {/* {this.state.errorGlobal && (
                        <div className="textErrors">
                          {this.state.errorGlobal}
                        </div>
                      )} */}
                      <TextInput
                        className={`textInput ${
                          errors.firstName && touched.firstName
                            ? "textInputError"
                            : ""
                        }`}
                        name="firstName"
                        label="PRÉNOM"
                        value={values.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && touched.firstName ? (
                        <div className="textErrors">{errors.firstName}</div>
                      ) : null}
                      <TextInput
                        className={`textInput ${
                          errors.lastName && touched.lastName
                            ? "textInputError"
                            : ""
                        }`}
                        name="lastName"
                        label="NOM"
                        value={values.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && touched.lastName ? (
                        <div className="textErrors">{errors.lastName}</div>
                      ) : null}
                      <MuiThemeProvider theme={theme}>
                        <div className="w12">
                          <div className="RadioLegend">
                            <Radio
                              color="secondary"
                              name="test"
                              value="mail"
                              checked={values.test === "mail"}
                              onChange={() => setFieldValue("test", "mail")}
                            />
                            <FormLabel component="legend">
                            { this.props.t('Email Adresse') }
                            </FormLabel>
                          </div>
                          <div className="RadioLegend">
                            <Radio
                              color="secondary"
                              name="test"
                              value="phone"
                              checked={values.test === "phone"}
                              onChange={() => setFieldValue("test", "phone")}
                            />
                            <FormLabel component="legend">
                            { this.props.t('Phone number') }
                            </FormLabel>
                          </div>
                        </div>
                      </MuiThemeProvider>
                      {values.test === "mail" ? (
                        <>
                          <TextInput
                            className={`textInput ${
                              errors.email && touched.email
                                ? "textInputError"
                                : null
                            }`}
                            name="email"
                            label="EMAIL"
                            value={values.email}
                            onChange={handleChange}
                          />
                          {errors.email && touched.email ? (
                            <div className="textErrors">{errors.email}</div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <TextInput
                            className={`textInput ${
                              errors.phone && touched.phone
                                ? "textInputError"
                                : null
                            }`}
                            name="phone"
                            label="PHONE"
                            value={values.phone}
                            onChange={handleChange}
                          />
                          {errors.phone && touched.phone ? (
                            <div className="textErrors">{errors.phone}</div>
                          ) : null}
                        </>
                      )}
                      <Button>{ this.props.t('REGISTER') }</Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <div className="dialog-right">
              <img src={images.registerKids} className="img-fluid" />
            </div>
          </Modal>
        ) : this.state.switch === "ready" ? (
          <ModalSigninDesktop
            open={true}
            onCloseModal={this.props.onCloseModal}
          />
        ) : null}
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter( withTranslation('translations')(ModalChoix)));


