import React, { Component } from "react";
import Modal from "react-responsive-modal";

import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { register_global_api } from "services/api";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation, Trans } from 'react-i18next';

import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class RegisterFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: {
        min: Math.floor(Math.random() * 10),
        max: Math.floor(Math.random() * 10)
      },
      errorCaptcha: false,
      errorGlobal: "",
      server_errors: {}
    };
  }
  
  getValidationSchema = () => {
    // const usernameRegExp = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/;
    const phoneRegExp = /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/;
    return Yup.object().shape({
      firstName: Yup.string().default("")
          .required(this.props.t("First name required")),
      lastName: Yup.string().default("")
          .required(this.props.t("Last name required")),
      email: Yup.string()
          .email(this.props.t("Email format incorrect"))
          .required(this.props.t("Email required")),
      phone: Yup.string()
        .matches(phoneRegExp, this.props.t('Phone format incorrect'))
        .required(this.props.t("Phone number required")),
      accept: Yup.string()
        .required(this.props.t("You should Accept the Terms and Conditions")),
      captcha: Yup.string().default("")
        .required(this.props.t("Captcha required"))
    });
  };

  handleSubmit = form => {
    if (form.captcha == this.state.captcha.min + this.state.captcha.max) {
      let register;
      register = {
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        phone_number: form.phone,
        account_type: form.account_type
      };
      this.setState({server_errors: {}});
      register_global_api(register)
      .then(data => {
        if (data.success) {
          // this.props.handleCloseRegister();
          this.props.handleOpenSuccess(`${this.props.t("An Email has been sent to you")+"."}`);
        }
        else{
          var server_errors = {};
          Object.keys(data).map(key => {
            server_errors[key] = data[key][0].user_message;
          });
          this.setState({server_errors: server_errors});
        }
      })
      .catch(err => {
        
        this.setState({
          errorGlobal: err.message,
          captcha: {
            min: Math.floor(Math.random() * 10),
            max: Math.floor(Math.random() * 10)
          }
        });
      });
    } else {
      this.setState({
        errorCaptcha: true,
        captcha: {
          min: Math.floor(Math.random() * 10),
          max: Math.floor(Math.random() * 10)
        }
      });
    }
  };


  render() {
    const {server_errors} = this.state;
    
    return ( 
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          account_type: this.props.pages=='children'?'ch':(this.props.pages=='parent'?"pa":"pr_pa"),
          captcha: "",
          accept: ""
        }}
        validationSchema={values => this.getValidationSchema(values)}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
        return (
        <Form>
          <div className="modalConnexionForm">
            <div className="connexionLigne">
              <div className="connexionW50">
                {this.state.errorGlobal && (
                  <div className="textErrors">{this.state.errorGlobal}</div>
                )}
              </div>
            </div>
            <div className="row">
              <MuiThemeProvider theme={theme}>
                <div className="col-12">
                  <label>{ this.props.t('Account Type') }*</label>
                  <div className="d-flex justify-content-between">
                    <div className="RadioLegend">
                      <Radio
                        color="secondary"
                        name="account_type"
                        value="ch"
                        checked={values.account_type === "ch"}
                        onChange={() => {
                          setFieldValue("account_type", "ch")
                        }}
                      />
                      <FormLabel component="legend">
                      { this.props.t('Child') }
                      </FormLabel>
                    </div>
                    <div className="RadioLegend">
                      <Radio
                        color="secondary"
                        name="account_type"
                        value="pa"
                        checked={values.account_type === "pa"}
                        onChange={() => {
                          setFieldValue("account_type", "pa")
                        }}
                      />
                      <FormLabel component="legend">
                      { this.props.t('Parent') }
                      </FormLabel>
                    </div>
                    <div className="RadioLegend">
                      <Radio
                        color="secondary"
                        name="account_type"
                        value="pr_pa"
                        checked={values.account_type === "pr_pa"}
                        onChange={() => {
                          setFieldValue("account_type", "pr_pa")
                        }}
                      />
                      <FormLabel component="legend">
                      { this.props.t('Professor') }
                      </FormLabel>
                    </div>
                  </div>
                </div>
              </MuiThemeProvider>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label>{ this.props.t('First Name') }*</label>
                <input type="text"
                  className={`form-control ${errors.firstName && "textInputError"}`}
                  name="firstName"
                  label={this.props.t("FIRST NAME")}
                  value={values.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && touched.firstName ? (
                  <div className="textErrors">{errors.firstName}</div>
                ) : null}
              </div>
              <div className="col-12 col-md-6">
                <label>{ this.props.t('Last Name') }*</label>
                <input type="text"
                  className={`form-control ${errors.lastName && "textInputError"}`}
                  name="lastName"
                  label={this.props.t("LAST NAME")}
                  value={values.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && touched.lastName ? (
                  <div className="textErrors">{errors.lastName}</div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label>{ this.props.t('Email Adresse') }*</label>
                <input
                  className={`form-control ${(('email' in server_errors) || errors.email) &&
                    "textInputError"}`}
                  name="email"
                  label="EMAIL ADDRESS"
                  value={values.email}
                  onChange={handleChange}
                />
                {(('email' in server_errors) || (errors.email && touched.email)) &&
                  <div className="textErrors">{server_errors.email || errors.email}</div>
                }{" "}
              </div>
              <div className="col-12 col-md-6">
                <label>{ this.props.t('Phone number') }*</label>
                <IntlTelInput inputClassName={`form-control ${((('phone_number' in server_errors) || (errors.phone && touched.phone))?"textInputError":"")}`}
                    preferredCountries={['ma']} defaultValue={(values.phone)?values.phone:''}
                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                      if(!status){
                        try {status = phoneUtil.isPossibleNumber(phoneUtil.parse(number));}catch (error){}
                      }
                      if(status)
                        setFieldValue('phone', number);
                      else
                        setFieldValue('phone','');
                    }}/>
                {(('phone_number' in server_errors) || (errors.phone && touched.phone)) &&
                    <div className="textErrors">{server_errors.phone_number || errors.phone}</div>
                  }
              </div>
            </div>

            <div className="row">
              <MuiThemeProvider theme={theme}>
                <div className="col-12">
                  <div className="RadioLegend">
                    <Radio
                      color="secondary"
                      name="accept"
                      value="yes"
                      checked={values.accept ? "yes" : ""}
                      onChange={() => {
                        if (!values.accept){
                          setFieldValue("accept", "yes")
                        }else{
                          setFieldValue("accept", "")
                        }
                      }}
                    />
                    <FormLabel component="legend">
                    { this.props.t('Accept the') } <a className="terms_link" onClick={() => this.props.handleOpenTermsAndCondition()}>{ this.props.t('Terms and Conditions') }*</a>
                    </FormLabel>
                    {errors.accept && touched.accept ? (
                      <div className="textErrors">{errors.accept}</div>
                    ) : null}
                  </div>
                </div>
              </MuiThemeProvider>
            </div>
            <div className="connexionLigne">
              <div className="captcha">
                <p className="pCaptcha">
                  {this.state.captcha.min} + {this.state.captcha.max} ={" "}
                </p>
                <input
                  name="captcha"
                  type="text"
                  value={values.captcha}
                  onChange={handleChange}
                  className="captchaInput"
                />
              </div>
              {this.state.errorCaptcha && (
                <div className="textErrors">{ this.props.t('Captcha Invalid') }</div>
              )}
              {errors.captcha && touched.captcha ? (
                <div className="textErrors">{errors.captcha}</div>
              ) : null}
            </div>
            <div className="row">
              <div className='col-12 text-right'>
                <button type="submit" className="btn btn-green btn-rounded">
                { this.props.t('REGISTER') } 
                </button>
              </div>
            </div>
          </div>
        </Form>
        );
      }}
    </Formik>)
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(RegisterFormComponent)));
