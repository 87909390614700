import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { withTranslation, Trans } from 'react-i18next';
import { resendActivationData } from "services/api";
import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

import * as Yup from "yup";
import { Formik, Form } from "formik";

import { loginProfessor } from "services/actions";
import { globalData } from "services/cms/actions";
import ModalCompteSuccess from "../../school/components/ModalCompteSuccess";
import { RegisterParent } from "services/actions";
import {clear, set, get} from "services/storage";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import ModalPassword from "../../children/home/components/ModalPassword";
import RegisterFormComponent from 'components/RegisterFormComponent';

import countryList from 'react-select-country-list'
import localforage from 'localforage';


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class ModalParentMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      selectedValue: "a",
      captcha: {
        min: Math.floor(Math.random() * 10),
        max: Math.floor(Math.random() * 10)
      },
      open: false,
      errorCaptcha: false,
      errorGlobal: "",
      server_errors: {},
      resend_activation_data: "",
      resend_activation_data_message: "",
      username: "",
      showBackBtn: false,
      messageSuccess: "",
      to_refresh: false,
      phone_number: "",
      is_email: true
    };
    this.countries = countryList().getData();
    this.countries = this.countries.filter(op => {
      return op.value != "EH";
    });
  };

  componentWillReceiveProps(newProps){
    if(newProps.openParentMobile.signup && this.props.openParentMobile.popup !== newProps.openParentMobile.popup){
      this.setState({server_errors: {}});
    }
    else if(!newProps.openParentMobile.signup){
      this.setState({to_refresh: !this.state.to_refresh});
    }
  };

  getValidationSchemaSignup = () => {
    const phoneRegExp = /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
      usernameRegExp = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
    return Yup.object().shape({
      first_name: Yup.string()
        .required(this.props.t("First name required")),
      last_name: Yup.string()
        .required(this.props.t("Last Name required")),
      email: Yup.string()
        .email(this.props.t("Email format incorrect"))
        .when('phone', {
            is: (phone) => !phone,
            then: Yup.string().required(this.props.t("Email or phone is required")+"!")
        }),
      phone: Yup.string()
        .matches(phoneRegExp, 'Phone format incorrect')
        .when('email', {
            is: (email) => !email,
            then: Yup.string().required(this.props.t("Email or phone is required")+"!")
        }),
      username: Yup.string()
        .required(this.props.t("Username required"))
        .matches(usernameRegExp, this.props.t('Username invalid')),   
      password: Yup.string()
        .required(this.props.t("Password required"))
        .min(5, this.props.t("Password must be greater than 5 character")),
      captcha: Yup.string()
        .required(this.props.t("Captcha required")),
      accept: Yup.string()
        .required(this.props.t("You should Accept the Terms and Conditions")),
    }, ['email', 'phone']);
  };

  getValidationSchemaSignin = () => {
    return Yup.object().shape({
      username: Yup.string().default("")
        .required(this.props.t("Username required")),
      password: Yup.string().default("")
      .required(this.props.t("Password required"))
      // .min(5, this.props.t("Password must be greater than 5 character")),
    });
  };

  handleOpenSuccess = (phone_number) => {
    if(phone_number){
      this.setState({
        success: true,
        phone_number: phone_number,
        is_email: false
      });
    }
    else{
      this.setState({
        success: true,
        is_email: true
      });
    }
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false,
      messageSuccess: ""
    });
  };

  handleOpenSignupParentMobile = () => {
    this.props.handleOpenSignupParentMobile();
  };

  handleOpenSigninParentMobile = () => {
    this.props.handleOpenSigninParentMobile();
  };

  handleCloseParentMobile = () => {
    this.props.handleCloseParentMobile();
  };

  handleResendActivationData = () => {
    let account = {
      username: this.state.username
    };
    resendActivationData(account)
    .then(data => {
      this.setState({resend_activation_data_message: data.message});
    })
    .catch(err => {
      this.setState(state => ({
        errorGlobal: err.message
      }));
    });
  };
  
  handleBackBtnClicked = () => {
    this.setState({showBackBtn: false});
    this.props.handleOpenSignupParentMobile();
  };
  
  handleAlreadyHaveAnAccount = () => {
    this.setState({showBackBtn: true});
    this.props.handleOpenSigninParentMobile();
  };

  handleSubmit = (form) => {
    if (this.props.openParentMobile.signin){
      let loginprof = this.state.showBackBtn ? this.state.first_step_data : {};
      if(this.state.showBackBtn){
        loginprof.added_action = "add_school";
      }
      loginprof.username = form.username;
      loginprof.password = form.password;
      var lev = get("level_groups");
      var current_language = get("current_language");
      clear();
      localforage.clear()
      if(global.ws_notification)
        global.ws_notification.close();
      set("level_groups", lev);
      set("current_language", current_language);
      this.setState({server_errors: {}});
      return this.props
        .dispatch(loginProfessor(loginprof))
        .then(data => {
          if (data.payload.success) {
            const user = get("session_user");
            if(this.props.key_inv)
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0)
            {
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard");
              // else
              //   this.props.history.push("/parents/candidatures");
            }
            else
              this.props.history.push("/");
          }
        })
        .catch(err => {
          var message = err.message || "";
          var resend_activation_data = message.indexOf("mail_not_yet_verified") !== -1 ? "mail" :(message.indexOf("phone_not_yet_verified") !== -1 ? "phone" : "");
          this.setState(state => ({
            errorGlobal: message.replace(",mail_not_yet_verified", "").replace(",phone_not_yet_verified", ""),
            resend_activation_data: resend_activation_data,
            resend_activation_data_message: "",
            username: form.username
          }));
        });
    }
    else{
      if (form.captcha == this.state.captcha.min + this.state.captcha.max) {
        var register = {
          original_email : "",
          phone_number : form.phone,
          permanent_user : "",
          child_invitation_key : "",
          from_invitation: !!this.props.key_inv,
          add_child_to_subscription : "",
          code_verify : form.code_verify,
          activation_key : "",
          school_user : "",
          gender : form.gender,
          username : form.username,
          password : form.password,
          first_name : form.first_name,
          last_name : form.last_name,
          mail_or_phone : form.mail_or_phone,
          email : form.email
        }
        this.setState({server_errors: {}});
        return this.props
          .dispatch(RegisterParent(register))
          .then(data => {
            if (data.payload.success) {
              if(this.props.key_inv){
                window.location = "/parent?username=" + data.payload.username + "&key_inv=" + this.props.key_inv;
              }
              else{
                this.handleCloseParentMobile();
                setTimeout(() => {
                  this.handleOpenSuccess(form.phone);
                }, 10);
              }
            }
            else{
              var server_errors = {};
              Object.keys(data.payload).map(key => {
                server_errors[key] = data.payload[key][0].user_message;
              });
              this.setState({server_errors: server_errors});
            }
          })
          .catch(err => {
            this.setState({
              errorGlobal: err.message,
              captcha: {
                min: Math.floor(Math.random() * 10),
                max: Math.floor(Math.random() * 10)
              }
            });
          });
      } else {
        this.setState({
          errorCaptcha: true,
          captcha: {
            min: Math.floor(Math.random() * 10),
            max: Math.floor(Math.random() * 10)
          }
        });
      }
    }
  };

  onOpenModalPassword = () => {
    this.setState({
      open: true
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  handlePasswordReseted = (message) => {
    this.setState({
      success: true,
      messageSuccess: message,
      open: false
    });
  };

  render() {
    const {server_errors, resend_activation_data, resend_activation_data_message} = this.state;
    /* TODO: TRANSLATIONS */

    return (
      <>
      <Modal
        open={
          this.props.openParentMobile && this.props.openParentMobile.popup
        }
        onClose={this.handleCloseParentMobile}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white", dialogMobile: "white" },
          modal: {
            dialog: "white",
            dialogConnexion: "white",
            dialogAddSchool: "white",
            dialogRegister: "white"
          }
        }}
      >
        <div
          className="closeRegister"
          onClick={() => this.handleCloseParentMobile()}
        >
          &times;
        </div>
        <div className="dialog-top">
          <a
            className={this.props.openParentMobile.signin ? "active" : ""}
            onClick={() => this.handleOpenSigninParentMobile()}
          >
          { this.props.t('CONNEXION') } 
          </a>
          <a
            onClick={() => this.handleOpenSignupParentMobile()}
            className={!this.props.openParentMobile.signin ? "active" : ""}
          >
            { this.props.t('REGISTER') }
          </a>
        </div>
        {this.props.account_activated &&
          <div className="account_activated">Bienvenue sur Koolskools. Votre compte a été activé, vous pouvez maintenant accéder à votre espace en saisissant votre mot de passe.</div>
        }
        <div className="dialog-content">

          {this.props.openParentMobile.signin ? (
            <>
            <Formik
              initialValues={{
                username: this.props.username || "",
                password: ""
              }}
              validationSchema={this.getValidationSchemaSignin}
              onSubmit={(values, actions) => {
                this.handleSubmit(values, actions);
              }}
            > 
              {({ errors, touched, values, handleChange }) => {
              return (
                <Form>
                  <div className="modalConnexionForm">
                  {this.state.errorGlobal && 
                    <div className="connexionLigne">
                      <div className="connexionW100">
                          <div className="textErrors">{this.state.errorGlobal}</div>
                      </div>
                    </div>
                  }

                    <div className="connexionLigne w12">
                      <label> { this.props.t('Enter Username') }</label>
                      <input type="text"
                        className={`textInput ${errors.username && "textInputError"}`}
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                      />
                      {errors.username && touched.username &&
                        <div className="textErrors">{errors.username}</div>
                      }
                    </div>                    

                    <div className="connexionLigne w12">
                      <label>{ this.props.t('Password') }</label>
                      <input type="password"
                        className={`textInput ${errors.password && "textInputError"}`}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      {errors.password && touched.password &&
                        <div className="textErrors">{errors.password}</div>
                      }
                    </div>

                    <div className="connexionLigne w12">
                      {this.state.showBackBtn ?
                        <a className="forgot" onClick={this.handleBackBtnClicked}>
                        { this.props.t('Back') }
                        </a>
                      :
                        <a onClick={() => this.onOpenModalPassword()} className="forgot">{ this.props.t('FORGOT PASSWORD?') }</a>
                      }
                      <button type="submit" className="signin">
                      { this.props.t('SIGN IN') }
                      </button>
                    </div>

                    {resend_activation_data &&
                    <>
                      <div className="connexionLigne w12 resend_activation_data">
                      { this.props.t('Resend') } {resend_activation_data == "mail" ? "link " : "code "} activation?
                        <button className="resend_activation_data_btn" onClick={this.handleResendActivationData}>{ this.props.t('Resend') }</button>
                      </div>
                      {resend_activation_data_message &&
                        <div className="resend_activation_data_message">
                          {resend_activation_data_message}
                        </div>
                      }
                    </>
                    }
                  </div>
                </Form>);
              }}
            </Formik>
            </>
          ) : (
            // <Formik
            //   initialValues={{
            //     original_email : "",
            //     phone : "",
            //     permanent_user : "",
            //     child_invitation_key : "",
            //     add_child_to_subscription : "",
            //     code_verify : "43529547",
            //     activation_key : "",
            //     school_user : "",
            //     gender :"",
            //     username : "" ,
            //     password : "",
            //     first_name : "",
            //     last_name : "",
            //     mail_or_phone : "mail",
            //     email : this.props.email || "",
            //     captcha: "",
            //     accept : ""
            //   }}
            //   validationSchema={this.getValidationSchemaSignup}
            //   onSubmit={(values, actions) => {
            //       this.handleSubmit(values, actions);
            //   }}
            // >
            // {({
            //     errors,
            //     touched,
            //     values,
            //     handleChange,
            //     handleBlur,
            //     setFieldValue
            // }) => {
            //   const errors_signup = errors;
            //   return (
            //     <Form>
            //       <div className="modalConnexionForm">
            //       {this.state.errorGlobal &&
            //         <div className="connexionLigne">
            //           <div className="connexionW100">
            //               <div className="textErrors">{this.state.errorGlobal}</div>
                        
            //           </div>
            //         </div>
            //       }
            //             <div className="connexionLigne w12">
            //               <label>{ this.props.t('Admin First Name') }*</label>
            //               <input type="text"
            //                 className={`textInput ${errors_signup.first_name && "textInputError"}`}
            //                 name="first_name"
            //                 value={values.first_name || ''}
            //                 onChange={handleChange}
            //               />
            //               {errors_signup.first_name && touched.first_name ? (
            //                 <div className="textErrors">{errors_signup.first_name}</div>
            //               ) : null}
            //             </div>

            //             <div className="connexionLigne w12">
            //               <label>{ this.props.t('Admin Last Name') }*</label>
            //               <input type="text"
            //                 className={`textInput ${errors_signup.last_name && "textInputError"}`}
            //                 name="last_name"
            //                 value={values.last_name || ''}
            //                 onChange={handleChange}
            //               />
            //               {errors_signup.last_name && touched.last_name ? (
            //                 <div className="textErrors">{errors_signup.last_name}</div>
            //               ) : null}
            //             </div>

            //             <div className="connexionLigne w12">
            //               <label>{ this.props.t('Email') }*</label>
            //               <input type="text"
            //                 className={`textInput ${(('email' in server_errors) || errors_signup.email) && "textInputError"}`}
            //                 name="email"
            //                 value={values.email || ''}
            //                 onChange={handleChange}
            //               />
            //               {(('email' in server_errors) || (errors_signup.email && touched.email)) &&
            //                 <div className="textErrors">{server_errors.email || errors_signup.email}</div>
            //               }{" "}
            //             </div>

            //             <div className="connexionLigne w12">
            //               <label>{ this.props.t('Phone Number') }*</label>
            //               <IntlTelInput inputClassName={ ((('phone_number' in server_errors) || (errors_signup.phone && (touched.phone || touched.phone_number)))?"textInputError":"")}
            //                   preferredCountries={['ma']} defaultValue={values.phone || ''}
            //                   onPhoneNumberChange={(status, value, countryData, number, id) => {
            //                       setFieldValue('phone', number);
            //                   }}/>
            //               {(('phone_number' in server_errors) || (errors_signup.phone && (touched.phone || touched.phone_number))) &&
            //                 <div className="textErrors">{server_errors.phone || errors_signup.phone}</div>
            //               }
            //             </div>

            //             <div className="connexionLigne w12">
            //               <label>{ this.props.t('Enter Username') }</label>
            //               <input type="text"
            //                 className={`textInput ${(('username' in server_errors) || errors_signup.username) && "textInputError"}`}
            //                 name="username"
            //                 value={values.username || ''}
            //                 onChange={handleChange}
            //               />
            //               {(('username' in server_errors) || (errors_signup.username && touched.username)) &&
            //                 <div className="textErrors">{server_errors.username || errors_signup.username}</div>
            //               }
            //             </div>

            //             <div className="connexionLigne w12">
            //               <label>{ this.props.t('Password') }</label>
            //               <input type="password"
            //                 className={`textInput ${errors_signup.password && "textInputError"}`}
            //                 name="password"
            //                 value={values.password || ''}
            //                 onChange={handleChange}
            //               />
            //               {errors_signup.password && touched.password ? (
            //                 <div className="textErrors">{errors_signup.password}</div>
            //               ) : null}
            //             </div>

            //             <div className="connexionLigne connexionGender w12">
            //               <label>{ this.props.t('Gender') } </label>
            //               <div className="gender">
            //                 <div className="genderMale">
            //                   <input type="radio"
            //                     name="gender"
            //                     value="male"
            //                     checked={values.gender === "male"}
            //                     onChange={() => setFieldValue("gender", "male")}
            //                   />
            //                   <span /> <b>{ this.props.t('Male') }</b>
            //                 </div>
            //                 <div className="genderFemale">
            //                   <input type="radio"
            //                     name="gender"
            //                     value="female"
            //                     checked={values.gender === "female"}
            //                     onChange={() => setFieldValue("gender", "female")}
            //                   />
            //                   <span /> <b>{ this.props.t('Female') }</b>
            //                 </div>
            //               </div>
            //             </div>

            //             <div className="connexionLigne w12">
            //               <MuiThemeProvider theme={theme}>
            //                 <div className="w12">
            //                   <div className="RadioLegend">
            //                     <Radio
            //                       color="secondary"
            //                       name="accept"
            //                       value="yes"
            //                       checked={values.accept ? "yes" : ""}
            //                       onChange={() => {if (!values.accept){
            //                           setFieldValue("accept", "yes")
            //                         }else{
            //                           setFieldValue("accept", "")
            //                         }
            //                       }}
            //                     />
            //                     <FormLabel component="legend">
            //                     { this.props.t('Accept the Terms and Conditions') }*
            //                     </FormLabel>
            //                     {errors_signup.accept && touched.accept ? (
            //                       <div className="textErrors">{errors_signup.accept}</div>
            //                     ) : null}
            //                   </div>
            //                 </div>
            //               </MuiThemeProvider>
            //             </div>

            //             <div className="connexionLigne">
            //               <div className="captcha">
            //                 <p className="pCaptcha">
            //                   {this.state.captcha.min} + {this.state.captcha.max} ={" "}
            //                 </p>
            //                 <input
            //                   name="captcha"
            //                   type="text"
            //                   value={values.captcha || ''}
            //                   onChange={handleChange}
            //                   className="captchaInput"
            //                 />
            //               </div>
            //               {this.state.errorCaptcha && (
            //                 <div className="textErrors">{ this.props.t('Captcha Invalid') }</div>
            //               )}
            //               {errors_signup.captcha && touched.captcha ? (
            //                 <div className="textErrors">{errors_signup.captcha}</div>
            //               ) : null}
            //             </div>

            //             <div className="connexionLigne w12">
            //               <a onClick={this.handleAlreadyHaveAnAccount}>{ this.props.t('Already have an account?') }</a>
            //               <button className="add" type="submit">{ this.props.t('REGISTER') }</button>
            //             </div>
            //         </div>
            //     </Form>
            //   );}}
            // </Formik>
            // <div style={{margin: "15px", textAlign: "justify"}}>
            //   <h3> { this.props.t('Fonctionnalité non disponible') } </h3>
            //   <div >
            //     {this.props.t("Cette fonctionnalité n'est pas disponible pour le moment")}.
            //   </div>
            //   <div>
            //     {this.props.t("Veuillez demander vos identifiants au niveau de votre école")}.
            //   </div>
            // </div>
            <RegisterFormComponent 
              pages="parent"
              handleOpenSuccess={(msg) => {
                this.props.handleCloseParentMobile();
                this.props.handleOpenSuccess(msg);
              }}
              handleOpenTermsAndCondition={this.props.handleOpenTermsAndCondition}
            />
          )}
        </div>
      </Modal>

      {this.state.open && (
        <ModalPassword
          open={this.state.open}
          onCloseModalPassword={this.onCloseModalPassword}
          handlePasswordReseted={(message) => this.handlePasswordReseted(message)} />
      )}
      <ModalCompteSuccess success={this.state.success} handleCloseSuccess={this.handleCloseSuccess} 
          message={this.state.messageSuccess}
          type={this.state.is_email ? "email" : "sms"} 
          phone_number={this.state.phone_number} />
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(ModalParentMobile)));