import * as firebase from "firebase/app";
import "firebase/messaging";

// const initializedFirebaseApp = firebase.initializeApp({
//     messagingSenderId: "196176104641"
// });
var messaging = null;
try{
  // Your web app's Firebase configuration
  let firebaseConfig = {}, public_key = '';
  if(process.env.REACT_APP_URL_WS == 'https://apipreprod.koolskools.com'){
    public_key = "BNmLX_dYlpvTOp3te8-NTXAQ3UwblUezvHoydvreiYapA4CVpCIb33yh4u2-MXi9SVPJVBXSat9r_iOCaEwKvT4"
    firebaseConfig = {
      apiKey: "AIzaSyAkAqnI0cEfS4NAVb8jh6EYSZwlSQUL2lU",
      authDomain: "koolskools-preprod.firebaseapp.com",
      projectId: "koolskools-preprod",
      storageBucket: "koolskools-preprod.firebasestorage.app",
      messagingSenderId: "421037871858",
      appId: "1:421037871858:web:9cdaceef8c634d1369366b",
      measurementId: "G-RCJJDPFGSS"
    };
  }
  else{
    public_key = "BAzPEXXila-EE69SVPSYfCXnKnlGsOMQ5vi63Sl62oZNggLuf0gZ8c_BosRU6eBdWbHrfhNPwpYA2nze6V-wz5g"
    firebaseConfig = {
      apiKey: "AIzaSyBwKgxTC7gDFeUMaYNJY31mfTw0241DdO0",
      authDomain: "smooth-copilot-201708.firebaseapp.com",
      databaseURL: "https://smooth-copilot-201708.firebaseio.com",
      projectId: "smooth-copilot-201708",
      storageBucket: "smooth-copilot-201708.appspot.com",
      messagingSenderId: "196176104641",
      appId: "1:196176104641:web:1b355a8417ffc61dc1d840",
      measurementId: "G-Y4WEC4YV10"
    };
  }
  // Initialize Firebase
  const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);

  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(public_key);

}catch(err){
}


export { messaging };