import React, { Component, Children } from "react";
import { Redirect, withRouter } from "react-router-dom";
// import { listFriends, friends_api_list } from "services/api";
import {log_js_error, school_api_info} from 'services/cms/api';
import { get, set } from "services/storage";
class LayoutChildSystem extends Component {
  constructor(props) {
    super(props);
    this.user = get("session_user");
    this.state = {
      groups:[],
      nombre_invitation:this.user.nombre_invitation,
      nombre_zoom_not_seen: this.user.nombre_zoom_not_seen,
      current_group: false,
      is_new_conversation: false,
      friends:[],
      score: this.user.profile.score,
      school: this.user.profile.schools.length > 0?this.user.profile.schools[0]:false,
      extra_props: { },
      handleChangeGroup : (group) => this.setState({
                                          current_group:group
                                        }),
      handleCloseConversation: () => this.setState({
        is_new_conversation: false
      }),
      handleAddScore: score => {
        this.user.profile.score += score;
        set('session_user', this.user);
        this.setState({
          score: this.user.profile.score
        })
      },
      todayExamsNotifications: (exams) => this.handleExamsNotifications(exams),
      handleDecrementNombreInvitation: () => {
          this.user.nombre_invitation = this.user.nombre_invitation - 1;
          set("session_user", this.user);
          this.setState({
            nombre_invitation: this.state.nombre_invitation - 1
          });
        this.forceUpdate()
      },
      handleDecrementNombreZoomSeen: ()=>{
        this.user.nombre_zoom_not_seen = this.user.nombre_zoom_not_seen -1;
        set("session_user",this.user);
        this.setState({
          nombre_zoom_not_seen: this.state.nombre_zoom_not_seen -1
        });
        this.forceUpdate()
      }
    };

    if(this.state.school)
      school_api_info({school: this.state.school.id}).then(res => {
        this.state.school.show_events_student = res.show_events_student;
        this.state.school.show_posts_student = res.show_posts_student;
        this.setState({
          school : this.state.school
        })
      })
    if(this.state.school && this.state.school.enable_chat)
      this.ws = new WebSocket(
        `${process.env.REACT_APP_URL_MESSAGING}/ws/users/?username=${this.user.profile.username}`
      )
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.props.history.push('/ErrorPage');
    var data={
      error_stack: error.stack
    }
    log_js_error(data);
  }

  hanleExamsNotification = (exams, is_notification) => {
    // alert("exam in progress, is_notification: "+is_notification)
  }

  handleExamsNotifications = (exams) => {
    var remaining_times = {};
    exams.today_exams.map(exam => {
      if(exam.duration_left_to_start_minutes < 0 && exam.duration_minutes + exam.duration_left_to_start_minutes > 0){
        if(!remaining_times["in_progress"]){
          remaining_times["in_progress"] = [];
        }
        remaining_times["in_progress"].push(exam);
      }
      else if(0 < exam.duration_left_to_start_minutes && exam.duration_left_to_start_minutes <= 5){
        if(!remaining_times["to_notify"]){
          remaining_times["to_notify"] = [];
        }
        remaining_times["to_notify"].push(exam);
      }
      else if(exam.duration_left_to_start_minutes > 0){
        if(!remaining_times[""+exam.duration_left_to_start_minutes]){
          remaining_times[""+exam.duration_left_to_start_minutes] = [];
        }
        remaining_times[""+exam.duration_left_to_start_minutes].push(exam);
      }
    });
    Object.keys(remaining_times).map(duration_left_to_start_minutes => {
      if(duration_left_to_start_minutes == "in_progress"){
        this.hanleExamsNotification(remaining_times[duration_left_to_start_minutes], true);
      }
      else if(duration_left_to_start_minutes == "to_notify"){
        this.hanleExamsNotification(remaining_times[duration_left_to_start_minutes], true);
      }
      else{
        this["exams_left_"+duration_left_to_start_minutes] = parseInt(duration_left_to_start_minutes);
        this["exams_after_"+duration_left_to_start_minutes] = setInterval(() => {
          let left_minutes_to_start = this["exams_left_"+duration_left_to_start_minutes];
          this["exams_left_"+duration_left_to_start_minutes] = left_minutes_to_start - 1;

          if (this["exams_left_"+duration_left_to_start_minutes] <= 5) {
            this.hanleExamsNotification(remaining_times[duration_left_to_start_minutes], true);
            clearInterval(this["exams_after_"+duration_left_to_start_minutes]);
          }
        }, 60000);
      }
    })
  }

  componentDidMount(){
    if(this.ws){
      this.ws.onopen = (e) => {
        this.ws.send(
            JSON.stringify({
                action: 'show_conversations',
                username: this.user.profile.username
            })
        );
      } 
  
      this.ws.onmessage = evt => {
        var {groups, current_group} = this.state;
        const data = JSON.parse(evt.data);
        if(data.messages){
          groups.forEach(group=> {
              if(group.group_id == data.group_id){
                  group.messages = data.messages;
                  // group.not_seen = user_info.not_seen;
              }
          });
          this.setState({
              groups: groups
          })
        }
        else if(data.message){
          let group_;
          this.setState({
            groups: groups.map(group => {
              if(group.group_id == data.group.group_id){
                group_ = data.group;
                if(group_.type == 0){
                  let last_message_index = group_.messages.length - 1;
                  let last_message = group_.messages[last_message_index];
                  last_message.seen = false;
                  group_.messages[last_message_index] = last_message;
                }
                return group_;
              }
              return group;
            }),
            current_group: (data.group.group_id == current_group.group_id)? group_ || data.group:current_group
          });
        }
        else if(data.group){
            // Create a new group
          if(data.action != "new_group"){
            if(groups.filter(group => group.group_id == data.group.group_id).length > 0 ){
              groups = groups.map((group) =>{
                  if(group.group_id == data.group.group_id)
                      return data.group;
                  return group;
              })   
            }    
            else
                groups.unshift(data.group);
            this.setState({
                groups:groups,
                current_group:data.group,
                is_new_conversation:true
            });
          }
        }
        else if(data.new_conversation){
          if(groups.filter(group => group.group_id == data.new_conversation.group_id).length == 0 )  
            groups.unshift(data.new_conversation);
          this.setState({
            groups:groups,
            current_group: data.sender == this.user.profile.username?data.new_conversation:current_group,
            is_new_conversation:data.sender == this.user.profile.username
          });
        }
        else if(data.conversation_left){
          var id_index = groups.map((conversation, idx) => [conversation.group_id, idx]).filter(g_idx => {return g_idx[0] === data.conversation_id});
          var idx_conv = id_index.length ? id_index[1] : null;
          if(idx_conv !== null){
            groups.splice(idx_conv, 1);
            this.setState({
              groups: groups,
              current_group: (current_group.group_id==data.group_id)?false:current_group,
            });
          }
        }
        else if(data.group_left){
          var id_index = groups.map((group, idx) => [group.group_id, idx]).filter(g_idx => {return g_idx[0] === data.group_id});
          var idx_group = id_index.length ? id_index[1] : null;
          if(idx_group !== null){
              groups.splice(idx_group, 1);
              this.setState({
                groups: groups,
                current_group: (current_group.group_id==data.group_id)?false:current_group,
              });
          }
        }
        else if(data.classe){
        }
        else if(data.group_id){
          
          if(data.action =="remove_group"){
            this.setState({
              groups: groups.filter(group => group.group_id != data.group_id),
              current_group: (current_group.group_id==data.group_id)?false:current_group,
              is_new_conversation:false
            })
          }
        }
        else if(data.username){
        }
        else if(data.error){   
        }
        else if(data.stop_traitement){
          groups = groups.map(group => {
            if(group.group_id == data.conversation.group_id)
              return data.conversation;
            return group;
          })
          
          this.setState({
            groups: groups,
            // current_group: data.conversation,
            is_new_conversation:false
          })
        }
        else if(data.conversations && data.groups){
          // get list of groups and conversations
          var groups = data.groups.concat(data.conversations)
          this.user.classes.forEach(class_item => {
            if(groups.filter(group => group.group_id == class_item.classe_id).length == 0){
              this.ws.send(
                  JSON.stringify({
                      users: [this.user.profile.username],
                      group_id: class_item.classe_id
                  })
              );
            }
          })
          groups.slice(0, 15).sort((a,b) => Date.parse(b.last_time) - Date.parse(a.last_time)).forEach(group => {
            this.ws.send(
                JSON.stringify({
                    action: 'show_messages',
                    username: this.user.profile.username,
                    group_id: group.group_id
                })
            );
          });
          this.setState({
            groups: groups,
            current_group: groups.length?groups[0]:false,
            is_new_conversation:false
          });
  
  
        }
      };
    } 

    // friends_api_list(this.user.classId).then(res => {
    //   this.setState({
    //     friends: res.friends
    //   })
    // });

    // listFriends(this.user.token).then(res =>
    //   this.setState({
    //     friends: res.friends
    //   })
    // );
  }


  render() {
    const { Layout, Component } = this.props;
    return (
      <Layout>
        <Component
          {...this.props}
          extra_props={this.state.extra_props}
          global_data={this.state}
          ws={this.ws}
        />
      </Layout>
    );
  }
}

export default withRouter(LayoutChildSystem);
