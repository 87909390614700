import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import Button from "../../../../components/Button";
import TextInput from "../../../../components/TextInput";
import { withTranslation, Trans } from 'react-i18next';
import { forgotPasswordAction } from "../../../../services/actions";
import { sanady_unconnected_api_get, sanady_unconnected_api_post } from "services/cms/api";
import {organize_programs_projects_schools, get_projects_from_pogram} from "utils/utils"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import moment from "moment";
import IntlTelInput from 'react-intl-tel-input';
import styled from "styled-components"
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();
const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class ModalProfessorSanadiRegister extends Component {
  constructor(props){
    super(props);
    Array.prototype.unique = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i] === a[j])
                  a.splice(j--, 1);
          }
      }
      return a;
    };
    Array.prototype.uniqueLevelsGroups = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i].id === a[j].id)
                  a.splice(j--, 1);
          }
      }
      return a;
    };
    Array.prototype.uniqueLevels = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i].level_id === a[j].level_id)
                  a.splice(j--, 1);
          }
      }
      return a;
    };
    Array.prototype.uniqueCodes = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i].subject_code && a[i].subject_code === a[j].subject_code)
                  a.splice(j--, 1);
          }
      }
      return a;
    };
    let projects_programs = organize_programs_projects_schools(props.programs_projects_schools || {}, true, true);
    this.state = {
      professor_already_exists: props.professor_already_exists,
      last_name: "",
      first_name: "",
      phone_number: "",
      phone_number_is_valid: true,
      phone_number_cc: "",
      email: "",
      email_is_valid: true,
      cin: "",
      num_som: "",
      teaching_seniority: null,
      address: "",
      city: "",
      province_men: "",
      school_men: "",
      school_id: "",
      project_id: "",
      province: "",
      projects_ids: [],
      programs_ids: [],
      schools_ids: [],
      levels_groups_ids: [],
      levels_ids: [],
      hide_levels: true,
      subjects_ids: [],
      subjects_codes: [],
      rib: "",
      gender: "",
      username: "",
      password: "",
      password2: "",
      errors: {},
      schools_levels_groups: {},
      response_message: "",
      projects: projects_programs[0] || [],
      programs: projects_programs[1] || [],
    };
  }
  
  componentWillReceiveProps(props){
    var new_state = {};
    let projects_programs = organize_programs_projects_schools(props.programs_projects_schools || {}, true, true);
    if(this.state.projects.length == 0 && Object.keys(props.programs_projects_schools || {}).length != 0){
      new_state.projects = projects_programs[0];
      new_state.programs = projects_programs[1];
    }
    var get_prof_info = false;
    if(this.state.professor_already_exists != props.professor_already_exists){
      new_state.professor_already_exists = props.professor_already_exists;
      get_prof_info = true;
    }

    if(Object.keys(new_state).length != 0){
      this.setState(new_state);
    }
  }

  handleChange = (e, key) => {
    var {schools_levels_groups} = this.state;
    this.state.errors[key] = "";
    var new_state = {errors: this.state.errors};
    new_state[key] = e.target.value;
    if(key == "username"){
      new_state[key] = (e.target.value || "").replaceAll(" ", "");
      if(this.usernameTypingTimer){
        clearTimeout(this.usernameTypingTimer);
      }
      var this_ = this;
      this.usernameTypingTimer = setTimeout(() => {
        this_.checkUsername();
      }, 500);
    }
    else if(false && key == "num_som"){
      if(this.numSomTypingTimer){
        clearTimeout(this.numSomTypingTimer);
      }
      var this_ = this;
      this.numSomTypingTimer = setTimeout(() => {
        this_.checkSOM();
      }, 500);
    }
    else if(key == "email"){
      if(this.emailTypingTimer){
        clearTimeout(this.emailTypingTimer);
      }
      var this_ = this;
      this.emailTypingTimer = setTimeout(() => {
        this_.checkEmail();
      }, 500);
    }
    else if(key == "school_id"){
      let school_id = e.target.value;
      if(school_id && !this.state.schools_levels_groups[school_id]){
        this.getSchoolLevelsGroups(school_id);
      }
    }
    else if(key == "schools_ids"){
      let schools_ids = (e.target.value || []).filter(itm =>itm);
      new_state.schools_ids = [...schools_ids];
      schools_ids = schools_ids.filter(si => !this.state.schools_levels_groups[si]);
      if(schools_ids.length > 0){
        this.getSchoolLevelsGroups(schools_ids[0], schools_ids);
      }
      else{
        let only_indh = this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1 && pg.code == "INDH").length == 1;
        let indh_and_other = only_indh && this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1).length > 1;
        let levels = [];
        let subjects = [];
        let levels_groups = [];
        let lvls_ = [];
        schools_ids.map(school_id_ => {
          levels_groups = levels_groups.concat(schools_levels_groups[school_id_] || []);
          let levels_groups_ = schools_levels_groups[school_id_] || [];
          let levels_ = [];
          levels_groups_.map(lg => {
            if(this.state.levels_groups_ids.indexOf(lg.id) != -1){
              levels = levels.concat(lg.levels);
              
              levels_ = levels_.concat(lg.levels);
              lg.levels[0] && lvls_.push(lg.levels[0]);
            }
          });
          levels_.map(l => {
            if(this.state.levels_ids.indexOf(l.level_id) != -1){
              l.subjects.map(s => {
                if(subjects.filter(sub => sub.subject_id == s.subject_id).length == 0){
                  subjects.push(s);
                }
              });
            }
          });
        });
        levels_groups = levels_groups.uniqueLevelsGroups();
        levels_groups.sort((a, b) => a.num > b.num ? 1 : -1);
        let tarl_level_group = undefined;
        levels_groups = levels_groups.filter(lg => {
          if(lg.is_tarl){
            tarl_level_group = lg;
            return false;
          }
          return true;
        });
        if(tarl_level_group){
          levels_groups.unshift(tarl_level_group);
        }
        levels = levels.uniqueLevels();
        lvls_ = lvls_.uniqueLevels();
        subjects = subjects.uniqueCodes();
        new_state.levels_groups_ids = levels_groups.filter(lg => this.state.levels_groups_ids.indexOf(lg.id) != -1).map(lg => lg.id);
        //new_state.levels_ids = levels.filter(l => only_indh && !indh_and_other || this.state.levels_ids.indexOf(l.level_id) != -1).map(l => l.level_id);
        new_state.levels_ids = lvls_.map(l => l.level_id);
        new_state.subjects_ids = subjects.filter(s => this.state.subjects_ids.indexOf(s.subject_id) != -1).map(s => s.subject_id);
      }
    }
    else if(key == "programs_ids"){
      let programs_ids = (e.target.value || []).filter(itm =>itm);
      new_state.programs_ids = programs_ids;
      let schools_ids = [];
      let only_indh = this.state.programs.filter(pg => programs_ids.indexOf(pg.id) != -1 && pg.code == "INDH").length == 1;
      let indh_and_other = only_indh && this.state.programs.filter(pg => programs_ids.indexOf(pg.id) != -1).length > 1;
      new_state.hide_levels = true || only_indh && !indh_and_other;
      new_state.projects_ids = this.state.projects.filter(pj => programs_ids.indexOf(pj.program_id) != -1).filter(pj => this.state.projects_ids.indexOf(pj.id) != -1).map(pj => {
        schools_ids = schools_ids.concat(pj.schools.map(sc => sc.id));
        return pj.id;
      });
      new_state.schools_ids = schools_ids.filter(sid => this.state.schools_ids.indexOf(sid) != -1);
      let levels = [];
      let lvls_ = [];
      let subjects = [];
      let levels_groups = [];
      schools_ids.map(school_id_ => {
        levels_groups = levels_groups.concat(schools_levels_groups[school_id_] || []);
        let levels_groups_ = schools_levels_groups[school_id_] || [];
        let levels_ = [];
        levels_groups_.map(lg => {
          if(this.state.levels_groups_ids.indexOf(lg.id) != -1){
            levels = levels.concat(lg.levels);
            
            levels_ = levels_.concat(lg.levels);
            lg.levels[0] && lvls_.push(lg.levels[0]);
          }
        });
        levels_.map(l => {
          if(this.state.levels_ids.indexOf(l.level_id) != -1){
            l.subjects.map(s => {
              if(subjects.filter(sub => sub.subject_id == s.subject_id).length == 0){
                subjects.push(s);
              }
            });
          }
        });
      })
      levels_groups = levels_groups.uniqueLevelsGroups();
      levels_groups.sort((a, b) => a.num > b.num ? 1 : -1);
      let tarl_level_group = undefined;
      levels_groups = levels_groups.filter(lg => {
        if(lg.is_tarl){
          tarl_level_group = lg;
          return false;
        }
        return true;
      });
      if(tarl_level_group){
        levels_groups.unshift(tarl_level_group);
      }
      levels = levels.uniqueLevels();
      lvls_ = lvls_.uniqueLevels();
      subjects = subjects.uniqueCodes();
      new_state.levels_groups_ids = levels_groups.filter(lg => this.state.levels_groups_ids.indexOf(lg.id) != -1).map(lg => lg.id);
      //new_state.levels_ids = levels.filter(l => only_indh && !indh_and_other || this.state.levels_ids.indexOf(l.level_id) != -1).map(l => l.level_id);
      new_state.levels_ids = lvls_.map(l => l.level_id);
      new_state.subjects_ids = subjects.filter(s => this.state.subjects_ids.indexOf(s.subject_id) != -1).map(s => s.subject_id);
    }
    else if(key == "projects_ids"){
      let projects_ids = (e.target.value || []).filter(itm =>itm);
      new_state.projects_ids = projects_ids;
      let schools_ids = [];
      let only_indh = this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1 && pg.code == "INDH").length == 1;
      let indh_and_other = only_indh && this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1).length > 1;
      this.state.projects.filter(pj => this.state.programs_ids.indexOf(pj.program_id) != -1).filter(pj => projects_ids.indexOf(pj.id) != -1).map(pj => {
        schools_ids = schools_ids.concat(pj.schools.map(sc => sc.id));
        return pj.id;
      });
      new_state.schools_ids = schools_ids.filter(sid => this.state.schools_ids.indexOf(sid) != -1);
      let levels = [];
      let lvls_ = [];
      let subjects = [];
      let levels_groups = [];
      schools_ids.map(school_id_ => {
        levels_groups = levels_groups.concat(schools_levels_groups[school_id_] || []);
        let levels_groups_ = schools_levels_groups[school_id_] || [];
        let levels_ = [];
        levels_groups_.map(lg => {
          if(this.state.levels_groups_ids.indexOf(lg.id) != -1){
            levels = levels.concat(lg.levels);
            
            levels_ = levels_.concat(lg.levels);
            lg.levels[0] && lvls_.push(lg.levels[0]);
          }
        });
        levels_.map(l => {
          if(this.state.levels_ids.indexOf(l.level_id) != -1){
            l.subjects.map(s => {
              if(subjects.filter(sub => sub.subject_id == s.subject_id).length == 0){
                subjects.push(s);
              }
            });
          }
        });
      });
      levels_groups = levels_groups.uniqueLevelsGroups();
      levels_groups.sort((a, b) => a.num > b.num ? 1 : -1);
      let tarl_level_group = undefined;
      levels_groups = levels_groups.filter(lg => {
        if(lg.is_tarl){
          tarl_level_group = lg;
          return false;
        }
        return true;
      });
      if(tarl_level_group){
        levels_groups.unshift(tarl_level_group);
      }
      levels = levels.uniqueLevels();
      lvls_ = lvls_.uniqueLevels();
      subjects = subjects.uniqueCodes();
      new_state.levels_groups_ids = levels_groups.filter(lg => this.state.levels_groups_ids.indexOf(lg.id) != -1).map(lg => lg.id);
      //new_state.levels_ids = levels.filter(l => only_indh && !indh_and_other || this.state.levels_ids.indexOf(l.level_id) != -1).map(l => l.level_id);
      new_state.levels_ids = lvls_.map(l => l.level_id);
      new_state.subjects_ids = subjects.filter(s => this.state.subjects_ids.indexOf(s.subject_id) != -1).map(s => s.subject_id);
    }
    else if(key == "levels_groups_ids"){
      let levels_groups_ids = e.target.value || [];
      let schools_ids = this.state.schools_ids;
      let only_indh = this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1 && pg.code == "INDH").length == 1;
      let indh_and_other = only_indh && this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1).length > 1;
      let levels = [];
      let lvls_ = [];
      let subjects = [];
      let levels_groups = [];
      schools_ids.map(school_id_ => {
        levels_groups = levels_groups.concat(schools_levels_groups[school_id_] || []);
        let levels_groups_ = schools_levels_groups[school_id_] || [];
        let levels_ = [];
        levels_groups_.map(lg => {
          if(levels_groups_ids.indexOf(lg.id) != -1){
            levels = levels.concat(lg.levels);
            
            levels_ = levels_.concat(lg.levels);
            lg.levels[0] && lvls_.push(lg.levels[0]);
          }
        });
        levels_.map(l => {
          if(this.state.levels_ids.indexOf(l.level_id) != -1){
            l.subjects.map(s => {
              if(subjects.filter(sub => sub.subject_id == s.subject_id).length == 0){
                subjects.push(s);
              }
            });
          }
        });
      });
      levels_groups = levels_groups.uniqueLevelsGroups();
      levels_groups.sort((a, b) => a.num > b.num ? 1 : -1);
      let tarl_level_group = undefined;
      levels_groups = levels_groups.filter(lg => {
        if(lg.is_tarl){
          tarl_level_group = lg;
          return false;
        }
        return true;
      });
      if(tarl_level_group){
        levels_groups.unshift(tarl_level_group);
      }
      levels = levels.uniqueLevels();
      lvls_ = lvls_.uniqueLevels();
      subjects = subjects.uniqueCodes();
      //new_state.levels_ids = levels.filter(l => only_indh && !indh_and_other || this.state.levels_ids.indexOf(l.level_id) != -1).map(l => l.level_id);
      new_state.levels_ids = lvls_.map(l => l.level_id);
      new_state.subjects_ids = subjects.filter(s => this.state.subjects_ids.indexOf(s.subject_id) != -1).map(s => s.subject_id);
    }
    this.setState(new_state);
  };

  getSchoolLevelsGroups = (school_id, schools_ids, set_subjects_codes) => {
    let data = {
      action: "get_school_levels_subjects",
      school_id: school_id,
    };
    if(schools_ids){
      data.schools_ids = schools_ids.join(",");
    }
    sanady_unconnected_api_post(data).then(res => {
      var {schools_levels_groups} = this.state;
      if(res.success){
        (schools_ids ? schools_ids : [school_id]).map(si => {
          schools_levels_groups[si] =( res.levels_groups || {})[si] || [];
        });
      }
      var new_state = {
        schools_levels_groups: schools_levels_groups,
      };
      let only_indh = this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1 && pg.code == "INDH").length == 1;
      let indh_and_other = only_indh && this.state.programs.filter(pg => this.state.programs_ids.indexOf(pg.id) != -1).length > 1;
      let levels = [];
      let lvls_ = [];
      let subjects = [];
      let levels_groups = [];
      schools_ids.map(school_id_ => {
        levels_groups = levels_groups.concat(schools_levels_groups[school_id_] || []);
        let levels_groups_ = schools_levels_groups[school_id_] || [];
        let levels_ = [];
        levels_groups_.map(lg => {
          if(this.state.levels_groups_ids.indexOf(lg.id) != -1){
            levels = levels.concat(lg.levels);
            
            levels_ = levels_.concat(lg.levels);
            lg.levels[0] && lvls_.push(lg.levels[0]);
          }
        });
        levels_.map(l => {
          if(this.state.levels_ids.indexOf(l.level_id) != -1){
            l.subjects.map(s => {
              if(subjects.filter(sub => sub.subject_id == s.subject_id).length == 0){
                subjects.push(s);
              }
            });
          }
        });
      });
      levels_groups = levels_groups.uniqueLevelsGroups();
      levels_groups.sort((a, b) => a.num > b.num ? 1 : -1);
      let tarl_level_group = undefined;
      levels_groups = levels_groups.filter(lg => {
        if(lg.is_tarl){
          tarl_level_group = lg;
          return false;
        }
        return true;
      });
      if(tarl_level_group){
        levels_groups.unshift(tarl_level_group);
      }
      levels = levels.uniqueLevels();
      lvls_ = lvls_.uniqueLevels();
      subjects = subjects.uniqueCodes();
      new_state.levels_groups_ids = levels_groups.filter(lg => this.state.levels_groups_ids.indexOf(lg.id) != -1).map(lg => lg.id);
      //new_state.levels_ids = levels.filter(l => only_indh && !indh_and_other || this.state.levels_ids.indexOf(l.level_id) != -1).map(l => l.level_id);
      new_state.levels_ids = lvls_.map(l => l.level_id);
      new_state.subjects_ids = subjects.filter(s => this.state.subjects_ids.indexOf(s.subject_id) != -1).map(s => s.subject_id);
      if(set_subjects_codes){
        new_state.subjects_codes = subjects.filter(sub => this.state.subjects_ids.indexOf(sub.subject_id) != -1).map(sub => sub.subject_code);
      }
      this.setState(new_state);
    })
    .catch(error => {
      
    });
  };
  checkSOM = () => {
    var {num_som} = this.state;
    if(!num_som){
      this.state.errors.num_som = "";
      this.setState({errors: this.state.errors});
      return;
    }
    let data = {
      action: "check_num_som",
      num_som: num_som,
    };
    sanady_unconnected_api_post(data).then(res => {
      var {errors, response_message} = this.state;
      if(res.response_message){
        if(res.key){
          errors[res.key] = this.props.t(res.response_message);
        }
        else{
          response_message = this.props.t(res.response_message);
        }
      }
      this.setState({
        response_message: response_message,
        errors: errors,
      });
    })
    .catch(error => {
      this.setState({
        error: error.error
      });
    });
  };
  checkUsername = () => {
    var {username} = this.state;
    if(!username){
      this.state.errors.username = "";
      this.setState({errors: this.state.errors});
      return;
    }
    let data = {
      action: "check_username",
      username: username,
    };
    sanady_unconnected_api_post(data).then(res => {
      var {errors, response_message} = this.state;
      if(res.response_message){
        if(res.key){
          errors[res.key] = this.props.t(res.response_message);
        }
        else{
          response_message = this.props.t(res.response_message);
        }
      }
      this.setState({
        response_message: response_message,
        errors: errors,
      });
    })
    .catch(error => {
      this.setState({
        error: error.error
      });
    });
  };
  checkEmail = () => {
    var {email, errors} = this.state;
    if(!email){
      this.state.errors.email = "";
      this.setState({errors: this.state.errors});
      return;
    }
    var email_is_valid = true
    if(!emailRegExp.test(email)){
      errors.email = this.props.t("This email is invalid");
      email_is_valid = false;
    }
    else{
      errors.email = "";
    }
    this.setState({
      email_is_valid: email_is_valid,
      errors: errors,
    });

    if(!email_is_valid)return;
    
    let data = {
      action: "check_email",
      email: email,
    };
    sanady_unconnected_api_post(data).then(res => {
      var {errors, response_message} = this.state;
      if(res.response_message){
        if(res.key){
          errors[res.key] = this.props.t(res.response_message);
        }
        else{
          response_message = this.props.t(res.response_message);
        }
      }
      this.setState({
        response_message: response_message,
        errors: errors,
      });
    })
    .catch(error => {
      this.setState({
        error: error.error
      });
    });
  };
  checkPhoneNumber = () => {
    var {phone_number, errors, phone_number_cc} = this.state;
    if(!phone_number){
      this.state.errors.phone_number = "";
      this.setState({errors: this.state.errors});
      return;
    }
    var phone_number_is_valid = true;
    var valid_number = false;
    // if not working; use regex:  var patt = /^((05)|(06)|(07)|(08))[0-9]{8}$/i; 
    try {valid_number = phoneUtil.isPossibleNumber(phoneUtil.parse(phone_number, phone_number_cc));}catch (error){}
    if(!valid_number){
      errors.phone_number = this.props.t('This number is invalid!');;
      phone_number_is_valid = false;
    }
    else{
      errors.phone_number = "";
      phone_number_is_valid = true;
    }
    this.setState({
      phone_number_is_valid: phone_number_is_valid,
      errors: errors,
    });

    if(!phone_number_is_valid)return;
    
    // let data = {
    //   action: "check_phone_number",
    //   phone_number: phone_number,
    // };
    // sanady_unconnected_api_post(data).then(res => {
    //   var {errors, response_message} = this.state;
    //   if(res.response_message){
    //     if(res.key){
    //       errors[res.key] = this.props.t(res.response_message);
    //     }
    //     else{
    //       response_message = this.props.t(res.response_message);
    //     }
    //   }
    //   this.setState({
    //     response_message: response_message,
    //     errors: errors,
    //   });
    // })
    // .catch(error => {
    //   this.setState({
    //     error: error.error
    //   });
    // });
  };

  isDigit = (str) => {
    return !/\D/.test(str);
  }
  handleCreateAccount = () => {
    if(this.state.response_message)this.setState({response_message: ""});
    var {
      last_name, first_name, phone_number, email, phone_number_cc, cin, num_som, teaching_seniority, address, city, province_men, school_men, rib,
      gender, province, project_id, projects_ids, programs_ids, levels_groups_ids, schools_ids, levels_ids, subjects_ids, subjects_codes,
      school_id, password, password2, username, schools_levels_groups
    } = this.state;
    var errors = {};
    var form_valid = true;
    if(username === "") {
      errors.username = this.props.t("This field is required");
      form_valid = false;
    }
    else if(this.state.errors["username"]) {
      errors.username = this.state.errors["username"];
      form_valid = false;
    }
    if(password === "") {
      errors.password = this.props.t("This field is required");
      form_valid = false;
    }
    if(password2 === "") {
      errors.password2 = this.props.t("This field is required");
      form_valid = false;
    }
    if(password2 && password && password !== password2) {
      errors.password = this.props.t("Passwords not matches");
      errors.password2 = this.props.t("Passwords not matches");
      form_valid = false;
    }
    if(last_name === "") {
      errors.last_name = this.props.t("This field is required");
      form_valid = false;
    }
    if(first_name === "") {
      errors.first_name = this.props.t("This field is required");
      form_valid = false;
    }
    var phone_number_is_valid = true;
    var valid_number = false;
    if(phone_number === "") {
      errors.phone_number = this.props.t("This field is required");
      form_valid = false;
    }
    // if not working; use regex:  var patt = /^((05)|(06)|(07)|(08))[0-9]{8}$/i; 
    try {valid_number = phoneUtil.isPossibleNumber(phoneUtil.parse(phone_number, phone_number_cc));}catch (error){}
    if(phone_number && !valid_number){
      errors.phone_number = this.props.t('This number is invalid!');;
      phone_number_is_valid = false;
    }
    var email_is_valid = true
    if(email === "") {
      errors.email = this.props.t("This field is required");
      form_valid = false;
    }
    else if(this.state.errors["email"]) {
      errors.email = this.state.errors["email"];
      form_valid = false;
    }
    else if(!emailRegExp.test(email)){
      errors.email = this.props.t("This email is invalid");
      email_is_valid = false;
      form_valid = false;
    }
    if(cin === "") {
      errors.cin = this.props.t("This field is required");
      form_valid = false;
    }
    if(!teaching_seniority && teaching_seniority != 0) {
      errors.teaching_seniority = this.props.t("This field is required");
      form_valid = false;
    }
    else if(teaching_seniority && teaching_seniority < 0) {
      errors.teaching_seniority = this.props.t("This value must be greater than or equal 0");
      form_valid = false;
    }
    if(num_som === "") {
      errors.num_som = this.props.t("This field is required");
      form_valid = false;
    }
    else if(this.state.errors["num_som"]) {
      errors.num_som = this.state.errors["num_som"];
      form_valid = false;
    }
    if(address === "") {
      errors.address = this.props.t("This field is required");
      form_valid = false;
    }
    if(city === "") {
      errors.city = this.props.t("This field is required");
      form_valid = false;
    }
    if(province_men === "") {
      errors.province_men = this.props.t("This field is required");
      form_valid = false;
    }
    if(school_men === "") {
      errors.school_men = this.props.t("This field is required");
      form_valid = false;
    }
    if(province === "") {
      errors.province = this.props.t("This field is required");
      form_valid = false;
    }
    if(programs_ids === "") {
      errors.programs_ids = this.props.t("This field is required");
      form_valid = false;
    }
    if(projects_ids === "") {
      errors.projects_ids = this.props.t("This field is required");
      form_valid = false;
    }
    // if(project_id === "") {
    //   errors.project_id = this.props.t("This field is required");
    //   form_valid = false;
    // }
    // if(school_id === "") {
    //   errors.school_id = this.props.t("This field is required");
    //   form_valid = false;
    // }
    if(schools_ids.length === 0) {
      errors.schools_ids = this.props.t("This field is required");
      form_valid = false;
    }
    if(levels_groups_ids.length === 0) {
      errors.levels_groups_ids = this.props.t("This field is required");
      form_valid = false;
    }
    if(levels_ids.length === 0) {
      errors.levels_ids = this.props.t("This field is required");
      form_valid = false;
    }
    if(subjects_codes.length === 0) {
      errors.subjects_codes = this.props.t("This field is required");
      form_valid = false;
    }
    if(rib === "") {
      errors.rib = this.props.t("This field is required");
      form_valid = false;
    }
    else if(!this.isDigit(rib)) {
      errors.rib = this.props.t("Le RIB doit contenir 24 chiffres!");
      form_valid = false;
    }
    else if(rib.length != 24) {
      errors.rib = this.props.t("Le RIB doit contenir 24 chiffres!");
      form_valid = false;
    }
    if(gender === "") {
      errors.gender = this.props.t("This field is required");
      form_valid = false;
    }
    if(!form_valid){
      this.setState({errors: errors, email_is_valid: email_is_valid, phone_number_is_valid: phone_number_is_valid});
      return;
    }
    else {
      let schools_levels_subjects = {};
      schools_ids.map(si => {
        let school_levels_groups = schools_levels_groups[si] || [];
        let levels_ = [], levels_ids_ = [];
        let subjects_codes__ = {};
        school_levels_groups.map(lg => {
          levels_ = levels_.concat(lg.levels);
        });
        levels_ids_ = levels_.map(l => l.level_id);
        let subjects_codes_ = [];
        levels_.map(l => {
          l.subjects.map(s => {
            if(s.subject_code){
              subjects_codes__[s.subject_code] = s.subject_id;
            }
            if(s.subject_code && subjects_codes_.indexOf(s.subject_code) == -1){
              subjects_codes_.push(s.subject_code);
            }
          });
        });
        schools_levels_subjects[si] = {
          levels_groups_ids: levels_groups_ids.filter(lgi => school_levels_groups.filter(slg => slg.id == lgi).length > 0),
          levels_ids: levels_ids.filter(lid => levels_ids_.indexOf(lid) != -1),
          subjects_ids: subjects_codes.filter(sc => sc && subjects_codes_.indexOf(sc) != -1).map(sc => subjects_codes__[sc]),
        }
      })
      let data = {
        action: "professor_registration",
        last_name: last_name,
        first_name: first_name,
        phone_number: phone_number,
        username: username,
        password: password,
        password2: password2,
        email: email,
        cin: cin,
        num_som: num_som,
        teaching_seniority: teaching_seniority,
        address: address,
        city: city,
        province_men: province_men,
        school_men: school_men,
        school_id: school_id,
        province: province,
        project_id: project_id,
        projects_ids: projects_ids,
        programs_ids: programs_ids,
        levels_groups_ids: levels_groups_ids,
        schools_ids: schools_ids,
        schools_levels_subjects: schools_levels_subjects,
        levels_ids: levels_ids,
        subjects_ids: subjects_ids,
        rib: rib,
        gender: gender,
        // update_professor: this.state.professor_already_exists && this.state.info_getted,
      };
      sanady_unconnected_api_post(data).then(res => {
        if(res.success){
          this.setState({creation_account_message: res.message})
          // this.props.handleAccountCreated(res.message);
        }
      })
      .catch(error => {
        this.setState({
          error: error.error
        });
      });
    }
  };
  handleGetProfessorInfo = () => {
    var {
      professor_num_som, getting_info
    } = this.state;
    var errors = {};
    var form_valid = true;
    if(!professor_num_som) {
      errors.professor_num_som = this.props.t("This field is required");
      form_valid = false;
    }
    if(!form_valid){
      this.setState({errors: errors});
      return;
    }
    else {
      this.setState({
        getting_info: true,
      });
      let data = {
        action: "get_professor_info",
        professor_num_som: professor_num_som,
      };
      sanady_unconnected_api_post(data).then(res => {
        var {schools_levels_groups} = this.state;
        var new_state = {getting_info: false, server_message: ""};
        if(res.professor_info){
          new_state.info_getted = true;
          new_state.last_name = res.professor_info.last_name;
          new_state.first_name = res.professor_info.first_name;
          new_state.phone_number = res.professor_info.phone_number;
          new_state.phone_number_is_valid = !!res.professor_info.phone_number;
          if(res.professor_info.phone_number){
            try{
              const phoneNumber = phoneUtil.parseAndKeepRawInput(res.professor_info.phone_number);
              const iso2CountryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
              new_state.phone_number_cc = iso2CountryCode;
            }
            catch{

            }
          }
          new_state.email = res.professor_info.email;
          new_state.email_is_valid = !!res.professor_info.email_is_valid;
          new_state.cin = res.professor_info.cin;
          new_state.num_som = res.professor_info.num_som;
          new_state.teaching_seniority = res.professor_info.teaching_seniority;
          new_state.address = res.professor_info.address;
          new_state.city = res.professor_info.city;
          new_state.province_men = res.professor_info.province_men;
          new_state.school_men = res.professor_info.school_men;
          new_state.province = res.professor_info.province;
          new_state.projects_ids = res.professor_info.projects_ids;
          new_state.programs_ids = res.professor_info.programs_ids;
          new_state.schools_ids = res.professor_info.schools_ids;
          var levels_groups_ids__ = [];
          var levels_ids__ = [];
          var subjects_ids__ = [];
          Object.keys(res.professor_info.schools_levels_subjects || {}).map(s_id => {
            levels_groups_ids__ = levels_groups_ids__.concat((res.professor_info.schools_levels_subjects[s_id] || {}).levels_groups_ids || []).unique();
            levels_ids__ = levels_ids__.concat((res.professor_info.schools_levels_subjects[s_id] || {}).levels_ids || []).unique();
            subjects_ids__ = subjects_ids__.concat((res.professor_info.schools_levels_subjects[s_id] || {}).subjects_ids || []).unique();
          });
          new_state.levels_groups_ids = levels_groups_ids__;
          new_state.levels_ids = levels_ids__;
          new_state.subjects_ids = subjects_ids__;
          var levels_groups = [];
          var levels = [];
          var subjects = [];
          new_state.schools_ids.map(school_id_ => {
            levels_groups = levels_groups.concat(schools_levels_groups[school_id_] || []);
            let levels_groups_ = schools_levels_groups[school_id_] || [];
            let levels_ = [];
            levels_groups_.map(lg => {
              if(new_state.levels_groups_ids.indexOf(lg.id) != -1){
                levels = levels.concat(lg.levels);

                levels_ = levels_.concat(lg.levels);
              }
            });
            levels_.map(l => {
              if(new_state.levels_ids.indexOf(l.level_id) != -1){
                l.subjects.map(s => {
                  if(subjects.filter(sub => sub.subject_id == s.subject_id).length == 0){
                    subjects.push(s);
                  }
                });
              }
            });
          })
          new_state.subjects_codes = subjects.filter(sub => new_state.subjects_ids.indexOf(sub.subject_id) != -1).map(sub => sub.subject_code);
          new_state.rib = res.professor_info.rib;
          new_state.gender = res.professor_info.gender;
          new_state.username = res.professor_info.username;
          new_state.password = "password";
          new_state.password2 = "password";
        }
        else{
          new_state.server_message = this.props.t("There is no professor with this SOM number!");
        }

        this.setState(new_state, () => {
          if(new_state.info_getted){
            this.getSchoolLevelsGroups(new_state.schools_ids[0], new_state.schools_ids, true);
          }
        });
      })
      .catch(error => {
        this.setState({
          getting_info: false,
          server_message: this.props.t('An error has occured, please try again'),
        });
        console.log(error.error);
      });
    }
  };
  onCloseModal = (evt, reset_creation_account_message) => {
    let this_ = this;
    setTimeout(() => {
      let new_state = {
        last_name: "",
        first_name: "",
        phone_number: "",
        phone_number_is_valid: true,
        phone_number_cc: "",
        email: "",
        email_is_valid: true,
        cin: "",
        num_som: "",
        teaching_seniority: null,
        address: "",
        city: "",
        province_men: "",
        school_men: "",
        school_id: "",
        project_id: "",
        province: "",
        projects_ids: [],
        programs_ids: [],
        schools_ids: [],
        levels_groups_ids: [],
        levels_ids: [],
        subjects_ids: [],
        subjects_codes: [],
        rib: "",
        gender: "",
        username: "",
        password: "",
        password2: "",
        errors: {},
        response_message: "",
      }
      if(reset_creation_account_message){
        new_state.creation_account_message = "";
      }
      this_.setState(new_state);
    }, 500);
    this.props.onCloseModal(evt);
  }
  render() {
    /* TODO: TRANSLATIONS */
    var {professor_already_exists, info_getted, getting_info, hide_levels, errors, projects, projects_ids, programs_ids, schools_ids, programs, schools_levels_groups, creation_account_message} = this.state;
    if(professor_already_exists && !info_getted){
      return (
        <Modal
          open={this.props.open}
          onClose={evt => {
            this.onCloseModal(evt,true);
          }}
          showCloseIcon={false}
          center
          classNames={{
            overlay: { dialogOverlay: "white", dialogMobile: "white", dialogGetProfInfo: "white" },
            modal: !global.is_koolskools?
            {
              dialog: "white",
              dialogSignIn: "white",
              dialogSignUp: "white",
              dialogSignUpDone: "white",
              dialogLaraki: global.is_laraki_logo?"white":false,
              dialogSanady: global.is_sanady_logo?"white":false,
              dialogPont: global.is_pont_logo?"white":false,
              dialogFMPS: global.is_fmps_logo?"white":false
            }
            :
            {
              dialog: "white",
              dialogSignIn: "white",
              dialogSignUpDone: "white",
              dialogSignUp: "white"
            }
          }}
        >
          <div
            className="closeRegister"
            onClick={evt => {
              this.onCloseModal(evt,true);
            }}
          >
            <span>x</span>
          </div>

          <h3 style={{color: global.is_koolskools?'#3db1a9':global.colors[1]}}> { this.props.t('Entrez votre numéro SOM') }</h3>
          <div className="creation_account_message">
            <div className="">
              <div className="field col col-12 col-md-12">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="professor_num_som"
                  label={this.props.t("SOM")}
                  value={this.state.professor_num_som}
                  onChange={evt => this.handleChange(evt, "professor_num_som")}
                  disabled={getting_info}
                />
                {errors.professor_num_som && (
                  <div className="textErrors">{errors.professor_num_som}</div>
                )}
              </div>
              {this.state.server_message &&
                <div className="textErrors">{this.state.server_message}</div>
              }
            </div>
            <Button style={{backgroundColor: global.is_koolskools?'#3db1a9':global.colors[1]}} disabled={getting_info} onPress={evt => {
              this.handleGetProfessorInfo(evt);
            }}>{ this.props.t('Ok') }</Button>
          </div>
        </Modal>
      );
    }
    if(creation_account_message){
      return (
        <Modal
          open={this.props.open}
          onClose={evt => {
            this.onCloseModal(evt,true);
          }}
          showCloseIcon={false}
          center
          classNames={{
            overlay: { dialogOverlay: "white", dialogMobile: "white" },
            modal: !global.is_koolskools?
            {
              dialog: "white",
              dialogSignIn: "white",
              dialogSignUp: "white",
              dialogSignUpDone: "white",
              dialogLaraki: global.is_laraki_logo?"white":false,
              dialogSanady: global.is_sanady_logo?"white":false,
              dialogPont: global.is_pont_logo?"white":false,
              dialogFMPS: global.is_fmps_logo?"white":false
            }
            :
            {
              dialog: "white",
              dialogSignIn: "white",
              dialogSignUpDone: "white",
              dialogSignUp: "white"
            }
          }}
        >
          <div
            className="closeRegister"
            onClick={evt => {
              this.onCloseModal(evt,true);
            }}
          >
            <span>x</span>
          </div>
  
          <h3 style={{color: global.is_koolskools?'#3db1a9':global.colors[1]}}> { this.props.t('Account created') }</h3>
          <div className="creation_account_message">
            <div className="">
              {creation_account_message}
            </div>
            <Button onPress={evt => {
              this.onCloseModal(evt,true);
            }}>{ this.props.t('Ok') }</Button>
          </div>
        </Modal>
      );
    }
    projects = projects.filter(pj => programs_ids.indexOf(pj.program_id) != -1);
    const selected_projects = projects.filter(pj => projects_ids.indexOf(pj.id) != -1);
    var schools_ = [];
    selected_projects.map(pr => {
      schools_ = schools_.concat(pr.schools);
    });
    let levels = [];
    let subjects = [];
    let levels_groups = [];
    schools_ids.map(school_id_ => {
      levels_groups = levels_groups.concat(schools_levels_groups[school_id_] || []);
      let levels_groups_ = schools_levels_groups[school_id_] || [];
      let levels_ = [];
      levels_groups_.map(lg => {
        if(this.state.levels_groups_ids.indexOf(lg.id) != -1){
          levels = levels.concat(lg.levels);
          
          levels_ = levels_.concat(lg.levels);
        }
      });
      levels_.map(l => {
        if(this.state.levels_ids.indexOf(l.level_id) != -1){
          l.subjects.map(s => {
            if(subjects.filter(sub => sub.subject_id == s.subject_id).length == 0){
              subjects.push(s);
            }
          });
        }
      });
    })
    levels_groups = levels_groups.uniqueLevelsGroups();
    levels_groups.sort((a, b) => a.num > b.num ? 1 : -1);
    let tarl_level_group = undefined;
    levels_groups = levels_groups.filter(lg => {
      if(lg.is_tarl){
        tarl_level_group = lg;
        return false;
      }
      return true;
    });
    if(tarl_level_group){
      levels_groups.unshift(tarl_level_group);
    }
    levels = levels.uniqueLevels();
    subjects = subjects.uniqueCodes();
    subjects.sort((a,b) => b.name.toLowerCase() - a.name.toLowerCase());
    var professor_info_exists = professor_already_exists && info_getted;
    return (
      <Modal
        open={this.props.open}
        onClose={evt => {
          this.onCloseModal(evt);
        }}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white", dialogMobile: "white" },
          modal: !global.is_koolskools?
          {
            dialog: "white",
            dialogSignIn: "white",
            dialogSignUp: "white",
            dialogLaraki: global.is_laraki_logo?"white":false,
            dialogSanady: global.is_sanady_logo?"white":false,
            dialogPont: global.is_pont_logo?"white":false,
            dialogFMPS: global.is_fmps_logo?"white":false
          }
          :
          {
            dialog: "white",
            dialogSignIn: "white",
            dialogSignUp: "white"
          }
        }}
      >
        <div
          className="closeRegister"
          onClick={evt => {
            this.onCloseModal(evt);
          }}
        >
          <span>x</span>
        </div>

        <h3 style={{color: global.is_koolskools?'#3db1a9':global.colors[1]}}> { this.props.t(professor_info_exists ? "Check your information and validate" : 'Create a new account') }</h3>
        <div className="formular">
          <div className="rows">
            <div className="row">
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="last_name"
                  label={this.props.t("Last name")}
                  value={this.state.last_name}
                  onChange={evt => this.handleChange(evt, "last_name")}
                />
                {errors.last_name && (
                  <div className="textErrors">{errors.last_name}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="first_name"
                  label={this.props.t("First name")}
                  value={this.state.first_name}
                  onChange={evt => this.handleChange(evt, "first_name")}
                />
                {errors.first_name && (
                  <div className="textErrors">{errors.first_name}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="phone_number"
                  label={this.props.t("Phone number")}
                  phone_number={this.state.phone_number}
                  is_phone_number={true}
                  onChange={(status, value_, countryData, number_, id_) => {
                    this.setState({phone_number: number_, phone_number_cc: countryData.iso2})
                  }}
                />
                {errors.phone_number && (
                  <div className="textErrors">{errors.phone_number}</div>
                )}
              </div>
              {/* <div className="field col col-12 col-md-6">
                <IntlTelInput inputClassName={""}
                  preferredCountries={['ma']} 
                  name="phone_number"
                  defaultValue={this.state.phone_number || ""}
                  onPhoneNumberChange={(status, value_, countryData, number_, id_) => {
                    this.setState({phone_number: number_, phone_number_cc: countryData.iso2})
                  }}
                />
                {errors.phone_number && (
                  <div className="textErrors">{errors.phone_number}</div>
                )}
              </div> */}
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="email"
                  label={this.props.t("Email")}
                  value={this.state.email}
                  onChange={evt => this.handleChange(evt, "email")}
                />
                {errors.email && (
                  <div className="textErrors">{errors.email}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="username"
                  label={this.props.t("Username")}
                  value={this.state.username}
                  onChange={evt => this.handleChange(evt, "username")}
                  disabled={professor_info_exists}
                />
                {errors.username && (
                  <div className="textErrors">{errors.username}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="password"
                  name="password"
                  label={this.props.t("Password")}
                  value={this.state.password}
                  onChange={evt => this.handleChange(evt, "password")}
                  disabled={professor_info_exists}
                />
                {errors.password && (
                  <div className="textErrors">{errors.password}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="password"
                  name="password2"
                  label={this.props.t("Re-enter the password")}
                  value={this.state.password2}
                  onChange={evt => this.handleChange(evt, "password2")}
                  disabled={professor_info_exists}
                />
                {errors.password2 && (
                  <div className="textErrors">{errors.password2}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="cin"
                  label={this.props.t("CIN")}
                  value={this.state.cin}
                  onChange={evt => this.handleChange(evt, "cin")}
                />
                {errors.cin && (
                  <div className="textErrors">{errors.cin}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="num_som"
                  label={this.props.t("SOM")}
                  value={this.state.num_som}
                  onChange={evt => this.handleChange(evt, "num_som")}
                  disabled={professor_info_exists}
                />
                {errors.num_som && (
                  <div className="textErrors">{errors.num_som}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="number"
                  name="teaching_seniority"
                  label={this.props.t("Ancienneté MEM")}
                  value={this.state.teaching_seniority || undefined}
                  onChange={evt => this.handleChange(evt, "teaching_seniority")}
                  disabled={professor_info_exists}
                />
                {errors.teaching_seniority && (
                  <div className="textErrors">{errors.teaching_seniority}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="address"
                  label={this.props.t("Address")}
                  value={this.state.address}
                  onChange={evt => this.handleChange(evt, "address")}
                />
                {errors.address && (
                  <div className="textErrors">{errors.address}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="city"
                  label={this.props.t("City")}
                  value={this.state.city}
                  onChange={evt => this.handleChange(evt, "city")}
                />
                {errors.city && (
                  <div className="textErrors">{errors.city}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="province_men"
                  label={this.props.t("Province d'affectation MEN")}
                  value={this.state.province_men}
                  onChange={evt => this.handleChange(evt, "province_men")}
                />
                {errors.province_men && (
                  <div className="textErrors">{errors.province_men}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="province"
                  label={this.props.t("Province de résidence")}
                  value={this.state.province}
                  onChange={evt => this.handleChange(evt, "province")}
                />
                {errors.province && (
                  <div className="textErrors">{errors.province}</div>
                )}
              </div>

              <div className="field col col-12 col-md-6">
                <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                  <TextField
                    id="sanadi_professors_programs_choices_menu"
                    select
                    label={this.props.t("Prgramme sanady")}
                    value={this.state.programs_ids}
                    SelectProps={{
                      multiple: true,
                    }}
                    helperText=""
                    variant="filled"
                    name="sanadi_professors_programs_choices_menu"
                    className="textInput"
                    onChange={evt => {
                      this.handleChange(evt, "programs_ids")
                    }}
                  >
                    <MenuItem value={""}>
                      {this.props.t("Select")}
                    </MenuItem>
                    <MenuItem>
                      <TextField
                        fullWidth
                        placeholder={this.props.t("Chercher par nom")}
                        value={this.state.searched_program}
                        onChange={evt => {
                          this.setState({searched_program: evt.target.value,})
                        }}
                      />
                    </MenuItem>
                    {programs.filter(pr => !this.state.searched_program || pr.name.toLowerCase().indexOf(this.state.searched_program.toLowerCase()) > -1).map(pg => {
                      return <MenuItem value={pg.id}>
                        {pg.name}
                      </MenuItem>
                    })}
                  </TextField>
                </Text>
                {errors.programs_ids && (
                  <div className="textErrors">{errors.programs_ids}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                  <TextField
                    id="sanadi_professors_projects_choices_menu"
                    select
                    label={this.props.t("Projet d'affectation Sanady")}
                    value={this.state.projects_ids}
                    SelectProps={{
                      multiple: true,
                    }}
                    helperText=""
                    variant="filled"
                    name="sanadi_professors_projects_choices_menu"
                    className="textInput"
                    onChange={evt => {
                      this.handleChange(evt, "projects_ids")
                    }}
                  >
                    <MenuItem value={""}>
                      {this.props.t("Select")}
                    </MenuItem>
                    <MenuItem>
                      <TextField
                        fullWidth
                        placeholder={this.props.t("Chercher par nom")}
                        value={this.state.searched_project}
                        onChange={evt => {
                          this.setState({searched_project: evt.target.value,})
                        }}
                      />
                    </MenuItem>
                    {projects.filter(pr => !this.state.searched_project || pr.name.toLowerCase().indexOf(this.state.searched_project.toLowerCase()) > -1).map(pr => {
                      return <MenuItem value={pr.id}>
                        {pr.name}
                      </MenuItem>
                    })}
                  </TextField>
                </Text>
                {errors.projects_ids && (
                  <div className="textErrors">{errors.projects_ids}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                  <TextField
                    id="sanadi_professors_schools_choices_menu"
                    select
                    label={this.props.t("Centre")}
                    value={this.state.schools_ids}
                    SelectProps={{
                      multiple: true,
                    }}
                    helperText=""
                    variant="filled"
                    name="sanadi_professors_schools_choices_menu"
                    className="textInput"
                    onChange={evt => {
                      this.handleChange(evt, "schools_ids")
                    }}
                  >
                    <MenuItem value={""}>
                      {this.props.t("Select")}
                    </MenuItem>
                    <MenuItem >
                      <TextField
                        fullWidth
                        placeholder={this.props.t("Chercher par nom")}
                        value={this.state.searched_school}
                        onChange={evt => {
                          this.setState({searched_school: evt.target.value,})
                        }}
                      />
                    </MenuItem>
                    {schools_.filter(pr => !this.state.searched_school || pr.name.toLowerCase().indexOf(this.state.searched_school.toLowerCase()) > -1).map(sc => {
                      return <MenuItem value={sc.id}>
                        {sc.name}
                      </MenuItem>
                    })}
                  </TextField>
                </Text>
                {errors.schools_ids && (
                  <div className="textErrors">{errors.schools_ids}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
              <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                  <TextField
                    id="sanadi_professors_school_men_is_sanady_choices_menu"
                    select
                    InputLabelProps={{
                      className: "fs_10",  // Ajout de la classe personnalisée pour le label
                    }}
                    label={this.props.t("Votre centre Sanady est-il identique à votre école d'affectation MENPS?")}
                    value={this.state.school_men}
                    helperText=""
                    variant="filled"
                    name="sanadi_professors_school_men_is_sanady_choices_menu"
                    className="textInput"
                    onChange={evt => {
                      this.handleChange(evt, "school_men")
                    }}
                  >
                      <MenuItem value={""}>
                        {this.props.t("Select")}
                      </MenuItem>
                      <MenuItem value={"o"}>
                        {this.props.t("Oui")}
                      </MenuItem>
                      <MenuItem value={"n"}>
                        {this.props.t("Non")}
                      </MenuItem>
                  </TextField>
                </Text>
                {errors.school_men && (
                  <div className="textErrors">{errors.school_men}</div>
                )}
              </div>

              <div className="field col col-12 col-md-6">
                <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                  <TextField
                    id="sanadi_professors_cycles_choices_menu"
                    select
                    label={this.props.t("Cycles")}
                    value={this.state.levels_groups_ids}
                    SelectProps={{
                      multiple: true,
                    }}
                    helperText=""
                    variant="filled"
                    name="sanadi_professors_cycles_choices_menu"
                    className="textInput"
                    onChange={evt => {
                      this.handleChange(evt, "levels_groups_ids")
                    }}
                  >
                    {/* <MenuItem value={""}>
                      {this.props.t("Select")}
                    </MenuItem> */}
                    {levels_groups.map(lg => {
                      return <MenuItem value={lg.id}>
                        {lg.name}
                      </MenuItem>
                    })}
                  </TextField>
                </Text>
                {errors.levels_groups_ids && (
                  <div className="textErrors">{errors.levels_groups_ids}</div>
                )}
              </div>
              {!hide_levels &&
                <div className="field col col-12 col-md-6">
                  <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                    <TextField
                      id="sanadi_professors_levels_choices_menu"
                      select
                      label={this.props.t("Levels")}
                      value={this.state.levels_ids}
                      SelectProps={{
                        multiple: true,
                      }}
                      helperText=""
                      variant="filled"
                      name="sanadi_professors_levels_choices_menu"
                      className="textInput"
                      onChange={evt => {
                        this.handleChange(evt, "levels_ids")
                      }}
                    >
                      {/* <MenuItem value={""}>
                        {this.props.t("Select")}
                      </MenuItem> */}
                      {levels.map(l => {
                        return <MenuItem value={l.level_id}>
                          {l.name}
                        </MenuItem>
                      })}
                    </TextField>
                  </Text>
                  {errors.levels_ids && (
                    <div className="textErrors">{errors.levels_ids}</div>
                  )}
                </div>
              }
              <div className="field col col-12 col-md-6">
                <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                  <TextField
                    id="sanadi_professors_subjects_choices_menu"
                    select
                    label={this.props.t("Subjects")}
                    value={this.state.subjects_codes}
                    SelectProps={{
                      multiple: true,
                    }}
                    helperText=""
                    variant="filled"
                    name="sanadi_professors_subjects_choices_menu"
                    className="textInput"
                    onChange={evt => {
                      this.handleChange(evt, "subjects_codes")
                    }}
                  >
                    {/* <MenuItem value={""}>
                      {this.props.t("Select")}
                    </MenuItem> */}
                    {subjects.filter(sc => sc.subject_code).map(sub => {
                      return <MenuItem value={sub.subject_code}>
                        {sub.name}
                      </MenuItem>
                    })}
                  </TextField>
                </Text>
                {errors.subjects_codes && (
                  <div className="textErrors">{errors.subjects_codes}</div>
                )}
              </div>
              <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="rib"
                  label={this.props.t("RIB")}
                  value={this.state.rib}
                  onChange={evt => this.handleChange(evt, "rib")}
                />
                {errors.rib && (
                  <div className="textErrors">{errors.rib}</div>
                )}
              </div>
              {/* <div className="field col col-12 col-md-6">
                <TextInput
                  className={`textInput `}
                  type="text"
                  name="gender"
                  label={this.props.t("Gender")}
                  value={this.state.gender}
                  onChange={evt => this.handleChange(evt, "gender")}
                />
                {errors.gender && (
                  <div className="textErrors">{errors.gender}</div>
                )}
              </div> */}
              <div className="field col col-12 col-md-6">
                <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} >
                  <TextField
                    id="sanadi_professors_gender_choices_menu"
                    select
                    label={this.props.t("Gender")}
                    value={this.state.gender}
                    helperText=""
                    variant="filled"
                    name="sanadi_professors_gender_choices_menu"
                    className="textInput"
                    onChange={evt => {
                      this.handleChange(evt, "gender")
                    }}
                  >
                      <MenuItem value={""}>
                        {this.props.t("Select")}
                      </MenuItem>
                      <MenuItem value={"m"}>
                        {this.props.t("Male")}
                      </MenuItem>
                      <MenuItem value={"f"}>
                        {this.props.t("Female")}
                      </MenuItem>
                  </TextField>
                </Text>
                {errors.gender && (
                  <div className="textErrors">{errors.gender}</div>
                )}
              </div>
            </div>
            {this.state.response_message && (
              <div className="response_message">{this.state.response_message}</div>
            )}
          </div>
          <Button onPress={this.handleCreateAccount}>{ this.props.t('Sign up') }</Button>
        </div>
      </Modal>
    );
  }
}

const Text = styled.div`
    .textInput > div:after, .textInput > div:before{
        border-bottom: 2px solid ${props => props.color}!important;
    }
    .textInput > div{
        background-color: transparent !important;
    }
`

export default connect()(withTranslation('translations')(ModalProfessorSanadiRegister));
