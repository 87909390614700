import React, { Component} from "react";
import { withRouter, Redirect} from "react-router-dom";
import { clear, set, get } from "services/storage";
import { unconnected_api_get, log_js_error} from "services/cms/api";
import axios from "axios";
const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
const base_url = process.env.REACT_APP_URL_CMS;
class LayoutUnconnectedSystem extends Component {
  constructor(props) {
    super(props);
    this.user = get("session_user");
    var school_id_target = props.match.params.school_id;
    this.state = {
      school_id_target: school_id_target,
      school_target: null,
      all_schools_targets: [],
      data_loaded: false,
    };
    this.getSchoolTargetData(school_id_target);
  }

  componentWillReceiveProps(props){
    if(this.props.match.params.school_id && this.state.school_id_target != props.match.params.school_id){
      var school_id_target = props.match.params.school_id;
      this.getSchoolTargetData(school_id_target);
      this.state = {
        school_id_target: school_id_target,
      };
    }
  }

  componentDidCatch(error, info) {
    // this.props.history.push('/ErrorPage');
    // var data={
    //   error_stack: error.stack,
    // }
    // log_js_error(data);
  }

  componentDidMount(){
  }

  getSchoolTargetData = school_id => {
    if(school_id && !window.api_sent){
      window.api_sent = true;
      var data = {
        school_id: school_id,
        action: "get_target_school",
      };
      var this_ = this;
      unconnected_api_get(data).then(res => {
        window.api_sent = false;
        if(res.success){
          this_.setState({
            school_target: res.school_target,
            all_schools_targets: res.all_schools_targets,
            current_scholar_year: res.selected_scholar_year,
            next_scholar_year: res.next_scholar_year,
          });
        }
      })
    }
  }

  render() {
    const { Layout, Component } = this.props;
    const {school_target, school_id_target} = this.state;
  return (
      <Layout 
        
      >
        <Component
          {...this.props}
          global_data={this.state}
        />
      </Layout>
    );
  }
};

export default withRouter(LayoutUnconnectedSystem);
