import logo from "../../../assets/img/logo.svg";
import logo_laraki from "../../../assets/img/logo_laraki.png";
import avatar from "../../../assets/img/col9.jpg";
import iconSettings from "../../../assets/img/iconSettings.svg";
import iconSettingsF from "../../../assets/img/iconSettingsF.svg";
import iconTransport from "../../../assets/img/iconTransport.svg";
import iconTransportF from "../../../assets/img/iconTransportF.svg";
import iconTransportActive from "../../../assets/img/iconTransportActive.svg";
import iconCantine from "../../../assets/img/iconCantine.svg";
import iconCantineF from "../../../assets/img/iconCantineF.svg";
import iconCantineActive from "../../../assets/img/iconCantineActive.svg";
import iconPurchaseOrder from "../../../assets/img/purchase_order.svg";
import iconPurchaseOrderF from "../../../assets/img/purchase_orderF.svg";
import iconPurchaseOrderActive from "../../../assets/img/purchase_orderActive.svg";
import iconSettingsActive from "../../../assets/img/iconSettingsActive.svg";
import iconProfessor from "../../../assets/img/iconProfessor.svg";
import iconProfessorF from "../../../assets/img/iconProfessorF.svg";
import iconSondage from "../../../assets/img/iconSondage.svg";
import iconSondageF from "../../../assets/img/iconSondageF.svg";
import iconStudents from "../../../assets/img/iconStudents.svg";
import iconStudentsF from "../../../assets/img/iconStudentsF.svg";
import iconClasses from "../../../assets/img/iconClasses.svg";
import iconClassesF from "../../../assets/img/iconClassesF.svg";
import iconGallery from "../../../assets/img/iconGallery.svg";
import iconGalleryActive from "../../../assets/img/iconGalleryActive.svg";
import iconTransmission from "../../../assets/img/iconTransmission.svg";
import iconTransmissionActive from "../../../assets/img/iconTransmissionActive.svg";

import iconExo from "../../../assets/img/book1.svg";
// import iconLibrary from "../../../assets/img/iconLibrary.svg";
// import iconLibraryActive from "../../../assets/img/iconLibraryActive.svg";
import iconActivities from "../../../assets/img/iconActivities.svg";
import iconActivitiesActive from "../../../assets/img/iconActivitiesActive.svg";
import iconLibraryActive from "../../../assets/img/resource_active.svg";
import iconLibrary from "../../../assets/img/resource.svg";
import iconLibraryF from "../../../assets/img/resourceF.svg";
import iconCourses from "../../../assets/img/iconCourses.svg";
import iconCoursesF from "../../../assets/img/iconCoursesf.svg";
import iconCoursesActive from "../../../assets/img/iconCoursesActive.svg";
import iconExercices from "../../../assets/img/iconExercices.svg";
import iconExercicesF from "../../../assets/img/iconExercicesF.svg";
import iconExercicesActive from "../../../assets/img/iconExercicesActive.svg";
import iconEvaluation from "../../../assets/img/iconEvaluation.svg";
import iconEvaluationF from "../../../assets/img/iconEvaluationF.svg";
import iconEvaluationActive from "../../../assets/img/iconEvaluationActive.svg";
import iconExam from "../../../assets/img/iconExam.svg";
import iconExamF from "../../../assets/img/iconExamF.svg";

import iconExamActive from "../../../assets/img/iconExamActive.svg";
import iconMore from "../../../assets/img/iconMore.svg";
import iconStatistics from "../../../assets/img/iconStatistics.svg";
import iconStatisticsF from "../../../assets/img/iconStatisticsF.svg";
import iconBills from "../../../assets/img/iconBills.svg";
import iconBillsF from "../../../assets/img/iconBillsF.svg";
import iconBillsActive from "../../../assets/img/iconBillsActive.svg";
import iconStatisticsActive from "../../../assets/img/iconStatisticsActive.svg";
import iconSheduleActive from "../../../assets/img/iconeSheduleActive.svg";
import iconSheduleWhite from "../../../assets/img/iconeSheduleWhite.svg";
import iconShedule from "../../../assets/img/iconShedule.png";
import iconSheduleF from "../../../assets/img/iconSheduleF.png";
import iconholiday from "../../../assets/img/iconcalendarong.png"
import iconholidayF from "../../../assets/img/iconcalendarongF.png"
import iconProfessorActive from "../../../assets/img/iconProfessorActive.svg";
import iconSondageActive from "../../../assets/img/iconSondageActive.svg";
import iconStudentsActive from "../../../assets/img/iconStudentsActive.svg";
import iconClassesActive from "../../../assets/img/iconClassesActive.svg";
import iconSondageWhite from "../../../assets/img/iconSondageWhite.svg";
import iconProfessorWhite from "../../../assets/img/iconProfessorWhite.png";
import iconStudentsWhite from "../../../assets/img/menu/iconStudentsYellow.svg";
import iconClassesWhite from "../../../assets/img/menu/iconClassesYellow.svg";
import iconAbsences from "../../../assets/img/iconAbsences.svg";
import iconAbsencesF from "../../../assets/img/iconAbsencesF.svg";
import iconAbsencesActive from "../../../assets/img/iconAbsencesActive.svg";
import groupedItems from "../../../assets/img/grouped_items.png";
import iconImport from "../../../assets/img/iconImport.svg";
import iconImport2 from "../../../assets/img/iconImport2.svg";
import iconExport from "../../../assets/img/iconExport.svg";
import iconConvert from "../../../assets/img/convert.svg";
import finger from "../../../assets/img/finger.svg";
import iconExportPdf from "../../../assets/img/iconExportPdf.png";
import iconExportCsv from "../../../assets/img/iconExportCsv.png";
import iconList from "../../../assets/img/iconList.svg";
import iconList2 from "../../../assets/img/iconList2.svg";
import iconBar from "../../../assets/img/iconBar.svg";
import iconFilter from "../../../assets/img/iconFilter.svg";
import iconFilter2 from "../../../assets/img/iconFilter2.svg";
import iconOthersStudents from "../../../assets/img/iconOthersStudents.png";
import iconOthersTextbooks from "../../../assets/img/iconOthersTextbooks.png";
import iconOthersProfessors from "../../../assets/img/iconOthersProfessors.png";
import iconOthersSchedule from "../../../assets/img/iconOthersSchedule.png";
import iconUplaod from "../../../assets/img/iconUplaod.png";
import iconReplace from "../../../assets/img/replace.png";

import search from "../../../assets/img/search.svg";
import iconLevel from "../../../assets/img/icon_level.svg";
import iconClasse from "../../../assets/img/icon_classe.svg";
import itemSelected from "../../../assets/img/item_selected.svg";

import iconClose from "../../../assets/img/close-calc-icon.png";
import iconStudent from "../../../assets/img/school/iconStudent.svg";
import iconStudentBoys from "../../../assets/img/school/iconStudentBoys.svg";
import iconStudentGirls from "../../../assets/img/school/iconStudentGirls.svg";

import iconWord from "../../../assets/img/icon_word.png";
import iconExerciceAdd from "../../../assets/img/iconExerciceAdd.png";
import iconChallenge from "../../../assets/img/iconChallenge.png";
import iconChallengeF from "../../../assets/img/iconChallengeF.png";
import iconChallengeActive from "../../../assets/img/iconChallengeActive.png";
import challengeMaths from "../../../assets/img/delly-challenge_icon2.svg";
import iconArabic from "../../../assets/img/icon-arabic.svg";
import iconBook from "../../../assets/img/iconBook.svg";
import iconGroupStatistics from "../../../assets/img/iconGroupStatistics.png";
import icontxtbkPJ from "../../../assets/img/txtbk_pj.svg";
import icontxtbkother from "../../../assets/img/txtbk_other.svg";
import icontxtbkexointerac from "../../../assets/img/txtbk_interac.svg";
import iconDelivery from "../../../assets/img/delivery.svg";
import iconDeliveryActive from "../../../assets/img/deliveryActive.svg";

import group_2 from "../../../assets/img/group_2.jpg";
import zoomicon from "../../../assets/img/video-camera.png";
import zoomiconG from "../../../assets/img/video-camera_g.png";
import default_school_logo from "../../../assets/img/group_2.jpg";

import smsIconActive from "../../../assets/img/sms-icon.svg";
import smsIcon from "../../../assets/img/sms-iconActive.svg";
import loading from "../../../assets/img/loading.gif";


// menu
import iconSectionVieScolaire from "../../../assets/img/menu/iconSectionVieScolaire.svg";
import iconSectionInstallation from "../../../assets/img/menu/iconSectionInstallation.svg";
import iconSectionFincance from "../../../assets/img/menu/iconSectionFincance.svg";
import iconSectionCommunication from "../../../assets/img/menu/iconSectionCommunication.svg";
import iconSectionPedagogie from "../../../assets/img/menu/iconSectionPedagogie.svg";
import iconSectionOthers from "../../../assets/img/menu/iconSectionOthers.svg";

import iconMenuFormation from "../../../assets/img/menu/iconFormation.svg";
import iconMenuAbsence from "../../../assets/img/menu/iconAbsence.svg";
import iconMenuActivity from "../../../assets/img/menu/iconActivity.svg";
import iconMenuActivityParascolaire from "../../../assets/img/menu/iconActivityParascolaire.svg";
import iconMenuAgenda from "../../../assets/img/menu/iconAgenda.svg";
import iconMenuAssistant from "../../../assets/img/menu/iconAssistant.svg";
import iconMenuAttestation from "../../../assets/img/menu/iconAttestation.svg";
import iconMenuCalendarControl from "../../../assets/img/menu/iconCalendarControl.svg";
import iconMenuCantine from "../../../assets/img/menu/iconCantine.svg";
import iconMenuCantineMenu from "../../../assets/img/iconCantineMenu2.svg";
import iconMenuCantineMenuActive from "../../../assets/img/iconCantineMenuAcive.svg";
import iconMenuCommand from "../../../assets/img/menu/iconCommand.svg";
import iconMenuCommunication from "../../../assets/img/menu/iconCommunication.svg";
import iconMenuMaintenanceMonitorings from "../../../assets/img/menu/iconMaintenanceMonitoring.svg";
import iconMenuComplaint from "../../../assets/img/menu/iconComplaint.svg";
import iconMenuConseilClasses from "../../../assets/img/menu/iconConseilClasses.svg";
import iconMenuCoursesLive from "../../../assets/img/menu/iconCoursesLive.svg";
import iconMenuCvTheque from "../../../assets/img/menu/iconCvTheque.svg";
import iconMenuEvaluation from "../../../assets/img/menu/iconEvaluation.svg";
import iconMenuGallery from "../../../assets/img/menu/iconGallery.svg";
import iconMenuInvoice from "../../../assets/img/menu/iconInvoice.svg";
import iconMenuLibrary from "../../../assets/img/menu/iconLibrary.svg";
import iconMenuLibraryNumeric from "../../../assets/img/menu/iconLibraryNumeric.svg";
import iconMenuLibrarySchool from "../../../assets/img/menu/iconLibrarySchool.svg";
import iconMenuMessages from "../../../assets/img/menu/iconMessages.svg";
import iconMenuNote from "../../../assets/img/menu/iconNote.svg";
import iconMenuParametre from "../../../assets/img/menu/iconParametre.svg";
import iconMenuPreinscription from "../../../assets/img/menu/iconPreinscription.svg";
import iconMenuProjetEducatif from "../../../assets/img/menu/iconProjetEducatif.svg";
import iconMenuSchedule from "../../../assets/img/menu/iconSchedule.svg";
import iconMenuSms from "../../../assets/img/menu/iconSms.svg";
import iconMenuTextbook from "../../../assets/img/menu/iconTextbook.svg";
import iconMenuTransmission from "../../../assets/img/menu/iconTransmission.svg";
import iconMenuTransport from "../../../assets/img/menu/iconTransport.svg";
import iconMenuVisit from "../../../assets/img/menu/iconVisit.svg";
import iconMenuClasses from "../../../assets/img/menu/iconClasses.svg";
import iconMenuProjects from "../../../assets/img/menu/iconProjects.svg";
import iconMenuStudents from "../../../assets/img/menu/iconStudents.svg";
import iconMenuProfessors from "../../../assets/img/menu/iconProfessors.svg";
import iconMenuParents from "../../../assets/img/menu/iconParents.svg";
import iconMenuExercises from "../../../assets/img/menu/iconExercises.svg";
import iconMenuChallenge from "../../../assets/img/menu/iconChallenge.svg";
import iconMenuDashboard from "../../../assets/img/menu/iconDashboard.svg";
import iconMenuPayment from "../../../assets/img/menu/iconPayment.svg";
import iconMenuTracking from "../../../assets/img/menu/iconTracking.svg";

import iconMenuFormationActive from "../../../assets/img/menu/iconFormationActive.svg";
import iconMenuAbsenceActive from "../../../assets/img/menu/iconAbsenceActive.svg";
import iconMenuActivityActive from "../../../assets/img/menu/iconActivityActive.svg";
import iconMenuActivityParascolaireActive from "../../../assets/img/menu/iconActivityParascolaireActive.svg";
import iconMenuSondageActive from "../../../assets/img/iconSondageActive.svg";
import iconMenuAgendaActive from "../../../assets/img/menu/iconAgendaActive.svg";
import iconMenuAssistantActive from "../../../assets/img/menu/iconAssistantActive.svg";
import iconMenuAttestationActive from "../../../assets/img/menu/iconAttestationActive.svg";
import iconMenuCalendarControlActive from "../../../assets/img/menu/iconCalendarControlActive.svg";
import iconMenuCantineActive from "../../../assets/img/menu/iconCantineActive.svg";
import iconMenuCommandActive from "../../../assets/img/menu/iconCommandActive.svg";
import iconMenuCommunicationActive from "../../../assets/img/menu/iconCommunicationActive.svg";
import iconMenuMaintenanceMonitoringsActive from "../../../assets/img/menu/iconMenuMaintenanceMonitoringsActive.svg";
import iconMenuScholarActivitiesActive from "../../../assets/img/menu/scholarActivitiesActive.svg";
import iconMenuScholarActivities from "../../../assets/img/menu/scholarActivitiesWhite.svg";
import iconMenuComplaintActive from "../../../assets/img/menu/iconComplaintActive.svg";
import iconMenuConseilClassesActive from "../../../assets/img/menu/iconConseilClassesActive.svg";
import iconMenuCoursesLiveActive from "../../../assets/img/menu/iconCoursesLiveActive.svg";
import iconMenuCvThequeActive from "../../../assets/img/menu/iconCvThequeActive.svg";
import iconMenuEvaluationActive from "../../../assets/img/menu/iconEvaluationActive.svg";
import iconMenuGalleryActive from "../../../assets/img/menu/iconGalleryActive.svg";
import iconMenuInvoiceActive from "../../../assets/img/menu/iconInvoiceActive.svg";
import iconMenuLibraryActive from "../../../assets/img/menu/iconLibraryActive.svg";
import iconMenuLibraryNumericActive from "../../../assets/img/menu/iconLibraryNumericActive.svg";
import iconMenuLibrarySchoolActive from "../../../assets/img/menu/iconLibrarySchoolActive.svg";
import iconMenuMessagesActive from "../../../assets/img/menu/iconMessagesActive.svg";
import iconMenuNoteActive from "../../../assets/img/menu/iconNoteActive.svg";
import iconMenuLostObject from "../../../assets/img/menu/iconLostObject.svg";
import iconMenuLostObjectActive from "../../../assets/img/menu/iconLostObjectActive.svg";
import iconMenuSurvey from "../../../assets/img/menu/iconSurveys.svg";
import iconMenuSurveyActive from "../../../assets/img/menu/iconSurveysActive.svg";
import iconMenuParametreActive from "../../../assets/img/menu/iconParametreActive.svg";
import iconMenuPreinscriptionActive from "../../../assets/img/menu/iconPreinscriptionActive.svg";
import iconMenuProjetEducatifActive from "../../../assets/img/menu/iconProjetEducatifActive.svg";
import iconMenuScheduleActive from "../../../assets/img/menu/iconScheduleActive.svg";
import iconMenuSmsActive from "../../../assets/img/menu/iconSmsActive.svg";
import iconMenuTextbookActive from "../../../assets/img/menu/iconTextbookActive.svg";
import iconMenuTransmissionActive from "../../../assets/img/menu/iconTransmissionActive.svg";
import iconMenuTransportActive from "../../../assets/img/menu/iconTransportActive.svg";
import iconMenuVisitActive from "../../../assets/img/menu/iconVisitActive.svg";
import iconMenuClassesActive from "../../../assets/img/menu/iconClassesActive.svg";
import iconMenuProjectsActive from "../../../assets/img/menu/iconProjectsActive.svg";
import iconProjectDefault from "../../../assets/img/menu/default_project_icon.svg";
import iconMenuStudentsActive from "../../../assets/img/menu/iconStudentsActive.svg";
import iconMenuStudentsBoys from "../../../assets/img/menu/iconStudentsBoys.svg";
import iconMenuStudentsGirls from "../../../assets/img/menu/iconStudentsGirls.svg";
import iconMenuProfessorsActive from "../../../assets/img/menu/iconProfessorsActive.svg";
import iconMenuParentsActive from "../../../assets/img/menu/iconParentsActive.svg";
import iconMenuExercisesActive from "../../../assets/img/menu/iconExercisesActive.svg";
import iconMenuChallengeActive from "../../../assets/img/menu/iconChallengeActive.svg";
import iconMenuDashboardActive from "../../../assets/img/menu/iconDashboardActive.svg";
import iconMenuPaymentActive from "../../../assets/img/menu/iconPaymentActive.svg";
import iconMenuTrackingActive from "../../../assets/img/menu/iconTrackingActive.svg";

import backWave1 from "../../../assets/img/school/backWave1.svg";
import backWave2 from "../../../assets/img/school/backWave2.svg";
import backWave3 from "../../../assets/img/school/backWave3.svg";
import backWave4 from "../../../assets/img/school/backWave4.svg";


//menu
export const images_menu = {
  backWave1,
  backWave2,
  backWave3,
  backWave4,
  iconSectionVieScolaire,
  iconSectionInstallation,
  iconSectionFincance,
  iconSectionCommunication,
  iconSectionPedagogie,
  iconSectionOthers,
  iconMenuDashboard,
  iconMenuChallenge,
  iconMenuClasses,
  iconMenuProjects,
  iconMenuStudents,
  iconMenuProfessors,
  iconMenuPayment,
  iconMenuFormation,
  iconMenuAbsence,
  iconMenuActivity,
  iconMenuActivityParascolaire,
  iconMenuAgenda,
  iconMenuAssistant,
  iconMenuAttestation,
  iconMenuCalendarControl,
  iconMenuCantine,
  iconMenuCantineMenu,
  iconMenuCantineMenuActive,
  iconMenuCommand,
  iconMenuCommunication,
  iconMenuMaintenanceMonitorings,
  iconMenuMaintenanceMonitoringsActive,
  iconMenuScholarActivities,
  iconMenuScholarActivitiesActive,
  iconMenuComplaint,
  iconMenuConseilClasses,
  iconMenuCoursesLive,
  iconMenuCvTheque,
  iconMenuEvaluation,
  iconMenuGallery,
  iconMenuInvoice,
  iconMenuLibrary,
  iconMenuLibraryNumeric,
  iconMenuLibrarySchool,
  iconMenuMessages,
  iconMenuNote,
  iconMenuLostObject,
  iconMenuSurvey,
  iconMenuParametre,
  iconMenuPreinscription,
  iconMenuProjetEducatif,
  iconMenuSchedule,
  iconMenuSms,
  iconMenuTextbook,
  iconMenuTransmission,
  iconMenuTransport,
  iconMenuVisit,
  iconMenuParents,
  iconMenuExercises,
  iconMenuTracking,

  iconMenuDashboardActive,
  iconMenuChallengeActive,
  iconMenuClassesActive,
  iconMenuProjectsActive,
  iconProjectDefault,
  iconMenuStudentsActive,
  iconMenuProfessorsActive,
  iconMenuPaymentActive,
  iconMenuFormationActive,
  iconMenuAbsenceActive,
  iconMenuActivityActive,
  iconMenuActivityParascolaireActive,
  iconMenuAgendaActive,
  iconMenuAssistantActive,
  iconMenuAttestationActive,
  iconMenuCalendarControlActive,
  iconMenuCantineActive,
  iconMenuCommandActive,
  iconMenuCommunicationActive,
  iconMenuComplaintActive,
  iconMenuConseilClassesActive,
  iconMenuCoursesLiveActive,
  iconMenuCvThequeActive,
  iconMenuEvaluationActive,
  iconMenuGalleryActive,
  iconMenuInvoiceActive,
  iconMenuLibraryActive,
  iconMenuLibraryNumericActive,
  iconMenuLibrarySchoolActive,
  iconMenuMessagesActive,
  iconMenuNoteActive,
  iconMenuLostObjectActive,
  iconMenuSurveyActive,
  iconMenuParametreActive,
  iconMenuPreinscriptionActive,
  iconMenuProjetEducatifActive,
  iconMenuScheduleActive,
  iconMenuSmsActive,
  iconMenuTextbookActive,
  iconMenuTransmissionActive,
  iconMenuTransportActive,
  iconMenuVisitActive,
  iconMenuParentsActive,
  iconMenuExercisesActive,
  iconMenuTrackingActive
}

export const images = {
  logo,
  logo_laraki,
  smsIconActive,
  smsIcon,
  group_2,
  zoomicon,
  zoomiconG,
  iconDelivery,
  iconDeliveryActive,
  iconClose,
  iconActivities,
  iconExo,
  iconActivitiesActive,
  icontxtbkPJ,
  icontxtbkother,
  icontxtbkexointerac,
  iconholiday,
  iconholidayF,
  iconSettings,
  iconSettingsF,
  iconTransport,
  iconTransportF,
  iconTransportActive,
  iconCantine,
  iconCantineF,
  iconCantineActive,
  iconPurchaseOrder,
  iconPurchaseOrderF,
  iconPurchaseOrderActive,
  iconSettingsActive,
  iconProfessor,
  iconProfessorF,
  iconSondage,
  iconSondageF,
  iconMenuSondageActive,
  iconStudents,
  iconStudentsF,
  iconClasses,
  iconClassesF,
  iconGallery,
  iconGalleryActive,
  iconTransmission,
  iconTransmissionActive,
  iconLibrary,
  iconLibraryF,
  iconCourses,
  iconCoursesF,
  iconExercices,
  iconExercicesF,
  iconShedule,
  iconSheduleF,
  iconEvaluation,
  iconEvaluationF,
  iconExam,
  iconExamF,
  iconExamActive,
  iconMore,
  iconStatistics,
  iconStatisticsF,
  iconBillsF,
  iconBills,
  iconBillsActive,
  iconStatisticsActive,
  iconProfessorActive,
  iconSondageActive,
  iconStudentsActive,
  iconClassesActive,
  iconProfessorWhite,
  iconSondageWhite,
  iconStudentsWhite,
  iconLibraryActive,
  iconCoursesActive,
  iconExercicesActive,
  iconEvaluationActive,
  iconSheduleActive,
  avatar,
  iconChallenge,
  iconChallengeF,
  iconChallengeActive,
  iconImport,
  iconImport2,
  iconExport,
  groupedItems,
  iconConvert,
  finger,
  iconExportPdf,
  iconExportCsv,
  iconList,
  iconList2,
  iconBar,
  iconFilter,
  iconFilter2,
  iconClassesWhite,
  iconSheduleWhite,
  iconOthersStudents,
  iconOthersTextbooks,
  iconOthersProfessors,
  iconOthersSchedule,
  iconUplaod,
  search,
  iconLevel,
  default_school_logo,
  iconClasse,
  itemSelected,
  iconStudent,
  iconMenuStudentsBoys,
  iconMenuStudentsGirls,
  iconStudentBoys,
  iconStudentGirls,
  iconWord,
  iconExerciceAdd,
  challengeMaths,
  iconBook,
  iconArabic,
  iconGroupStatistics,
  iconAbsencesActive,
  iconAbsences,
  loading,
  iconAbsencesF
};
export const replaceImage = {
  iconReplace
};