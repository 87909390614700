import React, {Component} from "react"
import { ViewPager, Frame, Track, View } from 'react-view-pager'
import styled from "styled-components"

import {images} from "../_resources"
import {colors} from "../../../../assets/variables/colors";
import { withTranslation, Trans } from 'react-i18next';

class NotesKoolskools extends Component {
  constructor(props){
    super(props);
  }
    state = {
        currentView: 1,
        activeItemIndex: 0,
        array: [
            {id:0,img: images.childSlider2},
            {id:1,img: images.childSlider1},
            {id:2,img: images.childSlider3},
        ]
    }

    // TODO: TRANSLATIONS
    render() {
        return(
            <NotesKoolskoolsStyle className="koolskools-notes">
                <div className="container container-koolskools">
                    {/* <h3><span>GRÂCE A KOOLSHOOLS MES NOTES<br /> SE SONT ENVOLEES</span></h3> */}
                    <h3><span>{ this.props.t('THANKS TO KOOLSHOOLS MY NOTES')}<br /> { this.props.t('HAVE RAISED')}</span></h3>

                    <ViewPager tag="main">
                        <Frame className="frame">
                            <Track
                                ref={c => this.track = c}
                                viewsToShow={3}
                                //infinite
                                className="track"
                                onViewChange={(currentIndicies) => {
                                    this.setState({ currentView: currentIndicies[1] })
                                }}
                            >
                                {this.state.array.map(data => (
                                    <View className={this.state.currentView === data.id ? "view current" : "view"} key={data.id}>
                                    <img src={data.img} className="img-fluid" /></View>
                                ))}

                            </Track>

                            <div className="pager-controls">
                                <a
                                    className="pager-control pager-control--prev"
                                    onClick={() => this.track.prev()}
                                >
                                   { this.props.t('Prev') } 
                                </a>
                                <a
                                    className="pager-control pager-control--next"
                                    onClick={() => this.track.next()}
                                >
                                   { this.props.t('Next') } 
                                </a>
                            </div>

                        </Frame>
                        <div className="pager-controls">
                            {this.state.array.map(data => (
                                <span key={data.id} className={data.id === this.state.currentView ? "current-nav" : ""}></span>
                            ))}
                        </div>
                    </ViewPager>
                </div>
            </NotesKoolskoolsStyle>
        )
    }
}

const NotesKoolskoolsStyle = styled.section`
   background: #f7f7f7;
    padding: 70px 200px 150px;
    background: url("../../assets/img/graphics-copy.svg") no-repeat scroll center;
    .container-koolskools{
      max-width: 940px;
      text-align: center;
    }
    h3{
      opacity: 0.9;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.9px;
      text-align: center;
      color: $secondary;
      margin-bottom: 70px;
      span{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          right: -200px;
          top: -8px;
          width: 130px;
          height: 157px;

          background: url("../../assets/img/home_rocket1.svg")  no-repeat scroll center;
        }
      }
    }
    .alice-carousel{
      .alice-carousel__stage-item{
        img{
          border: 20px solid $white;
          box-shadow: 1px 1px 1px 1px rgba(0,0,0,.5);
        }
      }
    }
    .current{
      transform: scale(1.5);
      transition: .3s;
      z-index: 999999;

      position: relative;
    }
    main{
      position: relative;
      .pager-controls{
        .pager-control--prev,.pager-control--next{
          display: none;
        }
      }
      &:before{
        content: "";
        background: url("../../assets/img/home_rocket2.svg") no-repeat scroll;
        width: 133px;
        height: 130px;
        position: absolute;
        left: -70px;
        top: 50%;
      }
    }
    .frame{
      margin: 10px;
      position:relative;

      .track{
        padding: 75px 3px 75px;
        img{
          -webkit-filter: drop-shadow(0 -1px 4px rgba(0,0,0,0.15));
          -moz-filter: drop-shadow(0 -1px 4px rgba(0,0,0,0.15));
          -ms-filter: drop-shadow(0 -1px 4px rgba(0,0,0,0.15));
          -o-filter: drop-shadow(0 -1px 4px rgba(0,0,0,0.15));
          filter: drop-shadow(0 -1px 4px rgba(0,0,0,0.15));
          border: 20px solid ${colors.white};
        }
      }
    }
    .pager-controls{
      span{
        width: 9px;
        height: 9px;
        background: rgba(216,216,216,.4);
        border-radius: 50%;
        display: inline-block;
        margin: 0 3.5px;
        &.current-nav{
          background: rgba(216,216,216,.9);
        }
      }
    }
    
    @media screen and (max-width: 1199px){
      padding: 35px 0 140px;
      .container-koolskools {
        max-width: 780px;
        h3{
          margin-bottom: 43px;
        }
      }
      main{
        &:before{
          top: auto;
          bottom: 30px;
          left: -15px;
        }
      }
    }
    
    @media screen and (max-width: 767px){
        padding: 35px 0 110px;
       main{
        background: #f8f6f6;
        &:before{
          top: auto;
          bottom: -10px;
          left: 50px;
          width: 100px;
          height: 130px;

        }
        >.pager-controls{
          position: absolute;
          left: 0;
          right: 0;
          bottom: 25px;
        }
        .view{
          transform: scale(2);
          -webkit-transform: scale(2);
          &.current{
            -webkit-transform: scale(2.5);
            transform: scale(2.5);
          }
        }

        .pager-controls{
          .pager-control--prev,.pager-control--next{
            display: block;
            position: absolute;
            top: 50%;
            background: #3eb6ad;
            padding: 0;
            font-size: 0;
            width: 40px;
            height: 40px;
            margin-top: -25px;
            border-radius: 50%;
            border: 5px solid ${colors.white};
            line-height: 45px;
            &:before{
              font: normal normal normal 14px/1 FontAwesome;
              color: ${colors.white};
              line-height: 14px;
              font-size: 24px
            }
          }
          .pager-control--prev{
            left: 17px;
            &:before{
              content: "\\f104";
            }
          }

          .pager-control--next{
            right: 17px;
            &:before{
              content: "\\f105";
            }
          }
        }
      }
      .container-koolskools{
        max-width: 100%;
        padding: 0;
        h3{
          margin-bottom: 67px;
          position: relative;
          Z-INDEX: 99;
          font-size: 12px;
          line-height: 16px;
          span{
            &:before{
              right: -63px;
              top: 25px;
              width: 117px;
              height: 102px;
              z-index: 9;
              -webkit-transform: rotate(50deg);
              transform: rotate(56deg);
            }

          }
        }
      }
      .frame{
        margin-left: 0;
        margin-right: 0;
        overflow: inherit!important;
        .track{
          padding: 130px 0 150px;
          img{
            border: 7px solid ${colors.white};
          }
        }
      }
    }
`
export default withTranslation('translations')(NotesKoolskools);
