import React, { Component } from "react";
import { Fade } from "react-slideshow-image";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { withTranslation, Trans } from 'react-i18next';
import { colors } from "../../../assets/variables/colors";
import { images } from "../_resources";

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: false,
  register: "false"
};

class Carousel_ extends Component {
  // TODO: TRANSLATIONS
  constructor(props) {
    super(props);
  }
  state = {
    RefMobile: 0,
    register: false
  };

  onOpenModalRegister = () => {
    this.setState({ register: true });
  };

  onCloseModalRegister = () => {
    this.setState({ register: false });
  };

  handleOpenSigninMobile = () => {
    this.setState({
      popupSignin: {
        popup: true,
        signin: true
      }
    });
  };

  handleOpenRegisterMobile = () => {
    this.setState({
      popupSignin: {
        popup: true,
        signin: false
      }
    });
  };

  handleCloseRegisterMobile = () => {
    this.setState({
      popupSignin: {
        popup: false,
        signin: true
      }
    });
  };

  render() {
    return (
      <>
        <Carousel>
          <div className="carousel">
            <div className="carouselItem">
              <Fade {...fadeProperties}>
                <div className="each-fade">
                  <div className="image-container">
                    {/* <img src={images.slider} /> */}
                    <img src={images.schoolSlider1} />
                  </div>
                  {/* <h2>{ this.props.t('I WIN GIFTS')}</h2> */}
                  {/* <h2>JE GAGNE DES CADEAUX</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                  <img src={images.schoolSlider2} />

                    {/* <img src={images.slider2} /> */}
                  </div>
                  {/* <h2>KOOLSKOOLS C’EST GENIAL</h2> */}
                  {/* <h2>{this.props.t('KOOLSKOOLS IT IS GREAT')}</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                  <img src={images.schoolSlider3} />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                  <img src={images.schoolSlider4} />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                  <img src={images.schoolSlider5} />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                  <img src={images.schoolSlider6} />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="slider-bottom">
            {/* <a href="">ESSAI GRATUIT</a> */}
            <a onClick={() => this.props.handleOpenModalConnexion()}>{ this.props.t('School Access')}</a>
            <a onClick={() => this.props.handleOpenModalRegister()}>{ this.props.t('I Register')}</a>
          </div>
        </Carousel>
      </>
    );
  }
}

const Carousel = styled.div`
  position: relative;
  margin-top: 8px;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: -4px 13px 18px 0 rgba(0, 0, 0, 0.15);
  img {
    border-radius: 10px;
    width: 100%;
  }
  .carousel {
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    .image-container {
      position: relative;
      z-index: 999;
      &:before {
        content: "";
         background-image: linear-gradient(180deg, transparent, #000); 
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 9;
      }
    }
    h2 {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      -webkit-text-stroke: 0 ${colors.orange};
      font-size: 27px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: normal;
      text-align: center;
      color: ${colors.white};
      margin: 0;
      z-index: 999;
    }
    .banner-anim {
      position: relative;
      min-height: 435px;
      &:before {
        content: "";
        background-image: linear-gradient(180deg, transparent, #000);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 999;
      }
    }
    .banner-anim-elem {
      min-height: 430px;
      .bg {
        min-height: 430px;
      }
    }
  }

  .slider-bottom {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
    a {
      cursor: pointer;
      font-family: Montserrat, sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      color: hsla(0, 0%, 100%, 0.9);
      background: #ffa827;
      padding: 13px 28px;
      border: 5px solid ${colors.white};
      border-radius: 40px;
      margin: 0 23px;
      display: inline-block;
      width: 250px;
      img {
        max-width: 22px;
        max-height: 22px;
        vertical-align: bottom;
        margin-right: 8px;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    margin: 5px 10px;
    box-shadow: -4px 22px 61px 0 rgba(0, 0, 0, 0.15);
    .carousel {
      h3 {
        font-size: 25px;
      }
      .banner-anim {
        min-height: 435px;
      }
      .banner-anim-elem {
        min-height: 430px;
        .bg {
          min-height: 430px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0 0 170px;
    .carousel {
      border-radius: 5px;
      h3 {
        font-size: 17px;
        line-height: 23px;
        bottom: 10px;
      }
      .banner-anim {
        min-height: 200px;

        .banner-anim-elem {
          min-height: 200px;
          .bg {
            min-height: 200px;
          }
        }
      }
    }
    .slider-bottom {
      bottom: -170px;
      left: 25px;
      right: 25px;
      a {
        margin: 0 0 22px;
        width: 100%;
        display: block;
        font-size: 12.5px;
        color: rgba(255, 255, 255, 0.9);
        padding: 14px 13px;
        border: 4px solid #fff;
        border-radius: 82px;
      }
    }
  }
`;
export default withTranslation('translations')(Carousel_);
