import React, { Component, Children } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { myChildren, myChildrenInvitations, events_api_list, child_absences_api_count, child_periods } from "../services/api_parent";
import { school_application_api_list, prof_classes_api, get_instanceID_info,log_js_error } from "services/cms/api";
import { check_username_connected } from "../services/api";
import {messaging} from 'init_fcm'

import { get, set, clear } from "services/storage";

class LayoutParentSystem extends Component {
  constructor(props) {
    super(props);
    this.user = get("session_user").profile;
    //var selected_child = get("selected_child");
    this.state = {
      extra_props: { child: false },
      children: [],
      children_tasks_by_classes: [],
      children_seances: [],
      levels: [],
      all_invitations: [],
      invitations_by_children: [],
      invited_children: [],
      events: [],
      candidacies: [],
      scholarYears: [],
      invitation_to_accept: null,
      children_to_accept: [],
      schools:[],
      conversations:[],
      groups:[],
      boolean: false,
      webso_pa:
        this.user &&
        new WebSocket(
          `${process.env.REACT_APP_URL_MESSAGING}/ws/users/?username=${this.user.username}`
        ),
      handleUserChange: image_url =>
        this.handleUserChange(image_url),
      handleRemoveInvitation: invitation =>
        this.handleRemoveInvitation(invitation),
      handleRemoveChildToAccept: child_to_accept =>
        this.handleRemoveChildToAccept(child_to_accept),
      handleAcceptChildToAccept: (child, child_to_accept) =>
        this.handleAcceptChildToAccept(child, child_to_accept),
      handleAcceptInvitation: invitation =>
        this.handleAcceptInvitation(invitation),
      handleChangeCurrentChild: child => this.handleChangeCurrentChild(child),
      handleChangeChildrenTasksCount: res => this.handleChangeChildrenTasksCount(res),
      handleChangeSaveGettedPosts: (posts, current_posts_page) => this.handleChangeSaveGettedPosts(posts, current_posts_page),
      handleChildRemoved: username => this.handleChildRemoved(username),
      handleChildRemoved: username => this.handleChildRemoved(username),
      handleChildAdded: (child, is_update) =>
        this.handleChildAdded(child, is_update)
    };
    
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.props.history.push('/ErrorPage');
    // You can also log the error to an error reporting service
    var data={
      error_stack: error.stack
    }
    log_js_error(data);
  }

  componentWillMount(){

    // check_username_connected().then(res => {  
      
    // }).catch(err=> {
    //   var lev = get("level_groups");
    //   var current_language = get("current_language");
    //   clear();
    //   set("level_groups", lev);
    //   set("current_language", current_language);
    //   this.props.history.push("/home/parent");
    // });

    this.state.webso_pa.onopen = (e) => {
      this.state.webso_pa.send(
        JSON.stringify({
          action: 'show_conversations',
          username: this.user.username
        })
      )
      if(this.state.schools.length > 0){
        this.state.schools.forEach(school => {
          if(!school.conversation && !school.disable_messaging_parents && school.enable_chat){
            this.state.webso_pa.send(
              JSON.stringify({
                  action: 'new_conversation',
                  username: this.user.username,
                  friend: {username: school.admin_school},
                  school: school.id
              })
            );
          }
        });
      }
    };

    this.state.webso_pa.onmessage = (e) => {
      var user_info = JSON.parse(e.data),
        {schools, conversations, groups} = this.state;
      if(user_info.messages){
          groups.concat(conversations).forEach(group=> {
              if(group.group_id == user_info.group_id){
                  group.messages = user_info.messages;
                  // group.not_seen = user_info.not_seen;
                  if(parseInt(group.school_id)){
                    schools.map(school => {
                      if(school.disable_messaging_parents)
                        return school;
                      if(!school.conversation && school.id == parseInt(group.school_id)){
                        var conv = group;
                        if(this.user.username.toUpperCase() == conv.users[0].username.toUpperCase())
                          conv.friend = conv.users[1];
                        else
                          conv.friend = conv.users[0];
                        school.conversation = conv;
                      } 
                      return school;
                    });
                  }
              }
          });

          this.setState({
              conversations: conversations,
              groups: groups,
              schools: schools
          })
      }
      else if(user_info.message){
        groups.map(group => {
          if(group.group_id == user_info.group.group_id){
            
            group.messages.push(user_info.message);
          }
          return group
        });

        conversations.map(group => {
          if(group.group_id == user_info.group.group_id)
            group.messages.push(user_info.message);
          return group
        });

        schools.map(school => {
          if(school.disable_messaging_parents)
            return school;
          if(!school.conversation && school.id == parseInt(user_info.group.school_id)){
            var conv = user_info.group;
            if(this.user.username.toUpperCase() == conv.users[0].username.toUpperCase())
              conv.friend = conv.users[1];
            else
              conv.friend = conv.users[0];
            school.conversation = conv;
          } 
          return school;
        });

        this.setState({
          schools:schools,
          groups:groups,
          conversations:conversations
        })
      }
      else if(user_info.group){
        if(groups.map(group=> group.group_id).indexOf(user_info.group.group_id) >= 0){
          groups.map(group => {
            if(group.group_id == user_info.group.group_id)
              return user_info.group;
            return group
          });
        }
        else
          groups.push(user_info.group)
        
        this.setState({
          groups:groups
        })
      }
      else if(user_info.new_conversation){
        var conv = user_info.new_conversation;
        if(conversations.filter(c => c.group_id == conv.group_id).length == 0){
          if(this.user.username.toUpperCase() == conv.users[0].username.toUpperCase())
            conv.friend = conv.users[1];
          else
            conv.friend = conv.users[0];

          schools.map(school => {
            if(!school.conversation && !school.disable_messaging_parents){
              school.conversation = false;
              if(parseInt(conv.school_id) == school.id){
                school.conversation = conv;
              }
            }
            return school;
          })
          
          conversations.push(conv);
          this.setState({
            conversations: conversations,
            schools:schools
          })
        }
      }
      else if(user_info.classe){
      }
      else if(user_info.group_id){
      }
      else if(user_info.username){
          // friend status logged changed
      }
      else if(user_info.error){
                
      }
      else if(user_info.stop_traitement){
        var conv = user_info.conversation;
        if(this.user.username.toUpperCase() == conv.users[0].username.toUpperCase())
          conv.friend = conv.users[1];
        else
          conv.friend = conv.users[0];

        schools = schools.map(school => {
          if(!school.disable_messaging_parents && school.conversation && school.conversation.group_id == conv.group_id)
            school.conversation = conv;
          return school;
        })
        
        this.setState({
          conversations: conversations.map(conversation => {
            if(conversation.group_id == conv.group_id)
              return conv;
            return conversation;
          }),
          schools:schools
        })
      }
      else if(user_info.conversations && user_info.groups){
        var conversations = user_info.conversations;
        conversations = conversations.map(conversation => {
          if(this.user.username.toUpperCase() == conversation.users[0].username.toUpperCase())
              conversation.friend = conversation.users[1];
          else
              conversation.friend = conversation.users[0];
          
          return conversation;
        });

        // user_info.groups.forEach(group => {
        //   if(group.school_id){
        //     var exist = false;
        //     for(let idx in conversations){
        //       if(conversations[idx].school_id == group.school_id){
        //         exist = true;
        //         conversations[idx].messages = conversations[idx].messages.concat(group.messages);
        //         conversations[idx].messages.sort((a,b)=> {
        //           return Date.parse(a.date + " " + a.time) - Date.parse(b.date + " " + b.time);
        //         })
        //       }
        //     }
        //     if(!exist){
        //       conversations.push({
        //         messages:group.messages,
        //         friend:group.creator,
        //         school_id:group.school_id
        //       });

        //       this.state.webso_pa.send(
        //         JSON.stringify({
        //             action: 'new_conversation',
        //             username: this.user.username,
        //             friend: group.creator,
        //             school: group.school_id
        //         })
        //       );
        //     }
        //   }
        // });
        [...conversations, ...user_info.groups].sort((a,b) => Date.parse(b.last_time) - Date.parse(a.last_time)).forEach(group => {
            this.state.webso_pa.send(
                JSON.stringify({
                    action: 'show_messages',
                    username: this.user.username,
                    group_id: group.group_id
                })
            );
        })
        this.setState({
          conversations: conversations,
          groups: user_info.groups
        });
        // this.setState({
        //   schools:schools
        // });
      }
    }
  }

  componentWillUnmount(){
    this.state.webso_pa.close()
  }

  componentWillReceiveProps(props) {
    var extra_props = this.state.extra_props;
    if(!extra_props.child && this.state.children.length > 0){
      extra_props.child = this.state.children[0];
      this.setState({ extra_props: extra_props });
    }

  }

  handleUserChange = image_url => {
    if(image_url){
      var user = get("session_user");
      user.image = image_url;
      set("session_user", user);
    }
    this.forceUpdate();
  };

  handleRemoveInvitation = invitation => {
    var invitations = this.state.all_invitations;
    invitations.splice(invitations.indexOf(invitation), 1);
    this.setState({ all_invitations: invitations });
  };
  handleRemoveChildToAccept = child_to_accept => {
    var children_to_accept = this.state.children_to_accept;
    children_to_accept.splice(children_to_accept.indexOf(child_to_accept), 1);
    this.setState({ children_to_accept: children_to_accept });
  };
  handleAcceptChildToAccept = (child, child_to_accept) => {
    var user = get("session_user");
    var {children_to_accept, children} = this.state;
    children_to_accept.splice(children_to_accept.indexOf(child_to_accept), 1);
    children = [child].concat(children);
    if (children.length === 1) {
      user.has_children = true;
      set("session_user", user);
    }
    this.setState({ children_to_accept: children_to_accept, children: children });
  };
  handleChildAdded = (child, is_update, invitation) => {
    var user = get("session_user");
    var extra_props = this.state.extra_props;
    var invitations = this.state.all_invitations;
    if (invitation) {
      invitations.splice(invitations.indexOf(invitation), 1);
    }
    if (is_update) {
      var new_children = this.state.children;
      var continue_ = true;
      new_children.map((child_, idx) => {
        if (continue_ && child_.id == child.id) {
          new_children[idx] = child;
          set("selected_child", child);
          extra_props.child = child;
          continue_ = false;
        }
      });
    } else {
      var new_children = [child].concat(this.state.children);
    }
    if (new_children.length === 1) {
      user.has_children = true;
      set("session_user", user);
    }
    this.setState({
      extra_props: extra_props,
      children: new_children,
      all_invitations: invitations
    });
  };
  handleChildRemoved = username => {
    var user = get("session_user");
    var children = this.state.children;
    var id_to_remove = null;
    var continue_ = true;
    children.map((child, idx) => {
      if (continue_ && child.username == username) {
        id_to_remove = idx;
        continue_ = false;
      }
    });
    children.splice(id_to_remove, 1);
    if (children.length === 0) {
      user.has_children = false;
      set("session_user", user);
    }
    this.setState({ children: children });
  };
  handleAcceptInvitation = invitation => {
    this.setState({ invitation_to_accept: invitation });
  };
  handleChangeCurrentChild = child => {
    var extra_props = this.state.extra_props;
    extra_props.child = child;
    set("selected_child", child);
    this.setState({ extra_props: extra_props });
  };
  handleChangeChildrenTasksCount = res => {
    var extra_props = this.state.extra_props;
    extra_props.children_tasks_count = res.children_tasks_count;
    extra_props.all_children_tasks_count = res.count;
    this.setState({ extra_props: extra_props });
  };
  handleChangeSaveGettedPosts = (posts, current_posts_page) => {
    var extra_props = this.state.extra_props;
    extra_props.getted_posts = posts;
    extra_props.current_posts_page = current_posts_page;
    this.setState({ extra_props: extra_props });
  };

  componentDidMount() {
    if(messaging){
      messaging
      .requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        set('device_token', token);
        this.refresh_children(token)
      })
      .catch(err => {
        this.refresh_children(0)
      })
    }
    else this.refresh_children(0)
  }

  refresh_children = token => {
    const user = get("session_user");
    var data = {
      device_token: token
    };
    myChildren(user.token, data)
      .then(res => {
        var children = res.children.map(child => {
            child.level = res.levels.filter(l => !l.school_id || l.school_id == child.schools[0].id).filter(l => l.level_id == child.level_id)[0];
            return child;
          }),
          schools = [],
          extra_props = this.state.extra_props;
        extra_props.child = children.length ? children[0] : extra_props.child;
        for(var idx in children){
          schools = schools.concat(children[idx].schools.filter((c_sc) => {
            return schools.filter(sc => sc.id == c_sc.id).length == 0
          }));
        }
        get_instanceID_info().then(res => {
          if(res.rel)
            set('topics', res.rel.topics);
        });
        this.setState({
          children: children.map(ch => {
            ch.classes = [];
            ch.periods = [];
            ch.level = res.levels.filter(l => !l.school_id || l.school_id == ch.schools[0].id).filter(l => l.level_id == ch.level_id)[0];
            return ch;
          }),
          extra_props: extra_props,
          schools: schools,
          // children_tasks_by_classes: response.children_tasks_by_classes,
          // children_seances: response.children_seances,
          levels: res.levels
        },() => {
          this.connectToMessaging()
          this.state.children.forEach(child => {
            var class_data = {
              username: child.username,
              device_token: token,
              action: 'get_child_classes'
            }
            prof_classes_api(class_data).then(res => {
              this.setState({
                children: this.state.children.map(ch => {
                  if(ch.username == res.username)
                    ch.classes = res.classes;
                  return ch;
                })
              })
            })

            child_periods(class_data).then(res => {
              this.setState({
                children: this.state.children.map(ch => {
                  if(ch.username == res.username)
                    ch.periods = res.periods;
                  return ch;
                })
              })
            })
          })
        });
        myChildrenInvitations(user.token, data)
          .then(response => {
            this.setState({
              all_invitations: response.invitations,
              invitations_by_children: response.invitations_by_children,
              children_to_accept: response.children_to_accept || this.state.children_to_accept,
              invited_children: response.invited_children,
              // events: response.events,
              scholarYears: response.scholar_years
            });
          })
          .catch(err => console.log("error", err));
      })
      .catch(err => console.log("error", err));
    events_api_list().then(res => {
      this.setState({
        events:res.events
      })
    })

    school_application_api_list({action:"get_child_candidacies"}).then(res => 
      this.setState({
        candidacies:res.candidacies
      })
    )
  }

  connectToMessaging(){
    var {schools, conversations} = this.state;
    conversations.forEach(conv => {
      schools.map(school => {
        if(school.disable_messaging_parents)
          return school;
          
        if(!school.conversation){
          school.conversation = false;
          if(parseInt(conv.school_id) == school.id){
            school.conversation = conv;
          }
        }
        else if(this.state.webso_pa.readyState === WebSocket.OPEN){
          this.state.webso_pa.send(
            JSON.stringify({
                action: 'new_conversation',
                username: this.user.username,
                friend: {username: school.admin_school},
                school: school.id
            })
          );
        }
        return school;
      })
    });
    // if(this.state.webso_pa.readyState === WebSocket.OPEN){
    //   this.state.schools.forEach(school => {
    //     if(!school.conversation){
    //       this.state.webso_pa.send(
    //         JSON.stringify({
    //             action: 'new_conversation',
    //             username: this.user.username,
    //             friend: {username: school.admin_school},
    //             school: school.id
    //         })
    //       );
    //     }
    //   });
    // }

    this.setState({
      schools:schools
    })
    
  }

  render() {
    const { Layout, Component } = this.props;
    return (
      <Layout>
        <Component
          {...this.props}
          extra_props={this.state.extra_props}
          global_data={this.state}
          handleSeenEvent={(event) => this.setState({
            events:this.state.events.map(evt => {
              if(evt.id == event.id)
                evt.seen = true;
              return evt;
            })
          })}
        />
      </Layout>
    );
  }
}

export default withRouter(LayoutParentSystem);
