import React, {Component} from "react"
import TextField from "@material-ui/core/TextField";
import styled from "styled-components"
import IntlTelInput from 'react-intl-tel-input';

const custom_tel_input_label_ref = React.createRef();
export default (props) => (
    <Text color={global.is_koolskools?'#55b9b1':global.colors[1]} className={props.className || ""} style={{position: "relative"}}>
        {props.is_phone_number ?
        <>
            <label onClick={evt => {
                if(props.phone_number) return;
                evt.target.classList.toggle("focused");

            }} ref={custom_tel_input_label_ref} className={"custom_tel_input_label " + (props.phone_number ? "focused" : "")}>{props.label}</label>
            <IntlTelInput inputClassName={""}
                preferredCountries={['ma']} 
                name="phone_number"
                defaultValue={props.phone_number || ""}
                onPhoneNumberChange={props.onChange}
                onPhoneNumberFocus={evt => {
                    if(custom_tel_input_label_ref.current){
                        if(custom_tel_input_label_ref.current.classList.contains("focused")){

                        }
                        else{
                            custom_tel_input_label_ref.current.classList.add("focused");
                        }
                    }
                }}
                onPhoneNumberBlur={evt => {
                    if(custom_tel_input_label_ref.current){
                        if(custom_tel_input_label_ref.current.classList.contains("focused") && !props.phone_number){
                            custom_tel_input_label_ref.current.classList.remove("focused");
                        }
                        else{
                        }
                    }
                }}
            />
        </>
        :
        <TextField
                error={props.error}
                id="standard-error"
                type={props.type}
                label={props.label}
                className={props.className}
                margin="normal"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
            />
        }
    </Text>
)
    

const Text = styled.div`
    .textInput > div:after, .textInput > div:before{
        border-bottom: 2px solid ${props => props.color}!important;
    }
    &.textInput{
    }
    .custom_tel_input_label{
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        z-index: 1;
        left: 52px;
        top: 16px;
        position: absolute;
        transform: translate(0, 24px) scale(1);
        transform-origin: top left;
        &.focused{
            transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
            transform: translate(0, 1.5px) scale(0.75);
            transform-origin: top left;
            top: 20;
            left: 0;
            position: absolute;
        }
    }
    .intl-tel-input{
        margin-top: 38px;
        position: relative;
        width: 100%;
        border-bottom: 2px solid #ff5a00 !important;
        padding-bottom: 2px;
        >input{
            border: 0;
            width: 100%;
        }
    }
`