import React, { Component } from "react";
import Modal from "react-responsive-modal";

import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation, Trans } from 'react-i18next';

import Radio from "@material-ui/core/Radio";
import { RadioGroup } from '@material-ui/core';
import FormLabel from "@material-ui/core/FormLabel";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { set, get } from "services/storage";


import ModalConnexion from "../../professor/home/components/ModalConnexion";

import countryList from 'react-select-country-list'
import { RegisterSchool } from "services/actions";
import ModalCompteSuccess from "./ModalCompteSuccess";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class ModalSchoolRegister extends Component {
  constructor(props){
    super(props);
    this.state = {
      nextStep: false,
      success: false,
      selectedValue: "a",
      captcha: {
        min: Math.floor(Math.random() * 10),
        max: Math.floor(Math.random() * 10)
      },
      errorCaptcha: false,
      errorGlobal: "",
      server_errors: {},
      first_step_data: {},
      openconnexionModal: false,
      cycles: [],
      level_groups: get("level_groups") || []
    };
    this.countries = countryList().getData();
    this.countries = this.countries.filter(op => {
      return op.value != "EH";
    });
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
  }

  componentWillMount(){
    window.addEventListener(
      "store_level_groups",
      e => {
        this.setState({
          level_groups: get("level_groups") || []
        });
      },
      false
    );
  }

  componentWillReceiveProps(newProps){
    if(newProps.openRegisterSchool && this.props.openRegisterSchool !== newProps.openRegisterSchool){
      this.setState({server_errors: {}});
    }
  }

  /* check the validation of elements */
  getValidationSchema = () => {
    const phoneRegExp = /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/,
      usernameRegExp = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
    if(this.state.nextStep){
      return Yup.object().shape({
        first_name: Yup.string()
          .required(this.props.t("First name required")),
        last_name: Yup.string()
          .required(this.props.t("Last Name required")),
        email: Yup.string()
          .email(this.props.t("Email format incorrect"))
          .required(this.props.t("Email required")),
        phone: Yup.string()
          .matches(phoneRegExp, 'Phone format incorrect')
          //.phone("Phone format incorrect")
          .required(this.props.t("Phone required")),
        username: Yup.string()
          .required(this.props.t("Username required"))
          .matches(usernameRegExp, this.props.t('Username invalid')),   
        password: Yup.string()
          .required(this.props.t("Password required"))
          .min(5, this.props.t("Password must be greater than 5 character")),
        captcha: Yup.string()
          .required(this.props.t("Captcha required")),
        accept: Yup.string()
          .required(this.props.t("You should Accept the Terms and Conditions")),
      });
    }
    else{
      return Yup.object().shape({
        school_name: Yup.string()
          .required(this.props.t("School name required")),
        sc_phone_number: Yup.string()
          .matches(phoneRegExp, this.props.t('Phone format incorrect'))
          //.phone("Phone format incorrect")
          .required(this.props.t("School phone required")),
        country: Yup.string()
          .required(this.props.t("Country name required")),
        city: Yup.string()
          .required(this.props.t("City required")),
        address: Yup.string()
          .required(this.props.t("Address required")),
        cycles: Yup.string()
          .required(this.props.t("Cycles required")),
      });
    }
  };

  handleOpenSuccess = () => {
    this.setState({
      success: true
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false
    });
  };

  handleChangeCheck = event => {
    this.setState({ selectedValue: event.target.value });
  };

  handleCloseRegisterSchool = () => {
    this.setState({
      nextStep: false
    });
    this.props.handleCloseRegisterSchool();
  };

  handleNextStep = () => {
    this.setState({
      nextStep: true
    });
  };

  handleBackStep = () => {
    this.setState({
      nextStep: false
    });
  }

  /* after submit clicked */
  handleSubmit = (form, {resetForm}) => {
    if(this.state.nextStep){
      if (form.captcha == this.state.captcha.min + this.state.captcha.max) {
        var register = this.state.first_step_data;
        register.first_name = form.first_name;
        register.last_name = form.last_name;
        register.username = form.username;
        register.password = form.password;
        register.email = form.email;
        register.phone_number = form.phone;
        register.gender = form.gender;
        this.setState({server_errors: {}});
        return this.props
          .dispatch(RegisterSchool(register))
          .then(data => {
            if (data.payload.success) {
              this.handleCloseRegisterSchool();
              setTimeout(() => {
                this.handleOpenSuccess();
              }, 10);
            }
            else{
              var server_errors = {};
              Object.keys(data.payload).map(key => {
                server_errors[key] = data.payload[key][0].user_message;
              });
              this.setState({server_errors: server_errors});
            }
          })
          .catch(err => {
            this.setState({
              errorGlobal: err.message,
              captcha: {
                min: Math.floor(Math.random() * 10),
                max: Math.floor(Math.random() * 10)
              }
            });
          });
      } else {
        this.setState({
          errorCaptcha: true,
          captcha: {
            min: Math.floor(Math.random() * 10),
            max: Math.floor(Math.random() * 10)
          }
        });
      }
    }
    else{
      var first_step_data = {
        school_name: form.school_name,
        sc_phone_number:form.sc_phone_number,
        sc_phone_number_opt: form.sc_phone_number_opt,
        level_group: form.cycles,
        sc_country: form.country,
        sc_city: form.city,
        zip: form.postal,
        sc_adress: form.address
      }
      resetForm({first_name: "", last_name: "", username: "", password: "", email: "",  phone_number: "",  accept: "",  captcha: "" });
      this.setState({first_step_data: first_step_data, nextStep: true});
    }
  };
  
  handleChangeChecked = event => {
    var cyl = this.state.cycles; 
    const target = event.target;

    if (cyl.indexOf(parseInt(target.value)) === -1) {
      cyl.push(parseInt(target.value));
    } else {
      cyl.splice(cyl.indexOf(parseInt(target.value)), 1);
    }
    this.setState({
      cycles : cyl
    });
  };

  checkChecked = (value) => {
    return !(this.state.cycles.indexOf(value) === -1)
  };

  handleBackBtnClicked = () => {
    this.setState({openRegisterSchool: true, openconnexionModal: false});
  };
  
  handleAlreadyHaveAnAccount = () => {
    this.setState({openRegisterSchool: false, openconnexionModal: true});
  };

  render() {
    const {server_errors} = this.state;
    // TODO: TRANSLATIONS
    return (
      <>
      <Modal
        let checked = {false}
        open={this.props.openRegisterSchool || false}
        onClose={this.handleCloseRegisterSchool}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white" },
          modal: {
            dialog: "white",
            dialogConnexion: "white",
            dialogAddSchool: "white",
            dialogRegister: "white"
          }
        }}
      >
        <>
          <h3> {this.props.t('Fonctionnalité non disponible')} </h3>
          <div style={{marginTop: "15px", textAlign: "justify"}}>
            <div className="add-school-form">
              {this.props.t("Cette fonctionnalité est désactivée pour le moment!")}.
            </div>
          </div>
        </>
        {/* <div className="modalConnexion">
          <div className="modalConnexionTop">
            <h2>{ this.props.t('REGISTER') }</h2>
            <p> { this.props.t('Step') }  {!this.state.nextStep ? "1/2" : "2/2"}</p>
            <span
              className="schoolClose"
              onClick={() => this.handleCloseRegisterSchool()}
            >
              &times;
            </span>
            <div className="modalConnexionForm">
              <div className="connexionLigne">
                <div className="connexionW50">
                  {this.state.errorGlobal && (
                    <div className="textErrors">{this.state.errorGlobal}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              username: "",
              password: "",
              email: "",
              phone: "",
              gender:"male",
              captcha: "",
              accept:"",
              school_name: this.state.first_step_data.school_name || "",
              sc_phone_number: this.state.first_step_data.sc_phone_number ? this.state.first_step_data.sc_phone_number: "",
              sc_phone_number_opt: this.state.first_step_data.sc_phone_number_opt ? this.state.first_step_data.sc_phone_number_opt: "",
              cycles: this.state.first_step_data.level_group || this.state.cycles,
              city: this.state.first_step_data.sc_city || "",
              country: this.state.first_step_data.sc_country || "MA",
              postal: this.state.first_step_data.zip || "",
              address: this.state.first_step_data.sc_address || ""
            }}
            
            validationSchema={this.getValidationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values, actions);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue
            }) => {
              return (
                <Form>
                  <div className="modalConnexionForm">
                    {!this.state.nextStep ? (
                      <>
                        <div className="connexionLigne w12">
                          <label> { this.props.t('School Name ') }* </label>
                          <input type="text" 
                            className={`textInput ${errors.school_name && "textInputError"}`}
                            name="school_name"
                            value={values.school_name}
                            onChange={handleChange}
                          />
                          {errors.school_name && touched.school_name ? (
                            <div className="textErrors">{errors.school_name}</div>
                          ) : null}
                        </div>
 
                        <div className="connexionLigne">
                          <div className="connexionW50">
                            <label> { this.props.t('Phone Number') } 1*</label>
                            <IntlTelInput inputClassName={ ((errors.sc_phone_number && touched.sc_phone_number)?"textInputError":"")}
                              preferredCountries={['ma']} defaultValue={(values.sc_phone_number)?values.sc_phone_number:''}
                              onPhoneNumberChange={(status, value, countryData, number, id) => {
                                  setFieldValue('sc_phone_number', number);
                              }}/>
                              {errors.sc_phone_number && touched.sc_phone_number &&
                                <div className="textErrors">{errors.sc_phone_number}</div>
                              }
                          </div>

                          <div className="connexionW50">
                            <label>{ this.props.t('Phone Number') } 2</label>
                            <IntlTelInput inputClassName={ ((errors.sc_phone_number_opt && touched.sc_phone_number_opt)?"textInputError":"")}
                              preferredCountries={['ma']} defaultValue={(values.sc_phone_number_opt)?values.sc_phone_number_opt:''}
                              onPhoneNumberChange={(status, value, countryData, number, id) => {
                                if(status)
                                  setFieldValue('sc_phone_number_opt', number);
                                else
                                  setFieldValue('sc_phone_number_opt','');
                              }}/>
                          </div>
                        </div>

                        <div className="connexionLigne w12">
                          <label> { this.props.t('Cycles') }*</label>
                          <MuiThemeProvider theme={theme}>
                            <div className="w12">
                              {this.state.level_groups.map((cycle, idx) => {
                                return <div className="RadioLegend"
                                  key={idx}>
                                    <Radio
                                      type="radio"
                                      color="secondary"
                                      // name={cycle.id}
                                      value={cycle.id}
                                      checked={this.checkChecked(cycle.id)}
                                      onClick={this.handleChangeChecked}
                                    />
                                    <FormLabel component="legend">{cycle.name} { this.props.t('School') } </FormLabel>
                                  </div>
                                })
                              }
                            </div>
                            {errors.cycles && touched.cycles ? (
                              <div className="textErrors">{errors.cycles}</div>
                            ) : null}
                          </MuiThemeProvider>
                        </div>

                        <div className="connexionLigne">
                          <div className="connexionW33">
                            <label> { this.props.t('City') }*</label>
                            <input type="text"
                              className={`textInput ${errors.city &&
                                "textInputError"}`}
                              name="city"
                              label="City"
                              value={values.city}
                              onChange={handleChange}
                            />
                            {errors.city && touched.city ? (
                              <div className="textErrors">{errors.city}</div>
                            ) : null}
                          </div>

                          <div className="connexionW33">
                            <label>  { this.props.t('Country') } </label>
                            <div className="select">
                              <select
                                name="country" 
                                value={values.country}
                                onChange={handleChange}
                              >
                              {this.countries.map(country => {
                                return <option key={country.value} value={country.value}>{country.label}</option>
                              })}
                              </select>
                              {errors.country && touched.country &&
                                <div className="textErrors">{errors.country}</div>
                              }
                              <i className="fa fa-chevron-circle-down" aria-hidden="true" />
                            </div>
                          </div>

                          <div className="connexionW33">
                            <label> { this.props.t('Postal Code') }</label>
                            <input type="text" 
                              className={`textInput ${errors.postal &&
                                "textInputError"}`}
                              name="postal"
                              label="postal"
                              value={values.postal}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="connexionLigne w12">
                          <label> { this.props.t('Address') }* </label>
                          <textarea 
                            className={`textInput ${errors.address &&
                              "textInputError"}`}
                            name="address"
                            label="address"
                            value={values.address}
                            onChange={handleChange}
                          />
                          {errors.address && touched.address ? (
                            <div className="textErrors">{errors.address}</div>
                          ) : null}
                        </div>

                        <div className="connexionLigne w12">
                          <button type="submit" className="add" > { this.props.t('Next') } </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="connexionLigne">
                          <div className="connexionW50">
                            <label>  { this.props.t(' Admin First Name') }</label>
                            <input type="text"
                              className={`textInput ${errors.first_name &&
                                "textInputError"}`}
                              name="first_name"
                              value={values.first_name}
                              onChange={handleChange}
                            />
                            {errors.first_name && touched.first_name ? (
                              <div className="textErrors">{errors.first_name}</div>
                            ) : null}
                          </div>
                          <div className="connexionW50">
                            <label> { this.props.t('Admin Last Name') }</label>
                            <input type="text"
                              className={`textInput ${errors.last_name &&
                                "textInputError"}`}
                              name="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                            />
                            {errors.last_name && touched.last_name ? (
                              <div className="textErrors">{errors.last_name}</div>
                            ) : null}
                          </div>
                        </div>

                        <div className="connexionLigne">
                          <div className="connexionW50">
                            <label> { this.props.t('Email') } </label>
                            <input type="text"
                              className={`textInput ${(('email' in server_errors) || errors.email) &&
                                "textInputError"}`}
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            {(('email' in server_errors) || (errors.email && touched.email)) &&
                              <div className="textErrors">{server_errors.email || errors.email}</div>
                            }{" "}
                          </div>
                          <div className="connexionW50">
                            <label> { this.props.t('Admin Phone Number') } </label>
                            <IntlTelInput inputClassName={ ((('phone_number' in server_errors) || (errors.phone && (touched.phone || touched.phone_number)))?"textInputError":"")}
                              preferredCountries={['ma']} defaultValue={(values.phone)?values.phone:''}
                              onPhoneNumberChange={(status, value, countryData, number, id) => {
                                  setFieldValue('phone',number);
                              }}/>
                            {(('phone_number' in server_errors) || (errors.phone && (touched.phone || touched.phone_number))) &&
                              <div className="textErrors">{server_errors.phone_number || errors.phone}</div>
                            }
                          </div>       
                        </div>

                        <div className="connexionLigne">
                          <div className="connexionW50">
                            <label> { this.props.t('Enter Username') } </label>
                            <input type="text"
                              className={`textInput ${(('username' in server_errors) || errors.username) &&
                                "textInputError"}`}
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                            />
                            {(('username' in server_errors) || (errors.username && touched.username)) &&
                              <div className="textErrors">{server_errors.username || errors.username}</div>
                            }
                          </div>
                            <div className="connexionW50">
                              <label> { this.props.t('Password') } </label>
                              <input type="password"
                                className={`textInput ${errors.password &&
                                  "textInputError"}`}
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                              />
                              {errors.password && touched.password ? (
                                <div className="textErrors">{errors.password}</div>
                              ) : null}
                          </div>
                        </div>

                        <div className="connexionLigne connexionGender w12">
                          <label> { this.props.t('Gender') }  </label>
                          <div className="gender">
                            <div className="genderMale">
                              <input
                                type="radio"
                                name="gender"
                                value="male"
                                checked={values.gender === "male"}
                                onChange={() => setFieldValue("gender", "male")}
                              />
                              <span /> <b> { this.props.t('Male') } </b>
                            </div>

                            <div className="genderFemale">
                              <input
                                type="radio"
                                name="gender"
                                value="female"
                                checked={values.gender === "female"}
                                onChange={() => setFieldValue("gender", "female")}
                              />
                              <span /> <b>  { this.props.t('Female') } </b>
                            </div>
                          </div>
                        </div>

                        <div className="connexionLigne w12">
                          <MuiThemeProvider theme={theme}>
                            <div className="w12">
                              <div className="RadioLegend">
                                <Radio
                                  color="secondary"
                                  name="accept"
                                  value="yes"
                                  checked={values.accept ? "yes" : ""}
                                  onChange={() => {if (!values.accept){
                                      setFieldValue("accept", "yes")
                                    }else{
                                      setFieldValue("accept", "")
                                      <FormLabel component="legend">
                                      { this.props.t('Accept the Terms and Conditions') } 
                                      </FormLabel>
                                      {errors.accept && touched.accept ? (
                                      <div className="textErrors">{errors.accept}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </MuiThemeProvider>
                              </div>
      
                              <div className="connexionLigne">
                                <div className="captcha">
                                  <p className="pCaptcha">
                                    {this.state.captcha.min} + {this.state.captcha.max} ={" "}
                                  </p>
                                  <input
                                    name="captcha"
                                    type="text"
                                    value={values.captcha}
                                    onChange={handleChange}
                                    className="captchaInput"
                                  />
                                </div>
                                {this.state.errorCaptcha && (
                                  <div className="textErrors"> { this.props.t('Captcha Invalid') } </div>
                                )}
                                {errors.captcha && touched.captcha ? (
                                  <div className="textErrors">{errors.captcha}</div>
                                ) : null}
                              </div>
      
                              <div className="connexionLigne w12">
                                <a onClick={this.handleAlreadyHaveAnAccount}> { this.props.t('Already have an account?') } </a>
                                <button type="submit" className="add" > { this.props.t('REGISTER') }</button>
      
                              </div>
                            </>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>                  }
                                  }}
                                />
                                <FormLabel component="legend">
                                { this.props.t('Accept the Terms and Conditions') } 
                                </FormLabel>
                                {errors.accept && touched.accept ? (
                                <div className="textErrors">{errors.accept}</div>
                                ) : null}
                              </div>
                            </div>
                          </MuiThemeProvider>
                        </div>

                        <div className="connexionLigne">
                          <div className="captcha">
                            <p className="pCaptcha">
                              {this.state.captcha.min} + {this.state.captcha.max} ={" "}
                            </p>
                            <input
                              name="captcha"
                              type="text"
                              value={values.captcha}
                              onChange={handleChange}
                              className="captchaInput"
                            />
                          </div>
                          {this.state.errorCaptcha && (
                            <div className="textErrors"> { this.props.t('Captcha Invalid') } </div>
                          )}
                          {errors.captcha && touched.captcha ? (
                            <div className="textErrors">{errors.captcha}</div>
                          ) : null}
                        </div>

                        <div className="connexionLigne w12">
                          <a onClick={this.handleAlreadyHaveAnAccount}> { this.props.t('Already have an account?') } </a>
                          <button type="submit" className="add" > { this.props.t('REGISTER') }</button>

                        </div>
                      </>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div> */}
      </Modal>
      {this.state.openconnexionModal &&
      <ModalConnexion
          pages="school"
          openProfessor={this.state.openconnexionModal}
          showBackBtn={true}
          addedData={this.state.first_step_data}
          handleBackBtnClicked={this.handleBackBtnClicked}
          handleCloseCnxConnexion={this.handleBackBtnClicked}
        />
      }
      <ModalCompteSuccess success={this.state.success} handleCloseSuccess={this.handleCloseSuccess}/>
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)( withTranslation('translations')(ModalSchoolRegister));

