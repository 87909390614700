import React, { Component } from "react"
import Modal from 'react-responsive-modal'
import {images} from "../_resources"

export default class ModalInvalidKey extends Component {

    handleCloseSuccess = () => {
        this.props.handleCloseSuccess()
    }
    render(){
        return(
            <Modal open={this.props.success}
                onClose={this.handleCloseSuccess}
                showCloseIcon={false}
                center
                classNames={{
                    overlay: {dialogOverlay: "white"},
                    modal: {dialog: "white", dialogSuccess: "white"},
                }}>
                <div className="dialogSucce">
                <a className="closeModal" onClick={() => this.handleCloseSuccess()}><img src={images.iconClose} /></a>
                    <h5>{this.props.message || "Invalid key."}</h5>
                </div>
            </Modal>
        )
    }
}