import { createAction } from "redux-actions";
import {
  prof_classes_api,
  profStudents,
  crudStudents,
  seancesDay,
  schoolStudents,
  absencesApi,
  becomeAdmin,
  exercise_types_api,
  exercises_list_api,
  school_permissions_api_list,
  get_levels_api,
  /*get_library_api,*/
  get_sms_api,
  get_list_schools,
  add_school,
  schools_scholar_years,
  update_school_info,
  update_school_logo,
  delete_school_logo,
  sc_settings_update_api,
  sc_settings_delete_api,
  list_chapter,
  add_chapter,
  delete_chapter,
  list_competencies_api,
  update_competency,
  list_type_classroom,
  update_type_classroom,
  add_reclamationType,
  list_classroom,
  update_classroom,
  list_holidays,
  update_holidays,
  add_evaluation,
  add_appreciation,
  list_evaluation,
  list_appreciations,
  list_control,
  update_control,
  school_update_control,
  school_update_lesson,
  list_events,
  update_events,
  add_events,
  delete_events
} from "./api";
import { get, remove } from "../storage";
import { async } from "q";
import { da } from "date-fns/locale";


export const PROFCLASSES = "session/PROFCLASSES";
export const SEANCESDAY = "session/SEANCESDAY";
export const SCSTUDENTS = "session/SCSTUDENTS";
export const ABSENCES = "session/ABSENCES";
export const BECOMEADMIN = "session/BECOMEADMIN";
export const DATA = "session/DATA";
export const CATEGORIES_LIST = "session/CATEGORIES_LIST";
export const EXERCISES_LIST = "session/EXERCISES_LIST";
export const LISTSCHOOLS = "session/LISTSCHOOLS";
export const ADDSCHOOL = "session/ADDSCHOOL";
export const PROFSTUDENTS = "session/PROFSTUDENTS";
export const CRUDSTUDENTS = "session/CRUDSTUDENTS";
export const SCHOLARYEARS = "session/SCHOLARYEARS";
export const UPDATESCHOOL = "session/UPDATESCHOOL";
export const UPDATELOGO = "session/UPDATELOGO";
export const DELETELOGO = "session/DELETELOGO";
export const ADDSUBJECT = "session/ADDSUBJECT";
export const EDITSUBJECT = "session/EDITSUBJECT";
export const DELETESUBJECT = "session/DELETESUBJECT";
export const LISTCHAPTER = "session/LISTCHAPTER";
export const ADDCHAPTER = "session/ADDCHAPTER";
export const DELETECHAPTER = "session/DELETECHAPTER";
export const LISTCOMPETENCY = "session/LISTCOMPETENCY";
export const UPDATECOMPETENCY = "session/UPDATECOMPETENCY";
export const LISTTYPECLASSROOM = "session/LISTTYPECLASSROOM";
export const UPDATETYPECLASSROOM = "session/UPDATETYPECLASSROOM";
export const ADDTYPERECLAMATION ="session/ADDTYPERECLAMATION";
export const LISTCLASSROOM = "session/LISTCLASSROOM";
export const UPDATECLASSROOM = "session/UPDATECLASSROOM";
export const LISTHOLIDAY = "session/LISTHOLIDAY";
export const UPDATEHOLIDAY = "session/UPDATEHOLIDAY";
export const LISTEVALUATION = "session/LISTEVALUATION";
export const ADDEVALUATION = "session/ADDEVALUATION";
export const LISTCONTROL = "session/LISTCONTROL";
export const UPDATECONTROL = "session/UPDATECONTROL";
export const LISTEVENT = "session/LISTEVENT";
export const UPDATEEVENT = "session/UPDATEEVENT";
export const ADDEVENT ="session/ADDEVENT";
export const DELETEEVENT ="session/DELETEEVENT";

export const profClassesAction = createAction(PROFCLASSES, async (data) => {
  return await prof_classes_api(data);
});

export const seancesDayAction = createAction(SEANCESDAY, async (object) => {
  return await seancesDay(object);
});

export const schoolStudentsAction = createAction(SCSTUDENTS, async (object) => {
  return await schoolStudents(object);
});

export const addSchool = createAction(ADDSCHOOL, async (object) => {
  const user = get("session_user");
  return await add_school(user.token, object);
});

export const scholarYearsAction = createAction(SCHOLARYEARS, async () => {
  return await schools_scholar_years();
})

export const updateSchoolAction = createAction(UPDATESCHOOL , async (object) => {
  const user = get("session_user");
  return await update_school_info(user.token, object);
});

export const updateSchoolLogoAction = createAction(UPDATELOGO, async (object) => {
  const user = get("session_user");
  return await update_school_logo(user.token, object);
})

export const addSubjectAction = createAction(ADDSUBJECT, async (object) => {
  return await sc_settings_update_api(object);
})

export const editSubjectAction = createAction(EDITSUBJECT, async (object) => {
  const user = get("session_user");
  return await sc_settings_update_api(user.token, object);
})

export const deleteSubjectAction = createAction(DELETESUBJECT, async (object) => {
  return await sc_settings_delete_api(object);
})

export const listChapterAction = createAction(LISTCHAPTER, async (object) => {
  const user = get("session_user");
  return await list_chapter(user.token, object);
})

export const addChapterAction = createAction(ADDCHAPTER, async (object) => {
  const user = get("session_user");
  return await add_chapter(user.token, object);
})

export const deleteChapterAction = createAction(DELETECHAPTER, async (object) => {
  const user = get("session_user");
  return await delete_chapter(user.token, object);
})

export const listCompetencyAction = createAction(LISTCOMPETENCY, async (object) => {
  return await list_competencies_api(object);
})

export const updateCompetencyAction = createAction(UPDATECOMPETENCY, async (object) => {
  return await update_competency(object);
})

export const listControlsAction = createAction(LISTCONTROL, async (object) => {
  const user = get("session_user");
  return await list_control(user.token, object);
})

export const updateControlAction = createAction(UPDATECOMPETENCY, async (object) => {
  const user = get("session_user");
  return await update_control(user.token, object);
})

export const schoolUpdateControlAction = createAction(UPDATECOMPETENCY, async (object) => {
  return await school_update_control( object);
})
//lesson add 
export const schoolUpdateLessonAction = createAction(UPDATECOMPETENCY, async (object) => {
  const user = get("session_user");
  return await school_update_lesson(user.token, object);
})

export const listTypeClassroomAction = createAction(LISTTYPECLASSROOM, async (object) => {
  const user = get("session_user");
  return await list_type_classroom(user.token, object);
})

export const updateTypeClassroomAction = createAction(UPDATETYPECLASSROOM, async (object) => {
  const user = get("session_user");
  return await update_type_classroom(user.token, object);
})
export const addTypeReclamation = createAction(ADDTYPERECLAMATION,async (object)=>{
  const user =get("session_user");
  return await add_reclamationType(user.token, object)
})

export const listClassroomAction = createAction(LISTCLASSROOM, async (object) => {
  return await list_classroom(object);
})

export const updateClassroomAction = createAction(UPDATECLASSROOM, async (object) => {
  return await update_classroom(object);
})

export const listHolidaysAction = createAction(LISTHOLIDAY, async (object) => {
  const user = get("session_user");
  return await list_holidays(user.token, object);
})

export const updateHolidaysAction = createAction(UPDATEHOLIDAY, async (object) => {
  const user = get("session_user");
  return await update_holidays(user.token, object);
})

export const deleteSchoolLogoAction = createAction(DELETELOGO, async (object) => {
  const user = get("session_user");
  return await delete_school_logo(user.token, object);
})
export const listSchoolsAction = createAction(LISTSCHOOLS, async (data) => {
  const user = get("session_user");
  
  data.is_admin = user.profile.is_school_admin && !user.profile.is_assistant;
  // if(!data.is_admin && data.project_id){
  //   data.project_id = undefined;
  // }
  if(!data.scholar_year_id && get("current_year")){
    data.scholar_year_id = get("current_year").id;
  }
  return await get_list_schools(data);
});

export const listEventsAction = createAction(LISTEVENT, async (object) => {
  const user = get("session_user");
  return await list_events(user.token, object);
})

export const updateEventsAction = createAction(UPDATEEVENT, async (object) => {
  const user = get("session_user");
  return await update_events(user.token, object);
})
export const addEventAction =createAction(ADDEVENT , async (object) => {
  const user = get("session_user");
  return await add_events(user.token, object);})
export const deleteEventAction =createAction(DELETEEVENT, async (object)=>{
  const user =get("session_user");
  return await delete_events(user.token, object);
})  

export const listEvaluationAction = createAction(LISTEVALUATION, async (object) => {
  const user = get("session_user");
  return await list_evaluation(user.token, object);
});

export const listAppreciationsAction = createAction(LISTEVALUATION, async (object) => {
  const user = get("session_user");
  return await list_appreciations(user.token, object);
});

export const addevaluationAction = createAction(ADDEVALUATION, async (object) => {
  const user = get("session_user");
  return await add_evaluation(user.token, object);
});

export const addAppreciationAction = createAction(ADDEVALUATION, async (object) => {
  const user = get("session_user");
  return await add_appreciation(user.token, object);
});

export const absencesAction = createAction(ABSENCES, async (object) => {
  return await absencesApi( object);
});

export const categoriesExercisesAction = createAction(CATEGORIES_LIST, async (object) => {
  // const user = get("session_user");
  return await exercise_types_api();
});

// export const exercisesListAction = createAction(EXERCISES_LIST, async (object) => {
//   const user = get("session_user");
//   return await exercises_list_api(user.token, object);
// });

export const globalData = createAction(DATA, async(object) => {
  const levels = get_levels_api(object);
  // const libraraies = get_library_api(object);
  if(object.school_id){
    get_sms_api(object);
  }
  // const permissions = school_permissions_api_list(object);
  return  /*await permissions +*/ await levels/*+ await libraraies*/;

});

export const becomeAdminAction = createAction(BECOMEADMIN, async () => {
  const user = get("session_user");
  return await becomeAdmin(user.token);
});

export const profStudentsAction = createAction(PROFSTUDENTS, async (object) => {
  return await profStudents(object);
});

export const crudStudentsAction = createAction(CRUDSTUDENTS, async (object) => {
  const user = get("session_user");
  return await crudStudents(user.token, object);
});