import React, { Component } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import Slider from "react-slick";
import { withTranslation, Trans } from 'react-i18next';
import bgSubject from "../assets/img/subjects-bg.svg";
import { images } from "../pages/children/home/_resources";
import { colors } from "../assets/variables/colors";

import "slick-carousel/slick//slick.css";
import "slick-carousel/slick/slick-theme.css";

class SliderSubjectsHome extends Component {
  /* TODO: TRANSLATIONS */

  constructor(props){
    super(props);
  }
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1
    };

    return (
      <Subjects>
        <h2 className="home">{ this.props.t('My subjects') }</h2>

        <MediaQuery query="(min-width: 768px)">
          <div className="subjects-bg">
            <div className="subjects-bbg">
              <Slider {...settings}>
                <a>
                  <img src={images.maths} className="img-fluid" />
                  <h4>{ this.props.t('MATHS') }</h4>
                </a>
                <a>
                  <img src={images.frensh} className="img-fluid" />
                  <h4>{ this.props.t('FRENCH') }</h4>
                </a>
                <a>
                  <img src={images.arabic} className="img-fluid" />
                  <h4>العربية</h4>
                </a>
                <a>
                  <img src={images.sciences} className="img-fluid" />
                  <h4>{ this.props.t('SCIENCE') }</h4>
                </a>
              </Slider>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery query="(max-width: 767px)">
          <div className="subjects-mobile">
            <a>
              <img src={images.maths} className="img-fluid" />
              <h4>{ this.props.t('MATHS') }</h4>
            </a>
            <a>
              <img src={images.frensh} className="img-fluid" />
              <h4>{ this.props.t('FRENCH') }</h4>
            </a>
            <a>
              <img src={images.arabic} className="img-fluid" />
              <h4>العربية</h4>
            </a>
            <a>
              <img src={images.sciences} className="img-fluid" />
              <h4>{ this.props.t('SCIENCE') }</h4>
            </a>
          </div>
        </MediaQuery>
      </Subjects>
    );
  }
}

const Subjects = styled.div`
  margin-top: 60px;
  .subjectsH2 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: ${colors.orange};
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }
  .slick-slider {
    width: 100%;
    float: left;
  }
  .subjects-bg {
    position: relative;
    max-width: 780px;
    background: url(${bgSubject}) no-repeat scroll;
    background-size: 100% auto;
    -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
    -moz-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
    -ms-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
    -o-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
    -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
    filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
    &.subjects-home {
      margin: auto;
    }
    margin: 0 -15px 20px;
    .subjects-bbg {
      min-height: 244px;
      padding: 0 0 0 10px;
      margin: auto;
      position: relative;
      margin: 0 90px;
      margin: 0 65px;

      .slick-list {
        border-radius: 250px;
        position: relative;
        overflow: hidden;
        margin: 37px 0 0;
        padding-bottom: 26px;
        padding-top: 10px;
        padding-left: 28px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 17px;
          bottom: 2px;
          border-radius: 250px;
          overflow: hidden;
        }
        .slick-track {
          text-align: left;
        }
        .sliderClass {
          padding-top: 18px;
          .itemClass {
            text-align: center;
          }
        }
      }
      a {
        position: relative;
        h4 {
          position: absolute;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          bottom: 29px;
          left: 16px;
          right: 65px;
          text-align: center;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        }
      }
      img {
        max-width: 160px;
        display: inline-block;
        -webkit-filter: drop-shadow(0 7px 10px rgba(0, 0, 0, 0.15));
        -moz-filter: drop-shadow(0 7px 10px rgba(0, 0, 0, 0.15));
        -ms-filter: drop-shadow(0 7px 10px rgba(0, 0, 0, 0.15));
        -o-filter: drop-shadow(0 7px 10px rgba(0, 0, 0, 0.15));
        filter: drop-shadow(0 7px 10px rgba(0, 0, 0, 0.15));
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: 10px;
    border: none;

    outline: none;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 35px;
    z-index: 1000;
    border: none;
    background: #46bfb6;
    width: 36px;
    height: 36px;
    opacity: 1;
    cursor: pointer;
    &:hover,
    &:focus {
      opacity: 1 !important;
      background: #46bfb6;
      color: #fff;
    }
    &:before {
      font-size: 20px;
      color: ${colors.white};
      display: block;
      font-family: revicons;
      text-align: center;
      z-index: 2;
      position: relative;
    }
  }
  .slick-prev {
    left: -50px;
    &:before {
      content: "\\E824";
    }
  }
  .slick-next {
    right: -50px;
    &:before {
      content: "\\E825";
    }
  }

  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 19px;
    }
    .subjects-bg {
      max-width: 730px;
      background-size: 730px auto;
      .subjects-bbg {
        margin: 0 80px;
        .slick-list {
          float: left;
          width: 100%;
          margin: 32px 0 0;
        }
        img {
          max-width: 148px;
        }
        .react-multi-carousel-list {
          padding: 0 0 15px;
        }
      }
    }
    .arrow-next,
    .arrow-prev {
      top: 47%;
    }
  }

  @media screen and (max-width: 767px) {
    box-shadow: 0 -4px 16px -10px rgba(0, 0, 0, 0.45);
    padding-top: 15px;
    h2 {
      font-size: 17px;
      margin-bottom: 20px;
      width: 210px;
      padding-left: 0;
      padding-right: 0;
    }
    .subjects-mobile {
      max-width: 210px;
      margin: auto;
      a {
        display: block;
        position: relative;
        margin: 42px 0;
        img {
          -webkit-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
          -moz-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
          -ms-filter: drop-shadow(5px -22px 23px rgba(0, 0, 0, 0.15));
          -o-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
        }
        h4 {
          position: absolute;
          color: rgb(255, 255, 255);
          font-size: 17px;
          font-weight: 600;
          text-transform: uppercase;
          bottom: 50px;
          left: 69px;
          right: 65px;
          text-align: center;
          text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
          margin: 0px;
        }
      }
    }
  }
`;

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withTranslation('translations')(SliderSubjectsHome));
