import React, { Component} from "react";
import { withRouter, Redirect} from "react-router-dom";
import { clear, set, get } from "services/storage";
import { school_classes_api, prof_classes_api, schoolStudents, profStudents, schools_scholar_years,
  school_professors_list_api, professor_professors_list_api, check_school_api, log_js_error,
  get_koolskools_levels, school_settings_payment_api_get} from "services/cms/api";
import axios from "axios";
import localforage from 'localforage';


const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
const base_url = process.env.REACT_APP_URL_CMS;
class LayoutEscortSystem extends Component {
  constructor(props) {
    super(props);
    this.user = get("session_user");
    this.ws = false
    // var school_ws = ""; 
    // if(props.match.params.school_id && !this.user.profile.is_formator)
    //   school_ws = `&school_id=${props.match.params.school_id}`
    // if(!this.user.profile.is_formator)
    //   this.ws = new WebSocket(
    //       `${process.env.REACT_APP_URL_MESSAGING}/ws/users/?username=${
    //           this.user.profile.username
    //       }${school_ws}`
    //   );

    this.state = {
      menuGlobal: false,
      data_loaded:false,
    };


    if(!this.user || (['esc'].indexOf(this.user.account_type) < 0 && ['esc'].indexOf(this.user.account_type_2) < 0)) 
      return <Redirect to="/home/school" />;
    if(/([À-ú]|\s)/.test(this.user.profile.username)){
      var lev = get("level_groups");
      var current_language = get("current_language");
      clear();
      localforage.clear();
      if(global.ws_notification)
        global.ws_notification.close();
      set("level_groups", lev);
      set("current_language", current_language);
      return <Redirect to="/home/school" />;
    }
  }

  componentWillReceiveProps(props){
    this.setState({
    })
  }

  componentDidCatch(error, info) {
    this.props.history.push('/ErrorPage');
    var data={
      error_stack: error.stack,
    }
    log_js_error(data);
  }

  componentDidMount(){
  }

  handleSwitchMenuGlobal = () => {
    this.setState({
      menuGlobal: !this.state.menuGlobal
    })
  }

  render() {
    const { Layout, Component } = this.props;
  return (
      <Layout 
        handleSwitchMenuGlobal={this.handleSwitchMenuGlobal}
        menuGlobal={this.state.menuGlobal}
      >
        <Component
          {...this.props}
          global_data={this.state}
        />
      </Layout>
    );
  }
};

export default withRouter(LayoutEscortSystem);
