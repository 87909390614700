import React, { Component } from "react";
import Header from "./components/Header";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import $ from "jquery";
import ChatBot from "./components/Bloc-chatbot";
import SectionGradient from "./components/SectionGradient";

import styled from "styled-components";
import Carousel from "./components/Carousel";
import BlocFeeds from "./components/Bloc-feeds";
import SliderSubjectsHome from "../../../components/SliderSubjectsHome";
import AvantagesKoolskools from "./components/AvantagesKoolskools";
import NotesKoolskools from "./components/NotesKoolskools";
import Footer from "./components/footer";
import { get } from "../../../services/storage";

window.jQuery = $;
class Home extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.state = {
      clientWidth: 0,
      clientHeight: 0,
      widthMobile: 0,
      heightMobile: 420,
      openConnexion:  query.get('login')?true:false,
      openRegister: false
    };
    this.myRef = React.createRef();
    this.RefMobile = React.createRef();
  }

  updateDimensions = () => {
    this.setState({
      clientWidth: this.myRef.current.clientWidth,
      clientHeight: this.myRef.current.clientHeight,
      widthMobile: this.RefMobile.current.offsetWidth,
      heightMobile: this.RefMobile.current.offsetHeight
    });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <Children>
        <ChatBot /> 
        <Header
          pages="children"
          {...this.props}
          openConnexion={this.state.openConnexion}
          openRegister={this.state.openRegister}
          hash={this.props.location.hash}
          handleCloseConnexion={() => this.setState({ openConnexion: false })}
          handleOpenConnexion={() => this.setState({ openConnexion: true })}
        />
        <section className="one" ref={this.myRef}>
          <SectionGradient
            clientWidth={this.state.clientWidth}
            clientHeight={this.state.clientHeight}
            widthMobile={this.state.widthMobile}
            heightMobile={
              this.state.heightMobile > -60 ? 420 : this.state.heightMobile
            }
          />
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-12 " ref={this.RefMobile}>
                <Carousel
                  handleOpenModalConnexion={() =>
                    this.setState({ openConnexion: true })
                  }
                  handleOpenModalRegister={()  => this.setState({openRegister:true})}
                />
              </div>
              <div className="col-xl-4 col-lg-12 news-feed">
                <BlocFeeds />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="two">
          <div className="container-subjects">
            <SliderSubjectsHome pages="home" />
          </div>
        </section> */}
        {/* <AvantagesKoolskools /> */}
        {/* <NotesKoolskools /> */}
        <Footer />
      </Children>
    );
  }
}
const Children = styled.div`
  section {
    margin-bottom: 120px;
  }
  @media (max-width: 1199px) {
    section {
      margin-bottom: 150px;
    }
  }
`;
const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(Home));
