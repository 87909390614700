import axios from "axios";
import { set, get } from "./storage";

const instance = axios.create({ baseURL: process.env.REACT_APP_URL_WS });

// list child friends
  export const list_child_friends = (token, param, data) => {
      return instance
          .get(`/list_child_friends/${param}`, {
              headers: {Authorization: "Bearer " + token},
              params: data
          })
          .then(response => {return response.data;})
          .catch(err => { if (err.response) {} throw err;});
  }

  export const myChildren = (token, data) => {
    return instance.get(`/my_children`, {
        headers: {
            Authorization: "Bearer " + token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };

  export const child_periods = (data) => {
    const user = get('session_user');
    return instance.get(`/child_periods`, {
        headers: {
            Authorization: "Bearer " + user.token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };

  export const myChildrenInvitations = (token, data) => {
      return instance.get(`/get_all_invitations`, {
          headers: {
              Authorization: "Bearer " + token
          },
          params: data
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {} throw err;});
    };

  export const events_api_list = (data) => {
    const user = get('session_user');
    return instance.get(`/events_api_list`, {
        headers: {
            Authorization: "Bearer " + user.token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };
  export const student_events_api_list = (data) => {
    const user = get('session_user');
    return instance.get(`/student_events_api_list`, {
        headers: {
            Authorization: "Bearer " + user.token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };

  export const update_event_api = (data) => {
    const user = get('session_user');
    return instance
      .post('/update_event_api', data, {
        headers: {Authorization: "Bearer "+ user.token},
      })
      .then(response =>{return response.data;})
      .catch(err => {if (err.response) {} throw err;});
  };

  export const childAbsences = (token, data) => {
      return instance.get(`/child_absences`, {
          headers: {
              Authorization: "Bearer " + token
          },
          params: data
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {} throw err;});
  };

  export const childs_modules_api_count = (data) => {
    const user = get('session_user');
    return instance.get(`/childs_modules_count`, {
        headers: {
            Authorization: "Bearer " + user.token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };
  // notif reclamation
  export const reclamation_response_api_count= (data) => {
    const user = get('session_user');
    return instance.get(`/reclamation_response_count`, {
        headers: {
            Authorization: "Bearer " + user.token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };
  // notif eval
  export const child_evaluation_api_count= (data) => {
    const user = get('session_user');
    return instance.get(`/child_evaluation_count`, {
        headers: {
            Authorization: "Bearer " + user.token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };

  // export const child_absences_viewed_api = (data) => {
  //   const user = get('session_user');
  //   return instance.post(`/child_absences_viewed`, data, {
  //       headers: {
  //           Authorization: "Bearer " + user.token
  //       }
  //   }).then(response => {
  //     return response.data;
  //   })
  //   .catch(err => { if (err.response) {} throw err;});
  // };

  export const child_tasks_api_count = (data) => {
    const user = get('session_user');
    return instance.get(`/child_tasks_count`, {
        headers: {
            Authorization: "Bearer " + user.token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };

  export const saveAbsenceComment = (token, data) => {
      return instance.post(`/save_absence_comment`, data, {
          headers: {
              Authorization: "Bearer " + token
          }
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {} throw err;});
  };

  export const checkAccountExists = (token, data) => {
    return instance.get(`/check_account_exists_request`, {
        headers: {
            Authorization: "Bearer " + token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };
  
  export const addChild = (token, data) => {
    return instance.post(`/add_child`, data, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };
  
  export const getSubscriptions = (token, data) => {
    return instance.get(`/account/subscriptions_packs`, {
        headers: {
            Authorization: "Bearer " + token
        },
        params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {} throw err;});
  };

export const childTasks = (token, data) => {
    return instance
        .get('/child_tasks', {
            headers: {Authorization: "Bearer " + token},
            params: data
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {} throw err;});
}

//delete child
export const delete_child_api = (token, object) => {
  return instance
    .post('/delete_child', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => {if (err.response) {} throw err;});
}

//attach child
export const attach_child_api = (token, object) => {
  return instance
    .post('/attach_child', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => {if (err.response) {} throw err;});
}

//remove child_to_accept
export const remove_child_to_accept_api = (token, object) => {
  return instance
    .post('/remove_child_to_accept', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => {if (err.response) {} throw err;});
}
/***********profile parent*******************/
export const save_parent_profile_api = (data) => {
  const user = get("session_user");
  return instance
    .post('/edit_parent', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {} throw err;});
}

//Edit child
export const edit_child_api = (token, object) => {
return instance
.post('/edit_children_save', object, {
  headers: {
    Authorization: "Bearer " + token
  },
})
.then(response => {return response.data;})
.catch(err => { if (err.response) {} throw err;});
}     

export const childCourses = (token, username) => {
  return instance
      .get('/child_courses_stats/'+username, {
          headers: {Authorization: "Bearer " + token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {} throw err;});
}

export const childExams = (token, username) => {
  return instance
      .get('/child_exams/'+username, {
          headers: {Authorization: "Bearer " + token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {} throw err;});
}


// list evaluations
export const list_evaluations = (object) => {
  const user = get("session_user");
  return instance
    .get('/parent/evaluations', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
}


  
export const rejectInvitation = (token, data) => {
  return instance.post(`/reject_invitation_from_parent`, data, {
      headers: {
          Authorization: "Bearer " + token
      }
  })
  .then(response => {
    return response.data;
  })
  .catch(err => { if (err.response) {} throw err;});
};



