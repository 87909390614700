import React, { Component } from "react"
import Modal from 'react-responsive-modal'
import {images} from "../_resources"

export default class ModalPasswordChanged extends Component {
    constructor(props){
        super(props);
        this.state = {
          reset_password_error: this.props.reset_password_error,
          reset_password_response: this.props.reset_password_response
        };
    }

    componentWillReceiveProps(props){
        this.setState({
          reset_password_error: props.reset_password_error,
          reset_password_response: props.reset_password_response
        });
    }

    handleClose = () => {
        this.forceQuite = true;
        this.props.handleClose()
    }
    render(){
     /* TODO: TRANSLATIONS */
        return(
            <Modal open={!!this.props.success  || (this.state.reset_password_response || this.state.reset_password_error) && !this.forceQuite}
                onClose={this.handleClose}
                showCloseIcon={false}
                center
                classNames={{
                    overlay: {dialogOverlay: "white"},
                    modal: {
                        dialog: "white", 
                        dialogSuccess: "white", 
                        dialogLaraki: global.is_laraki_logo?"white":false,
                        dialogSanady: global.is_sanady_logo?"white":false,
                        dialogPont: global.is_pont_logo?"white":false,
                        dialogFMPS: global.is_fmps_logo?"white":false
                    },
                }}>
                <div className="dialogSucce">
                <a className="closeModal" onClick={() => this.handleClose()}><img src={images.iconClose} /></a>
                {this.props.reset_password_error ? 
                <h5 style={{color: "red"}}>{this.props.reset_password_error}</h5>
                :
                <h5>Un nouvel email contenant votre mot de passe temporaire vous a été envoyé. Veuillez vous connecter à nouveau avec ce mot de passe.</h5>
                }
                
                    {/* <h5>Votre mot de passe a ete change reconnectiez-vous svp.</h5> */}
                </div>
            </Modal>
        )
    }
}