import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import styled from "styled-components";
import ModalConfirm from "pages/school/inscriptions/components/ModalConfirm";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import LdsSpinner from 'components/LdsSpinner';

import {clear} from "services/storage";

import { set, get } from "services/storage";
import { withTranslation, Trans } from 'react-i18next';

import { loginProfessor } from "services/actions";
import { globalData } from "services/cms/actions";

import { sanady_unconnected_api_get } from "services/cms/api";
import ModalPassword from "pages/children/home/components/ModalPassword"
import ModalProfessorSanadiRegister from "pages/professor/home/components/ModalProfessorSanadiRegister"
import ModalCompteSuccess from "pages/children/home/components/ModalCompteSuccess";

import {images} from './_resources'
import ModalPasswordChanged from "../professor/home/components/ModalPasswordChanged";
import {forgotPassword} from "services/api";
import localforage from 'localforage';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open_register_professor_sanadi: false,
      open_ask_if_professor_sanadi_exists: false,
      openConnexion: false,
      success: false,
      messageSuccess: "",
      section: 0,
      resend_activation_data: "",
      resend_activation_data_message: "",
      programs_projects_schools: {},
      isSubmitting: false,
      reset_password_response: "",
      reset_password_error: "",
      page: 0
    }

    const query = new URLSearchParams(props.location.search);

    if(query.get('reset_password')){
      var openConnexion = true;
      var data_ = {
        key: query.get('key'),
        id: query.get('id'),
        from: 'koolskools'
      };
      if(query.get('is_email')){
        data_.is_email = true;
      }
      if(global.is_laraki_logo)
        data_.from = 'elaraki';
      else if(global.is_sanady_logo)
        data_.from = 'sanady';
      else if(global.is_pont_logo)
        data_.from = 'pont';
      else if(global.is_reference_logo)
        data_.from = 'reference';
      else if(global.is_yakada_logo)
        data_.from = 'yakada';
      else if(global.is_kastalani_logo)
        data_.from = 'kastalani';
      else if(global.is_babylone_logo)
        data_.from = 'babylone';
      else if(global.is_fmps_logo)
        data_.from = 'FMPS';
      forgotPassword(data_).then(data => {
        this.setState({reset_password_response: data.response_msg, reset_password_error: data.error});
      })
      .catch(error => {
      });
    }
  }

  componentDidMount(){
    if(global.is_sanady_logo){
      this.getProgramsProjectsSchools();
    }
  }

  onOpenModalPassword = () => {
    this.setState({
      open: true
    });
  };
  onOpenModalProfessorSanadiRegister = (professor_already_exists, close_open_ask_if_professor_sanadi_exists) => {
    this.setState({
      professor_already_exists: professor_already_exists,
      open_ask_if_professor_sanadi_exists: close_open_ask_if_professor_sanadi_exists ? false : this.state.open_ask_if_professor_sanadi_exists,
      open_register_professor_sanadi: true
    });
  };
  onOpenModalAskIfProfessorSanadiExists = () => {
    this.setState({
      open_ask_if_professor_sanadi_exists: true
    });
  };
  onCloseModalProfessorSanadiRegister = () => {
    this.setState({
      professor_already_exists: false,
      open_register_professor_sanadi: false
    });
  };

  onCloseModalPassword = () => {
    this.setState({
      open: false
    });
  };

  handlePasswordReseted = (message) => {
    this.setState({
      success: true,
      messageSuccess: message,
      open: false
    });
  };

  handleCloseSuccess = () => {
    this.setState({
      success: false,
      messageSuccess: ""
    });
  };

  getValidationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required(this.props.t("Username required")),
      password: Yup.string()
        // .min(5, this.props.t("Password must be greater than 5 character"))
        .required(this.props.t("Password required"))
    });
  };

  handleSubmit = form => {
    const {section} = this.state;
    let loginprof = this.props.addedData || {};
    if(this.props.addedData){
      loginprof.added_action = "add_school";
    }
    loginprof.username = form.username;
    loginprof.password = form.password;
    if(section == 3)
      loginprof.page_front = 'parent';
    
    var lev = get("level_groups");
    var current_language = get("current_language"),
      device_token = get('device_token');
    clear();
    localforage.clear();
    if(global.ws_notification)
      global.ws_notification.close();
    set("device_token", device_token);
    set("level_groups", lev);
    set("current_language", current_language);
    return this.props
      .dispatch(loginProfessor(loginprof))
      .then(data => {
        const user = get("session_user");
        if (data.payload.success) {
          if(section === 2){
            if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else{
              if(!user.profile.is_school_admin)
                this.props.dispatch(globalData({}));
              this.props.history.push("/");
            }
          }else if (section === 3) {
            if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0 || user.account_type.indexOf('pa') != -1){
              this.props.history.push("/parents/dashboard");
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else{
              if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
                this.props.dispatch(globalData({}));
                this.props.history.push("/parents/dashboard");
                // else
                //   this.props.history.push("/parents/candidatures");
              }
              else
                this.props.history.push("/");
            }
          }else if (section === 1) {
            if(['pr', 'pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
              
              if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0)){
                this.props.history.push(`/professor/info_profile`);
              }
              else{
                this.props.dispatch(globalData({}));
                if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                  this.props.history.push(`/professor/activities_prescolaire`);
                else
                  this.props.history.push(`/professor/classes`);
              }
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(user.profile.is_school_admin){
              this.props.history.push("/");
            }
            else if(this.props.key_inv){
              this.props.dispatch(globalData({}));
              this.props.history.push("/parents/dashboard?key_inv=" + this.props.key_inv);
            }
            else if(['pa', 'pr_pa'].indexOf(user.account_type) >= 0){
              this.props.history.push("/parents/dashboard");
            }
            else{
              this.props.history.push("/");
            }
          } else {
            if(user.profile.is_school_admin && (user.profile.is_program_system || user.profile.is_sanady_assistant) && !get('project')){
              this.props.history.push("/school/program/list");
            }
            else if(user.profile.is_school_admin && (user.profile.is_program_system || user.profile.is_sanady_assistant)){
              this.props.history.push("/school/list");
            }
            else if(user.profile.is_school_admin){
              this.props.history.push("/school/list");
            }
            else if(user.profile.is_escort){
              this.props.history.push("/escort/transport");
            }
            else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && ((user.schools || user.profile && user.profile.schools || []).length > 0 || user.profile && user.profile.had_sanady_school_from_last_year)){
              
              if(false && !((user.schools || user.profile && user.profile.schools || []).length > 0)){
                this.props.history.push(`/professor/info_profile`);
              }
              else{
                this.props.dispatch(globalData({}));
                if(user.profile.schools.findIndex(sc => sc.group_id == 249) >= 0)
                  this.props.history.push(`/professor/activities_prescolaire`);
                else
                  this.props.history.push(`/professor/classes`);
              }
            }
            else
              this.props.history.push("/");
          }
        }
        
      })
      .catch(err => {
        var message = err.message || "";
        this.setState(state => ({
          errorGlobal: message.replace(",mail_not_yet_verified", "").replace(",phone_not_yet_verified", ""),
          username: form.username,
          isSubmitting: false
        }));
      });
  }

  render_connexion = () => {
    const {isSubmitting, section} = this.state;
    return <div className="modalConnexion">
    <div className="modalConnexionTop">
      <Formik
        initialValues={{
          username: this.props.username || this.state.username || "",
          password: ""
        }}
        validationSchema={this.getValidationSchema}
        onSubmit={values => {
          this.setState({
            isSubmitting:true
          })
          this.handleSubmit(values);
        }}
      > 
        {({ errors, touched, values, handleChange }) => {
          return (<Form className="d-flex flex-column align-items-center">
            {this.state.errorGlobal && (                    
              <div style={{color: 'red'}}>{this.state.errorGlobal}</div>
            )}
           
          <div className="">
            <div className="form-connexion">
              <div className="form-item">
                <label>Identifiant</label>
                <input type="text"
                  className={`textInput ${errors.username && "textInputError"}`}
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                />
                {errors.username && touched.username ? (
                  <div className="textErrors">{errors.username}</div>
                ) : null}
              </div>
              <div className="form-item">
                <label>Mot de passe</label>
                <input type="password"
                  className={`textInput ${errors.password && "textInputError"}`}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && touched.password ? (
                  <div className="textErrors">{errors.password}</div>
                ) : null}
              </div>
              <div className="text-right" style={{width: 500}}>
                <a onClick={() => this.onOpenModalPassword()} className="forgot" style={{float:'none'}}>
                  { this.props.t('FORGOT PASSWORD?') }
                </a>
              </div>
            </div>
            <div className="form-footer">
              <div className='d-flex'>
                {isSubmitting && <LdsSpinner type='relative'/>}
                <button type="submit" className="signin" 
                  style={{float:'none', backgroundColor: '#03387a'}} 
                  disabled={isSubmitting}>
                { this.props.t('SIGN IN') } 
                </button>
              </div>
            </div>
          </div>
        </Form>);
        }}
      </Formik>
    </div>
  </div>
  }

  getProgramsProjectsSchools = () => {
    var data = {
      action: "get_programs_projects_schools",
    };
    sanady_unconnected_api_get(data).then(res => {
      if(res.success){
        this.setState({programs_projects_schools: res.programs_projects_schools || {}});
      }

    }).catch(err => {
      console.log(err);
    })
  }

  render_first_page = () => {
    return <div className="first_page" style={{
        backgroundImage: `url(${images.bg_unconnected})`, padding: '4vh'
      }}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <div>
          <img src={images.logo_fmps} style={{width: '5vw'}}/>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className="menu-header" style={{width: '50vw', marginRight: 20}}>
            <div className="menu-item">ACCUEIL</div>
            <div className="menu-item">FMPS</div>
            <div className="menu-item">CONTACT</div>
            <div className="menu-item">FAQ</div>
          </div>
          <div style={{color: '#FFF', fontSize: 18, fontWeight: 400}}>
            FR <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div>
      <div style={{
          display: 'flex', height: '90vh'
        }}>
        <div style={{
          display: 'flex', flexDirection: 'column', width: '29vw'
        }}>
          <img src={images.logo_atfalouna_learning} style={{width: '100%', marginTop: '6vh'}}/>
          <img src={images.home_form} style={{width: '100%'}}/>
          {/* <div className='description_home' style={{backgroundImage: `url(${images.home_form})`, 
          //   color: '#043566', width: '100%', aspectRatio: 1, backgroundSize: '100% 100%',
          //   display: 'flex', justifyContent: 'center', alignItems: 'center',
          //   flexDirection: 'column', padding: '8vh', fontWeight: 500}}>
          //    <b>ATFALONA LARNING,</b>
          //   <div className="text-center">La platforme idéale pour les enfants du préscolaire!</div>
          //   <div className="text-center">
          //     Plongez dans un univers ludique riche d'activités pédagogiques interactives adaptées aux besoins des enfants, 
          //     stimulant leur curiosité et favorisant le développement de leurs compétences.</div> 
           </div> */}
        </div>
        <div style={{display: 'flex', flexDirection: 'column', 
            alignItems: 'center', justifyContent: 'space-between', margin: '0px 1vw', width: '40vw'}}>
          <img src={images.all_plateforme_img} style={{width: '100%', marginTop: '7vh'}}/>
          <div style={{display: 'flex', flexDirection:'column',
              alignItems: 'center'
          }}>
            <button className="signin" 
              onClick={() => this.setState({page : 1})}
              style={{float:'none', backgroundColor: '#03387a', padding: '1vw 1.5vh', fontSize: '1.2vw', minWidth: '24vw'}} 
              >
              CONNECTEZ-VOUS
            </button>
            <div style={{color: '#043566', marginTop: '1vh', fontSize: '1.2vw', fontWeight: 600}}> Pour commencer votre nouvelle expérience d'apprentissage !</div>
          </div>
        </div>
        <div style={{width: '30vw'}}></div>
      </div>
    </div>
  }

  render_login_page = () => {
    return <div className="d-flex" style={{padding: '0px 20px'}}>
    <div className="justify-content-center align-items-center" style={{overflow: 'hidden'}}>
      <img src={images.login_background} style={{height: '100vh'}}/>
    </div>
    <div style={{padding: '20px 0px 20px 100px', flex: 1}}>
      <div className="text-right">
        <img src={images.menu_home_connexion} 
          style={{cursor: 'pointer', position: 'absolute', right: 40}}
          onClick={() => this.setState({page : 0})}/>
      </div>
      <div style={{height: '100vh', width: 500}} className="d-flex flex-column justify-content-center align-items-center">
        
        <h1 style={{color: '#03387a', fontWeight: 'bold'}}>Connexion</h1>
        {this.render_connexion()}
        
      </div>
    </div>
  </div>
  }

  render() {
    const {section, page} = this.state;
    return (
      <Children>
        <section style={{overflow: 'hidden'}}>
          {/* <MediaQuery query="(min-width: 1200px)"> */}
          {page == 0?this.render_first_page():this.render_login_page()}
          {/* </MediaQuery> */}
          {/* <MediaQuery query="(max-width: 1200px)">
          {this.state.openConnexion ?
          <div className="modal-connexion">
            <div className="header">
              <div>
                <img src={global.logo} style={{width: 40}}/>
              </div>
              <div className="btn-close"
                onClick={() => this.setState({openConnexion: false})}>
                <i className="fa fa-times"/>
              </div>
            </div>
            <div className={'header-menu'}>
              <button className={`${section == 0?'active':''}`}
                onClick={() => section != 0?this.setState({section: 0}):{}}>
                élèves
                {section == 0?
                <span></span>
                :<></>
                }
              </button>
              <button className={`${section == 1?'active':''}`}
                onClick={() => section != 1?this.setState({section: 1}):{}}>
                enseignants
                {section == 1?
                <span></span>
                :<></>
                }
              </button>
              <button className={`${section == 2?'active':''}`}
                onClick={() => section != 2?this.setState({section: 2}):{}}>
                écoles
                {section == 2?
                <span></span>
                :<></>
                }
              </button>
              <button className={`${section == 3?'active':''}`}
                onClick={() => section != 3?this.setState({section: 3}):{}}>
                parents
                {section == 3?
                <span></span>
                :<></>
                }
              </button>
            </div>
            {this.render_connexion()}
          </div> 
          :
          <>
              {global.is_laraki_logo?
              <>
                <img src={videos.home_laraki} style={{width: '100%'}}/>
                <div className="signin" onClick={() => this.setState({openConnexion: true})}
                  style={{background: `linear-gradient(to bottom,${global.colors[0]} 0%,${global.colors[1]} 100%)`, margin: '40px auto'}}>
                  JE ME CONNECTE
                </div>
              </>
              :
              <>
                <video style={{height: '100vh'}}  loop autoPlay muted>
                    <source src={global.is_fmps_logo?videos.video_fmps:videos.video} type='video/mp4'/>
                    Your browser does not support the video tag.
                </video>
                <div className="open-connexion">
                  <img src={global.logo} style={{width: 150, top: 10}}/>
                  <div className="signin" onClick={() => this.setState({openConnexion: true})}
                    style={{background: `linear-gradient(to bottom,${global.colors[0]} 0%,${global.colors[1]} 100%)`}}>
                    JE ME CONNECTE
                  </div>
                </div>
              </>
              }
            </>
          }
          </MediaQuery> */}
        </section>
        <ModalPassword 
            open={this.state.open} 
            pages={section == 0?"children":(section == 2?"school":"")} 
            onCloseModalPassword={this.onCloseModalPassword}
            handlePasswordReseted={(message) => this.handlePasswordReseted(message)} />
        {this.state.open_register_professor_sanadi &&
          <ModalProfessorSanadiRegister 
            open={this.state.open_register_professor_sanadi} 
            onCloseModal={this.onCloseModalProfessorSanadiRegister}
            programs_projects_schools={this.state.programs_projects_schools}
            professor_already_exists={this.state.professor_already_exists}
            handleAccountCreated={(message) => {
              this.setState({
                open_register_professor_sanadi: false, 
                creation_account_message: message,
              })
            }} />
        }
        <ModalConfirm 
            message=""
            title={this.props.t("Were you already a teacher last year?")}
            show={this.state.open_ask_if_professor_sanadi_exists} 
            onClose={evt => {
              // this.onOpenModalProfessorSanadiRegister(false, true);
            }}
            onHide={() => {
              this.onOpenModalProfessorSanadiRegister(false, true)
            }}
            handleValidate={() => {
              this.onOpenModalProfessorSanadiRegister(true, true)
            }} />
        <ModalCompteSuccess
            success={this.state.success}
            message={this.state.messageSuccess}
            handleCloseSuccess={this.handleCloseSuccess}
          />
        <ModalPasswordChanged
          success={
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.password_changed
          }
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          handleClose={() => {
            this.setState({reset_password_response: "", reset_password_error: ""});
            const state = { ...this.props.history.location.state };
            delete state.password_changed;
            this.props.history.replace({
              ...this.props.history.location,
              state
            });
          }}
        />
      </Children>
    );
  }
}
const Children = styled.div`
  section {
    background: radial-gradient(circle, rgba(255,255,255,1) 45%, rgba(245,250,252,1) 83%);
    height: 100vh;
  }
  .description_home{
    font-size: 2vh;
    @media(max-width: 1199px) {
      font-size: 1.6vh;
    }
    @media(max-width: 767.98px) {
      font-size: 1.2vh;
    }

  }
  .menu-header{
    display: flex;
    justify-content: space-around;
    .menu-item{
      background: #FFF;
      color: #ed910b;
      font-size: 1.3vw;
      font-weight: 500;
      border-radius: 20px;
      padding: 0.5vw; 
      width: 8vw;
      box-shadow: 0 8px 16px 0 rgb(0 0 0 / 63%);
      text-align: center;
      cursor: pointer;
    }

  }
  .first_page{
    background-color: #FFF;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .open-connexion{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 6px 12px 33px 0 rgba(204, 235, 248, 0.19);
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .modal-connexion{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .header-menu{
      width: 100%;
      padding: 0 20px;
    }
    .header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      margin-bottom: 20px;
      .btn-close{
        width: 40px;
        height: 40px;
        flex-grow: 0;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 6px 12px 33px 0 rgba(255, 224, 60, 0.16);
        background-color: rgba(218, 218, 218, 0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        color:  #7d7d7d;
        font-size: 20px;
      }
    }
  }
  .header-menu{
    padding: 0;
    display: flex;
    align-self: center;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 400px;
    >button{
      text-transform: capitalize;
      color: #6e7b81;
      background: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.active{
        font-weight: bold;
        color: #042a45;
        span{
          background: #64c2be;
          width: 4px;
          height: 4px;
          display: block;
          border-radius: 50%;
        }
      }

    }
  }
  .form-connexion{
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-item{
      position: relative;
      label{
        position: absolute;
        top: 10px;
        padding: 0px 5px;
        background-color: #FFF;
        left: 20px;
        font-size: 16px;
      }
      input{
        width: 500px;
        flex-grow: 0;
        margin-top: 20px;
        padding: 20px;
        border-radius: 12px;
        border: solid 0.3px #8b8b8b;
        background-color: #FFF!important;
        color: #000;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
          -webkit-background-clip: text;
          -webkit-text-fill-color: #00000!important;
          transition: background-color 5000s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px #23232329;
           -webkit-box-shadow: 0 0 0 30px white inset !important;
        } 
      }
    }
  }
  a.forgot{
    margin: 0px;
    font-size: 14px;
    color: #ee9718;
  }
  .form-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    .forgot{
      margin: 14px auto 32px auto;
      font-size: 11px;
      line-height: 2;
      color: #ee9718;
    }
    .register{
      margin: 14px auto 32px auto;
      font-size: 11px;
      line-height: 2;
      color: #7d7e7e;
    }
  }

  .signin{
    // width: 276px;
    // height: 56px;
    padding: 10px 20px;
    flex-grow: 0;
    margin: 22px 42px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5vw;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 1px;
    color: #fff;
    border: none;
  }
`;
const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(Home)));
