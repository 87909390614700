import { get } from "services/storage";

export default class Permission {   
    
    constructor(permissions){
        this.user = get("session_user");
        this.permissions = permissions || get('permissions') || [];
        this.current_permission = false;
    }

    setPermissions = () => {
        this.permissions = get('permissions') || [];
    }

    setCurrentSection = section => {
        if(this.permissions.length)
            this.current_permission = this.hasSection(section)?this.permissions.filter(p => p.section.name == section)[0]:false;
    }

    is = section => {
        return this.current_permission && this.current_permission.section.name == section;
    }

    hasSection = section => {
        return (this.permissions.length == 0 && !this.user.profile.is_assistant) || this.permissions.filter(p => p.section.name == section).length > 0
    }

    hasSectionAbility = (section, ability) => {
        let permission = false;
        if(this.permissions.length)
            permission = this.hasSection(section)?this.permissions.filter(p => p.section.name == section)[0]:false;
        return (this.permissions.length == 0 && !this.user.profile.is_assistant) || (permission && permission.abilities.filter(ab => ab.name == ability).length > 0);
    }

    can = (ability, other_section) => {
        var current_perm = this.current_permission;
        if(other_section){
            current_perm = this.hasSection(other_section)?this.permissions.filter(p => p.section.name == other_section)[0]:false;
        }
        return (this.permissions.length == 0 && !this.user.profile.is_assistant) || (current_perm && current_perm.abilities.filter(ab => ab.name == ability).length > 0);
    }

    hasCycle = ability => {
        var permission = this.hasSection('cycles')?this.permissions.filter(p => p.section.name == 'cycles')[0]:false;
        return (this.permissions.length == 0 && !this.user.profile.is_assistant) || (permission && permission.abilities.filter(ab => ab.name == ability).length > 0);
    }

    hasLevel = (section, ability) => {
        var permission = this.hasSection(section)?this.permissions.filter(p => p.section.name == section)[0]:false;
        return (this.permissions.length == 0 && !this.user.profile.is_assistant) || (permission && permission.abilities.filter(ab => ab.name == ability).length > 0);
    }

    canMarkTransportBoarding = () => {
        var permission = this.hasSection('transport_boarding')?this.permissions.filter(p => p.section.name == 'transport_boarding')[0]:false;
        return permission && permission.abilities.filter(ab => ab.name == "mark").length > 0;
    }
    
    canViewOnlyHealthAndContactInfo = (abilities_to_check, no_more_abilities) => {
        var permission = this.hasSection('students')?this.permissions.filter(p => p.section.name == 'students')[0]:false;
        var res = permission && true;
        abilities_to_check.map(ability_to_check => {
            res = res && permission.abilities.filter(ab => ab.name == ability_to_check).length > 0;
        });
        if(no_more_abilities){
            res = res && abilities_to_check.length == permission.abilities.length;
        }
        if(permission && !res){
            var res2 = permission.abilities.length < abilities_to_check.length;
            if(res2){
                permission.abilities.map(ab => {
                    res2 = res2 && abilities_to_check.filter(ability_to_check => ab.name == ability_to_check).length > 0;
                });
            }
            res = res2;
        }
        if(permission && permission.abilities.length == 1 && permission.abilities.filter(ab => ab.name == "view").length > 0){
            res = false;
        }
        return res;
    }
    
    has_ability = (section_name, aminity_name) => {
        var permission = this.hasSection(section_name)?this.permissions.filter(p => p.section.name == section_name)[0]:false;
        var res = permission && true;
        res = res && permission.abilities.filter(ab => ab.name == aminity_name).length > 0;
        return res;
    }
}