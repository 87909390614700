import React from 'react';
import styled from "styled-components";
import MediaQuery from "react-responsive";
import LdsEllipses from 'components/LdsEllipses';

export default () => {
    return(
        <div className="d-flex flex-column justify-content-center align-items-center" style={{height:'100vh'}}>    
            <img src={global.logo} alt="Logo" style={{width:'200px', marginBottom:20}}/>
            <LdsEllipses/>
            <strong style={{marginTop:20, color: '#991515'}}>En cours de chargement</strong>
        </div>
    )
}