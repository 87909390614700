import React from "react"
import styled from "styled-components"

import CTA from "../assets/img/cta.svg"
import CTA_laraki from "../assets/img/cta_laraki.svg"

export default ({onPress, children, disabled}) => (
    <Button type="submit" onClick={onPress} disabled={disabled}
        style={{background: `url(${global.is_laraki_logo?CTA_laraki:CTA}) no-repeat scroll transparent`}}>
        {children}
    </Button>
)

const Button = styled.button`
    background-size: contain!important;
      font-family: "Montserrat", sans-serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.7px;
      color: rgba(255, 255, 255, 0.9);
      width: 130px;
      padding: 8px 0 57px;
      background-size: cover;
      margin: 20px 0 0;
      border: none;   
      
      @media screen and (max-width: 1199px){
         width: 100px;
         padding: 4px 0 41px;
      } 
`