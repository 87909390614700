import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../../../../assets/variables/colors";
import { images } from "../_resources";
import ModalSigninDesktop from "./ModalSigninDesktop";
import ModalRegisterDesktop from "./ModalRegisterDesktop";
import ModalSignInRegisterMobile from "./ModalSignInRegisterMobile";
import ModalConnexion from "../../../professor/home/components/ModalConnexion";
// import ModalRegister from "../../../professor/home/components/ModalRegister";
import ModalRegisterGlobal from "components/ModalRegisterGlobal";
import ModalSchoolRegister from "../../../school/components/ModalSchoolRegister";
import ModalSignInMobile from "../../../professor/home/components/ModalSignInMobile";
import ModalSchoolMobile from "../../../school/components/ModalSchoolMobile";
import ModalParentMobile from "../../../parents/components/ModalParentMobile";
// import ModalParentRegister from "../../../parents/components/ModalParentRegister";
import ModalPasswordChanged from "../../../professor/home/components/ModalPasswordChanged";
import ModalTermsAndCondition from "../../../professor/home/components/ModalTermsAndCondition";
import ModalCompteSuccess from "./ModalCompteSuccess";
import { get, set } from "services/storage";
import { withTranslation, Trans } from 'react-i18next';
import FonctionalityNotExistModal from "../../../parents/dashboard/components/FonctionalityNotExistModal";

class HeaderClass extends Component {
  constructor(props){
    super(props);
    this.is_sanadi_logo = window.location.hostname == "sanadidev.koolskools.com";
    this.is_laraki_logo = window.location.hostname == "preprod.koolskools.com";
    this.state = {
      open: (this.props.openConnexion || (this.props.hash && this.props.hash==="#klogin")) && this.props.pages === "children",
      hash:this.props.hash && this.props.hash,
      register: false,
      registerChild: false,
      openFonctionalityNotExistModal: false,
      openProfessor: (this.props.openConnexion || (this.props.location.state &&
        this.props.location.state.signin_mode))&& ["professor", "school", "parent"].indexOf(this.props.pages) >= 0,
      openModalTerms:false,
      openSuccessRegister:false,
      messageSuccess: this.props.pages === "children" ? "" : this.props.t("An email has been sent to your address to activate your account"),
      with_phone: false,
      phone_number: "",
      // openRegisterProfessor:
      //   (this.props.openRegister ||
      //   (this.props.location.state &&
      //   this.props.location.state.register_mode)) &&
      //   this.props.pages === "professor"
      //     ? true
      //     : false,
      openRegisterSchool: this.props.openRegister && this.props.pages === "school",
      // openRegisterParent: (this.props.openRegister && this.props.pages === "parent") || !!this.props.email,
      openRegister: props.openRegister && this.props.pages !== "school",
      mobile: false,
      popupSignin: {
        popup: (this.props.openConnexion || (this.props.hash && this.props.hash==="#klogin")) && this.props.pages === "children",
        signin: true
      },
      openParentMobile: {
        popup:
          (this.props.openRegister && this.props.pages === "parent") ||
          !!this.props.email ||
          (!!this.props.username && this.props.pages === "parent") ||
          false,
        signin:
          !!this.props.username && this.props.pages === "parent" ? true : false
      },
      openProfessorMobile: {
        popup:
          (this.props.openRegister || 
          ((this.props.openConnexion || !!this.props.username ||
          (this.props.location.state &&
          this.props.location.state.register_mode))) &&
          this.props.pages === "professor")
            ? true
            : false,
        signin: (this.props.openConnexion || !!this.props.username) &&
            this.props.pages === "professor" ? true : false
      },
      openSchoolMobile: {
        popup: this.props.openRegister && this.props.pages === "school",
        signin:
          !!this.props.username && this.props.pages === "school" ? true : false
      },
      default_username: this.props.username || "",
      account_activated: this.props.account_activated,
      default_email: this.props.email,
      key_inv: this.props.key_inv
    };
  }

  componentWillReceiveProps(newProps){
    this.setState({
      open:(newProps.openConnexion || (newProps.hash && newProps.hash==="#klogin")) && newProps.pages === "children",
      hash:newProps.hash && newProps.hash,
      openProfessor: (newProps.openConnexion || (newProps.location.state &&
        newProps.location.state.signin_mode)) && ["professor", "school", "parent"].indexOf(newProps.pages) >= 0,
      popupSignin: {
        popup: (newProps.openConnexion || (newProps.hash && newProps.hash==="#klogin"))  && newProps.pages === "children",
        signin: true
      },
      openProfessorMobile: {
        popup:
          (newProps.openRegister || 
          ((newProps.openConnexion || !!newProps.username ||
          (newProps.location.state &&
          newProps.location.state.register_mode)))) &&
          newProps.pages === "professor"
            ? true
            : false,
        signin: (newProps.openConnexion || !!newProps.username) &&
        newProps.pages === "professor" ? true : false
      },
      openSchoolMobile: {
        popup: (newProps.openConnexion || newProps.openRegister) && newProps.pages === "school",
        signin: (newProps.openConnexion || !!newProps.username)
          && newProps.pages === "school" ? true : false
      },
      openParentMobile: {
        popup:
          (newProps.openRegister && newProps.pages === "parent") ||
          !!newProps.email ||
          ((!!newProps.username || newProps.openConnexion) && newProps.pages === "parent") ||
          false,
        signin: (!!newProps.username || newProps.openConnexion) &&
        newProps.pages === "parent" ? true : false
      },
      // openRegisterProfessor:
      //   (newProps.openRegister ||
      //   (newProps.location.state &&
      //     newProps.location.state.register_mode)) &&
      //     newProps.pages === "professor"
      //     ? true
      //     : false,
      openRegisterSchool: newProps.openRegister && newProps.pages === "school",
      // openRegisterParent: (newProps.openRegister && newProps.pages === "parent") || !!newProps.email,
      openRegister: newProps.openRegister && newProps.pages !== "school",
      reset_password_response: newProps.reset_password_response,
      reset_password_error: newProps.reset_password_error,
    })
  }

  handleOpenRegisterProfessor = () => {
    this.setState({ openRegisterProfessor: true });
    this.props.handleOpenRegister();
  };

  handleCloseRegisterProfessor = () => {
    this.setState({ openRegisterProfessor: false });
    this.props.handleCloseRegister();
  };

  // for school
  handleOpenRegisterSchool = () => {
    this.setState({ openRegisterSchool: true });
    this.props.handleOpenRegister();
  };

  handleCloseRegisterSchool = () => {
    this.setState({ openRegisterSchool: false });
    this.props.handleCloseRegister();
  };

  handleOpenRegisterParent = () => {
    this.setState({ openRegisterParent: true });
    this.props.handleOpenRegister();
  };

  handleCloseRegisterParent = () => {
    this.setState({ openRegisterParent: false });
    this.props.handleCloseRegister();
  };

  onCloseModalRegisterChild = () => {
    this.setState({ registerChild: false, openFonctionalityNotExistModal: false });
  };

  onOpenModalRegisterChild = () => {
    // this.setState({ registerChild: false, openFonctionalityNotExistModal: true  });
    this.setState({ openRegister:true  });
  };

  onOpenModal = () => {
    this.setState({ open: true });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.props.handleCloseConnexion();
  };

  handleOpenSigninMobile = () => {
    this.setState({
      popupSignin: {
        popup: true,
        signin: true
      },
      mobile: false
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleOpenRegisterMobile = () => {
    this.setState({
      popupSignin: {
        popup: true,
        signin: false
      },
      mobile: false
    });
  };

  handleCloseRegisterMobile = () => {
    this.setState({
      popupSignin: {
        popup: false,
        signin: true
      }
    });
    this.props.handleCloseConnexion();
  };

  handleOpenCnxConnexion = () => {
    this.setState({
      openProfessor: true
    });
  };

  handleCloseCnxConnexion = () => {
    this.setState({
      openProfessor: false,
      default_username: "",
      account_activated: null,
      default_email: "",
      key_inv: ""
    });
    this.props.handleCloseConnexion();
  };

  handleOpenSignupProfessorMobile = () => {
    this.setState({
      openProfessorMobile: {
        popup: true,
        signin: false
      },
      mobile: false
    });
    this.props.handleOpenRegister();
  };

  handleOpenSigninProfessorMobile = () => {
    this.setState({
      openProfessorMobile: {
        popup: true,
        signin: true
      },
      mobile: false
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleCloseProfessorMobile = () => {
    this.setState({
      openProfessorMobile: {
        popup: false,
        signin: false
      },
      default_username: "",
      account_activated: null,
      default_email: "",
      key_inv: ""
    });
    this.props.handleCloseConnexion();
  };

  handleOpenSignupSchoolMobile = () => {
    this.setState({
      openSchoolMobile: {
        popup: true,
        signin: false
      },
      mobile: false
    });
    this.props.handleOpenRegister();
  };

  handleOpenSigninSchoolMobile = () => {
    this.setState({
      openSchoolMobile: {
        popup: true,
        signin: true
      },
      mobile: false
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleCloseSchoolMobile = () => {
    this.setState({
      openSchoolMobile: {
        popup: false,
        signin: false
      }
    });
    this.props.handleCloseConnexion();
  };

  handleOpenSignupParentMobile = () => {
    this.setState({
      openParentMobile: {
        popup: true,
        signin: false
      },
      mobile: false
    });
    this.props.handleOpenRegister();
  };

  handleOpenSigninParentMobile = () => {
    this.setState({
      openParentMobile: {
        popup: true,
        signin: true
      },
      mobile: false
    });
    if(this.props.handleOpenConnexion){
      this.props.handleOpenConnexion();
    }
  };

  handleCloseParentMobile = () => {
    this.setState({
      openParentMobile: {
        popup: false,
        signin: false
      }
    });
    this.props.handleCloseConnexion();
  };
  changeLanguage = (new_language) => {
    set("current_language", new_language);
    this.props.i18n.changeLanguage(new_language);
  }

  render() {
    const {openRegister} = this.state;
    const current_language = get("current_language");
    return (
      <>
        <MediaQuery query="(max-width: 1199px)">
          {this.state.mobile && (
            <div className="navigation-mobile">
              <div className="container">
                <div className="header-mobile">
                  <div className="text-right">
                    <a
                      onClick={() => this.setState({ mobile: false })}
                      className="close-mobile"
                    >
                      x
                    </a>
                  </div>
                  <div className="logo">
                    <a href="">
                      {this.is_sanadi_logo?
                        "Sanadi"
                        :
                        this.is_laraki_logo?
                        "LARAKI"
                        :
                        <img src={images.logo} />
                      }
                    </a>
                  </div>
                </div>
                <div className="content-mobile">
                  <div className="center-mobile">
                    <div className="translate">
                      <a href="#" className="translate-bloc" onClick={() => this.changeLanguage("fr")}>
                        FR <img src={images.editIcon} />
                      </a>
                      <a href="#" className="translate-bloc" onClick={() => this.changeLanguage("en")}>
                        EN <img src={images.editIcon} />
                      </a>
                    </div>
                    <nav>
                      <ul>
                        <li>
                          <Link
                            to="/"
                            className={
                              this.props.pages === "children" ? "active" : ""
                            }
                          >
                            { this.props.t('Student') }
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/home/professor"
                            className={
                              this.props.pages === "professor" ? "active" : ""
                            }
                          >
                            { this.props.t('Professor') }
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/home/school"
                            className={
                              this.props.pages === "school" ? "active" : ""
                            }
                          >
                            { this.props.t('School') }
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/home/parent"
                            className={
                              this.props.pages === "parent" ? "active" : ""
                            }
                          >
                            { this.props.t('Parent') }
                          </Link>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                    <ul className="signin">
                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.props.pages === "children"
                              ? this.handleOpenSigninMobile()
                              : this.handleOpenSigninProfessorMobile()
                          }
                        >
                          <img src={images.iconConnexion} /> { this.props.t('Sign in') }
                        </a>
                      </li>

                      <li>
                        <a
                          href="#"
                          onClick={() =>
                            this.props.pages === "children"
                              ? this.handleOpenRegisterMobile()
                              : this.handleOpenSignupProfessorMobile()
                          }
                        >
                          {" "}
                          { this.props.t('Sign up') }
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </MediaQuery>

        <Header>
          <div className="container">
            <div className="row">
              <div className="col-sm-2 col-8 logo">
                <img src={images.logo} className="img-fluid" />
              </div>
              <div className="col-sm-6 d-none d-sm-block navigation" style={{zIndex: 1}}>
                <MediaQuery query="(min-width: 1200px)">
                  <div className="lang">
                    <div className="btn-group">
                      <button type="button" className="button-first">
                      {current_language === "fr" ? "FR" : "EN"}
                      </button>
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="caret" />
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="#" onClick={() => this.changeLanguage("fr")}>FR</a>
                        </li>
                        <li>
                          <a href="#" onClick={() => this.changeLanguage("en")}>EN</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <nav>
                    <ul>
                      <li>
                        <Link
                          to="/"
                          className={
                            this.props.pages === "children" ? "active" : ""
                          }
                        >
                          { this.props.t('Student') }
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/home/professor"
                          className={
                            this.props.pages === "professor" ? "active" : ""
                          }
                        >
                          { this.props.t('Professor') }
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/home/school"
                          className={
                            this.props.pages === "school" ? "active" : ""
                          }
                        >
                          { this.props.t('School') }
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/home/parent"
                          className={
                            this.props.pages === "parent" ? "active" : ""
                          }
                        >
                          { this.props.t('Parent') }
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </MediaQuery>
              </div>
              <div className="col-sm-4 col-4">
                <MediaQuery query="(min-width: 1200px)">
                  <ul className="signin">
                    <li onClick={() =>
                          this.props.pages === "children"
                            ? this.onOpenModal()
                            : this.handleOpenCnxConnexion()
                        }
                    >
                      <a>
                        <img src={images.iconConnexion} />
                        <br />
                        { this.props.t('Sign in') }
                      </a>
                    </li>
                    <li onClick={() =>{
                          if(this.props.pages === "professor"){
                            this.handleOpenRegisterProfessor();
                          }
                          else if(this.props.pages === "school"){
                            this.handleOpenRegisterSchool();
                          }
                          else if(this.props.pages === "parent"){
                            this.handleOpenRegisterParent();
                          }
                          else if(this.props.pages === "children"){
                            this.onOpenModalRegisterChild();
                          }
                          else{
                            this.onOpenModal();
                          }
                        }}
                    >
                      <a>
                        <img src={images.iconConnexion} />
                        <br />
                        { this.props.t('Sign up') }
                      </a>
                    </li>
                  </ul>
                </MediaQuery>

                <MediaQuery query="(max-width: 1199px)">
                  <div className="header-right">
                    <ul className="d-flex justify-content-between">
                      <li onClick={() =>
                            this.props.pages === "professor"
                              ? this.handleOpenSigninProfessorMobile()
                              : this.props.pages === "school"
                              ? this.handleOpenSigninSchoolMobile()
                              : this.props.pages === "parent"
                              ? this.handleOpenSigninParentMobile()
                              : this.handleOpenSigninMobile()
                          }
                      >
                        <a>
                          <img src={images.iconUser} className="img-fluid" />{" "}
                        </a>{" "}
                      </li>
                      <li>
                        <a onClick={() => this.setState({ mobile: true })}>
                          <img src={images.iconGroup} className="img-fluid" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                </MediaQuery>
              </div>
            </div>
          </div>
        </Header>

      <MediaQuery query="(min-width: 1200px)">
        <ModalSigninDesktop
          open={this.state.open}
          onCloseModal={this.onCloseModal}
          onOpenModal={this.onOpenModal}
        />
        <ModalRegisterDesktop
          register={this.state.registerChild}
          onCloseModalRegister={this.onCloseModalRegisterChild}
        />
        <FonctionalityNotExistModal
          open={this.state.openFonctionalityNotExistModal}
          handleCloseModal={this.onCloseModalRegisterChild}
          addedMessage={this.props.t("Veuillez demander vos identifiants au niveau de votre école")+"."}
        />


      {(!!this.state.default_username || this.state.openProfessor) &&
        <ModalConnexion
          pages={this.props.pages}
          username={this.state.default_username}
          account_activated={this.state.account_activated}
          key_inv={this.state.key_inv}
          openProfessor={
          !!this.state.default_username || this.state.openProfessor
          }
          handleCloseCnxConnexion={this.handleCloseCnxConnexion}
        />}
          {/* <ModalRegister
            openRegisterProfessor={this.state.openRegisterProfessor}
            handleOpenTermsAndCondition={() => {
              this.setState({openModalTerms:true})
            }}
            handleOpenSuccess={(message, with_phone, phone_number) => this.setState({
              openSuccessRegister:true,
              messageSuccess:message || this.state.messageSuccess,
              with_phone: with_phone,
              phone_number: phone_number
            })}
            schoolProfessorKey={
            (this.props.location.state && this.props.location.state.key)
             ? this.props.location.state.key
              : false
               }
            professor={
                (this.props.location.state && this.props.location.state.professor)
                ? this.props.location.state.professor
                 : false
                }
            handleCloseRegisterProfessor={this.handleCloseRegisterProfessor}
            handleOpenModalConnexion={this.handleOpenCnxConnexion}
          /> */}
          <ModalSchoolRegister
            openRegisterSchool={this.state.openRegisterSchool}
            handleCloseRegisterSchool={this.handleCloseRegisterSchool}
          />
          {/* <ModalParentRegister
            openRegisterParent={
            !!this.state.default_email || this.state.openRegisterParent}
            handleCloseRegisterParent={this.handleCloseRegisterParent}
            email={this.state.default_email}
            key_inv={this.state.key_inv}
          /> */}
          {openRegister &&
          <ModalRegisterGlobal
            open={openRegister}
            pages={this.props.pages}
            handleCloseRegister={() => this.setState({openRegister:false})}
            handleOpenTermsAndCondition={() => {
              this.setState({openModalTerms:true})
            }}
            handleOpenSuccess={(message) => this.setState({
              openSuccessRegister:true,
              messageSuccess:message
            })}
          />
          }

      </MediaQuery>
      <MediaQuery query="(max-width: 1199.98px)">

          <ModalSignInRegisterMobile
            popupSignin={this.state.popupSignin}
            username={this.state.default_username}
            account_activated={this.state.account_activated}
            handleOpenSigninMobile={this.handleOpenSigninMobile}
            handleOpenRegisterMobile={this.handleOpenRegisterMobile}
            handleCloseRegisterMobile={this.handleCloseRegisterMobile}
          />

          <ModalSchoolMobile
            openSchoolMobile={ this.state.openSchoolMobile}
            username={this.state.default_username}
            account_activated={this.state.account_activated}
            handleOpenSignupSchoolMobile={this.handleOpenSignupSchoolMobile}
            handleOpenSigninSchoolMobile={this.handleOpenSigninSchoolMobile}
            handleCloseSchoolMobile={this.handleCloseSchoolMobile}
          />

          <ModalParentMobile
            openParentMobile={ this.state.openParentMobile}
            username={this.state.default_username}
            email={this.state.default_email}
            key_inv={this.state.key_inv}
            account_activated={this.state.account_activated}
            handleOpenSignupParentMobile={this.handleOpenSignupParentMobile}
            handleOpenSigninParentMobile={this.handleOpenSigninParentMobile}
            handleCloseParentMobile={this.handleCloseParentMobile}
            handleOpenTermsAndCondition={() => {
              this.setState({openModalTerms:true})
            }}
            handleOpenSuccess={(message) => this.setState({
              openSuccessRegister:true,
              messageSuccess:message
            })}
          />
        {(this.state.openProfessorMobile && this.state.openProfessorMobile.popup) &&
          <ModalSignInMobile
            username={this.state.default_username}
            account_activated={this.state.account_activated}
            openProfessorMobile={this.state.openProfessorMobile}
            handleOpenSignupProfessorMobile={this.handleOpenSignupProfessorMobile}
            handleOpenSigninProfessorMobile={this.handleOpenSigninProfessorMobile}
            handleOpenSuccess={(message, with_phone, phone_number) => this.setState({
              openSuccessRegister:true,
              messageSuccess:message || this.state.messageSuccess,
              with_phone: with_phone,
              phone_number: phone_number
            })}
            handleOpenTermsAndCondition={() => {
              this.setState({openModalTerms:true})
            }}
            handleCloseProfessorMobile={this.handleCloseProfessorMobile}
            schoolProfessorKey={
              (this.props.location.state && this.props.location.state.key)
               ? this.props.location.state.key
                : false
                 }
            professor={
              (this.props.location.state && this.props.location.state.professor)
              ? this.props.location.state.professor
               : false
              }
          />}
      </MediaQuery>

        <ModalPasswordChanged
          success={
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.password_changed
          }
          reset_password_response={this.state.reset_password_response}
          reset_password_error={this.state.reset_password_error}
          handleClose={() => {
            console.log(this.state)
            this.setState({reset_password_response: "", reset_password_error: ""});
            const state = { ...this.props.history.location.state };
            delete state.password_changed;
            this.props.history.replace({
              ...this.props.history.location,
              state
            });
          }}
        />
        {this.state.openSuccessRegister &&
          <ModalCompteSuccess success={this.state.openSuccessRegister}
              handleCloseSuccess={() => this.setState({
                                        openSuccessRegister:false,
                                        messageSuccess:this.props.pages === "children" ? "" : this.props.t("An email has been sent to your address to activate your account")
                                      })}
              with_phone={this.state.with_phone}
              phone_number={this.state.phone_number}
              message={this.state.messageSuccess}/>
        }



        <ModalTermsAndCondition
          success={this.state.openModalTerms}
          handleClose={() => this.setState({openModalTerms:false})}
        />
      </>
    );
  }
}

const Header = styled.header`
  width: 100%;
  float: left;
  background: ${colors.primary};
  padding: 10px 0 13px;
  overflow-x: hidden;
  .navigation {
    position: relative;
    text-align: center;
    nav {
      opacity: 0.9;
      border-radius: 17.5px;
      background-color: ${colors.secondary};
      display: inline-block;
      padding: 2px 3px;
      margin-top: 20px;
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline-flex;
          margin-left: 30px;
          &:first-child {
            margin-left: 0;
          }
          a {
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            color: rgba(255, 255, 255, 0.54);
            border-radius: 30px;
            padding: 5px 10px;
            border: solid 2px transparent;
            cursor: pointer;

            &.active {
              background: ${colors.primary};
              border: 2px solid ${colors.white};
              font-weight: 600;
              color: ${colors.white};
            }
            &:hover,
            &:focus {
              font-weight: 600;
              background: transparent;
            }
          }
        }
      }
    }
    .lang {
      position: absolute;
      right: -50px;
      top: -10px;
      .btn-group {
        > button {
          border-radius: 0;
          border: none;
          color: ${colors.white};
          font-family: "Montserrat", sans-serif;
          &.button-first {
            opacity: 0.9;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: ${colors.white};
            background: ${colors.secondary};
            border: none;
            padding: 16px 12px 14px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            font-family: "Montserrat", sans-serif;
            color: hsla(0, 0%, 100%, 0.9);
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 1.3px;
            text-transform: uppercase;
          }
          &.dropdown-toggle {
            background: transparent;
            padding: 0 3px;
          }
        }
        .caret {
          margin-left: 0;
        }
      }
    }
  }
  .signin {
    text-align: right;
    margin: 15px 0 0;
    display:flex;
    justify-content:flex-end;
    li {
      display: inline-block;
      text-align: center;
      a {
        opacity: 0.9;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2.5px;
        text-align: right;
        color: ${colors.white};
        cursor: pointer;
      }
      &:nth-child(2) {
        margin-left: 15px;
        padding-left: 15px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: -3px;
          width: 4px;
          top: 3px;
          bottom: 3px;
          background: #3eb6ac;
          border-radius: 5px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .signin {
      text-align: center;
      margin: 15px auto 0;
    }
  }
  @media (max-width: 767px) {
    .col-0 {
      width: 0;
    }
  }
`;

export default withTranslation('translations')(HeaderClass);