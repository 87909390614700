import React, { Component} from "react";
import { withRouter, Redirect} from "react-router-dom";
import { clear, set, get } from "services/storage";
import { pilotage_permissions_api_list, pilotage_responsables_list, schools_scholar_years,
  get_list_schools_sub_groups, 
  school_professors_list_api, list_semesters_api, check_school_api, log_js_error,
  get_koolskools_levels, school_settings_payment_api_get, transport_api_get, device_token_api, 
  school_mailings_api_list, school_mailings_api_store, absencesApi, absences_post_api, behaviours_list_api, behaviours_list_api_post, reports_list_api, reports_list_api_post} from "services/cms/api";
import {levelGroups} from "services/api"
import axios from "axios";
import moment from "moment";
import {messaging} from 'init_fcm'
import localforage from 'localforage';


const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
const base_url = process.env.REACT_APP_URL_CMS;
class LayoutPilotageSystem extends Component {
  constructor(props) {
    super(props);
    this.user = get("session_user");
    this.ws = false
    Array.prototype.unique = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i].username === a[j].username)
                  a.splice(j--, 1);
          }
      }
      return a;
    }; 
    Array.prototype.unique_item = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i] === a[j])
                  a.splice(j--, 1);
          }
      }
      return a;
    }; 
    
    //     this.ws = new WebSocket(
    //       `${process.env.REACT_APP_URL_MESSAGING}/ws/users/?username=${
    //           this.user.profile.username
    //       }${school_ws}${school_administrators_ws}`
    //     );
      

    if(!get("koolskools_levels") && !this.user.profile.is_formator){
      get_koolskools_levels()
    }

    if(!get("level_groups")){
      levelGroups()
    }

    this.state = {
      menuGlobal: false,
      data_loaded:false,
      semesters: [],
      school_id: props.match.params.school_id,
      scholar_year_id: props.match.params.scholar_year_id,
      scholar_years: [],
      pilotage_responsables: [],
      ws: this.ws,
      
    };

    if(!this.user || ['ceo','csm'].indexOf(this.user.account_type) < 0) 
      return <Redirect to="/pilotage" />;
    if(/([À-ú]|\s)/.test(this.user.profile.username)){
      var lev = get("level_groups");
      var current_language = get("current_language");
      clear();
      localforage.clear();
      if(global.ws_notification)
        global.ws_notification.close();
      set("level_groups", lev);
      set("current_language", current_language);
      return <Redirect to="/pilotage" />;
    }
  }

  componentWillReceiveProps(props){
    this.setState({
      school_id: props.match.params.school_id,
      scholar_year_id: props.match.params.scholar_year_id,
      classe_id: props.match.params.classe_id,
      activity_classe_id: props.match.params.activity_classe_id,
    })
  }

  componentDidCatch(error, info) {
    // console.log(error)
    // console.log(error.stack)
    // Display fallback UI
    this.props.history.push('/ErrorPage');
    // You can also log the error to an error reporting service
    var data={
      // error_msg: error.message,
      error_stack: error.stack,
      // error_info: info.componentStack
    }
    log_js_error(data);
  }

  componentDidMount(){
    
    pilotage_permissions_api_list({pilotage_user: true}).then(res => {
      // res.permissions.map(p => {
      // })
    });
    pilotage_responsables_list({}).then(res => {
      this.setState({
        pilotage_responsables: res.pilotage_responsables,
      });
    });
    if(false){
      var data = {
        action: "get_semesters",
        school_id: this.state.school_id
      };
      list_semesters_api(data).then(res => {
        this.setState({
          semesters: res.semesters
        })
      })
    }
    if(messaging && !get('device_token')){
      messaging
      .requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        set('device_token', token);
        this.refresh_token(token)
      })
    }
  }

  refresh_token = token => {
    var data = {
      device_token: token
    };
    device_token_api(data)
  }

  getScholarYears = () => {
    schools_scholar_years({
      scholar_years_only: true
    }).then(res => {
      var current_year = res.years.filter(y => y.id == this.state.scholar_year_id)[0],
        showExistantsButton = false;
      if(current_year && (current_year.next || (res.years.filter(y => y.previous).length > 0 && current_year.current)))
        showExistantsButton = true;
      
      
      this.setState({
        scholar_years: res.years,
        showExistantsButton: showExistantsButton
      });
    })
  }

  getNotifMail = () => {
    var data = {
      action: "get_notif_messages"
    }

    school_mailings_api_list(data).then(res => {
      this.setState({
        messages_notif_ids: res.messages_notif_ids
      })
    })
  }

  messagesViewed = () => {
    var data = {
      action: "set_notif_messages_viewed"
    }

    school_mailings_api_store(data)
  }

  handleSwitchMenuGlobal = () => {
    this.setState({
      menuGlobal: !this.state.menuGlobal
    })
  }

  render() {
    const { Layout, Component } = this.props;
    return (
      <Layout 
        handleSwitchMenuGlobal={this.handleSwitchMenuGlobal}
        menuGlobal={this.state.menuGlobal}
        ws={this.ws}
      >
        <Component
          {...this.props}
          // extra_props={this.state.extra_props}
          global_data={this.state}
          messagesViewed={() => {
            if(this.state.messages_notif_ids.length > 0){
              this.messagesViewed();
              this.setState({messages_notif_ids: []});
            }
          }}
          ws={this.ws}
        />
      </Layout>
    );
  }
};

export default withRouter(LayoutPilotageSystem);
