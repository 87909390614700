import * as storage from "../storage";
import {
  LOGOUT_SUCCESS,
  LOGIN,
  MODIFIER,
  CHANGEAIMAGEVATAR,
  LOGINPROF,
  LOGINWITHCLASSE,
  MODIFIERFIRSTNAME
} from "../actions";

import { set, get } from "services/storage";
const initialState = {
  user: storage.get("session_user"),
  authenticated: false
};
let user;

if (initialState.user) {
  initialState.authenticated = true;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      storage.remove("InfoProfile");
      storage.remove("taskId");
      if (!action.error) {
        user = {
          token: action.payload.access_token,
          firstName: action.payload.user.first_name? action.payload.user.first_name: action.payload.user.f_name,
          lastName: action.payload.user.last_name? action.payload.user.last_name: action.payload.user.l_name,
          image: (action.payload.user.image && action.payload.user.image.url) || action.payload.user.image_url,
          subjects:action.payload.user.current_level && action.payload.user.current_level.subjects,
          class_subjects: (action.payload.user.classes && action.payload.user.classes.length > 0)?action.payload.user.classes[0].level.subjects:[],
          classId: action.payload.user.classes && action.payload.user.classes[0] && action.payload.user.classes[0].classe_id,
          level: action.payload.user.level && action.payload.user.level,
          idLevel: action.payload.user.current_level && action.payload.user.current_level.level_id,
          classes: action.payload.user.classes /*&& action.payload.user.classes[0] && action.payload.user.classes[0].level.subjects*/,
          activities_classes : action.payload.user.activities_classes,
          taskID: action.payload.user.current_level && action.payload.user.current_level.level_id,
          subject: action.payload.user.classes && action.payload.user.classes && action.payload.user.classes[0] && action.payload.user.classes[0],
          classLevel: action.payload.user.classes && action.payload.user.classes[0] && action.payload.user.classes[0].level.level_id,
          account_type: action.payload.user.account_type,
          account_type_2: action.payload.user.account_type_2,
          is_escort: action.payload.user.is_escort,
          is_tarl_professor: action.payload.user.is_tarl_professor,
          is_sanady_student: action.payload.user.is_sanady_student,
          is_professor: action.payload.user.is_professor,
          kne_cas_ticket: action.payload.kne_cas_ticket,
          edulib_cas_ticket: action.payload.edulib_cas_ticket,
          permissions: action.payload.user.permissions || [],
          nombre_invitation: action.payload.user.nombre_invitation || 0,
          nombre_zoom_not_seen :action.payload.user.nombre_zoom_not_seen || 0,
          is_sanadi_monitor :action.payload.user.is_sanadi_monitor || false,
          is_sanadi_coordinator :action.payload.user.is_sanadi_coordinator || false,
          profile: {
            firstName: action.payload.user.first_name ? action.payload.user.first_name : action.payload.user.f_name,
            lastName: action.payload.user.last_name ? action.payload.user.last_name : action.payload.user.l_name,
            username: action.payload.user.username,
            sanady_professor_info: action.payload.sanady_professor_info,
            had_sanady_school_from_last_year: action.payload.had_sanady_school_from_last_year,
            have_sanady_school_this_year: action.payload.have_sanady_school_this_year,
            user_id: action.payload.user.user_id,
            level: action.payload.user.level && action.payload.user.level,
            birthday: action.payload.user.birthday,
            gender: action.payload.user.gender,
            address: action.payload.user.address,
            email: action.payload.user.email,
            score: action.payload.user.score || 0,
            image: action.payload.user.image && action.payload.user.image.url,
            phone_number: action.payload.user.phone_number,
            is_csm_manager: action.payload.user.is_csm_manager,
            is_professeur: action.payload.user.is_professeur,
            is_escort: action.payload.user.is_escort,
            is_tarl_professor: action.payload.user.is_tarl_professor,
            is_sanady_student: action.payload.user.is_sanady_student,
            is_sanadi_coordinator :action.payload.user.is_sanadi_coordinator || false,
            can_add_schools :action.payload.user.can_add_schools || false,
            is_professor: action.payload.user.is_professor,
            is_assistant: action.payload.user.is_assistant,
            schools_count: action.payload.user.schools_count,
            is_formator: action.payload.user.is_formator,
            is_tuteur: action.payload.user.is_tuteur,
            is_school_admin: action.payload.user.is_school_admin,
            is_program_system: action.payload.user.is_program_system,
            is_sanady_assistant: action.payload.user.is_sanady_assistant,
            schools: action.payload.user.schools,
            admin_schools: action.payload.user.admin_schools,
            ass_ad_schools: action.payload.user.ass_ad_schools || [],
            admin_sms_copy_permissions: action.payload.user.admin_sms_copy_permissions,
            country: action.payload.user.country,
            city: action.payload.user.city,
            password_is_forgotten: action.payload.user.password_is_forgotten,
            success: action.payload.user.success,
            scope: action.payload.user.scope
          },
          current_level: action.payload.user.current_level,
          has_children: action.payload.has_children,
          schoolsName: action.payload.user.schools && action.payload.user.schools[0] && action.payload.user.schools[0].name
        };
        var levels_groups = action.payload.user.levels_groups || [];
        var all_levels = action.payload.user.all_levels || [];
        if(all_levels.length){
          set("all_levels", all_levels);
        }
        if(levels_groups.length){
          set("level_groups", levels_groups);
        }
        if(action.payload.professor_schools){
          storage.set("professor_schools", action.payload.professor_schools);
        }
        if(action.payload.year){
          storage.set("current_year", action.payload.year);
        }
        {/*if(action.payload.professor_subjects){
          var levels = storage.get("levels") || [];
          levels = levels.map(level =>{
            var sds = [];
            level.subjects_details = level.subjects_details.map(subjectD => {
              subjectD.subject = level.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0];
              if(level.subjects.some(sb => (action.payload.professor_subjects || []).indexOf(sb.subject_id))){
                sds.push(subjectD);
              }
              return subjectD;
            });
            level.subjects_details = sds;
            return level;
          });
          storage.set("levels", levels || []);
        }*/}
        storage.set("professor_subjects", action.payload.professor_subjects || []);
        storage.set("assistant_schools_subjects_permissions", action.payload.assistant_schools_subjects_permissions || []);
        storage.set("professor_levels", action.payload.professor_levels || []);
        storage.set("professor_levels_groups", action.payload.professor_levels_groups || []);
        storage.set("session_user", user);
        window.not_viewed_courses_count = undefined;
        window.not_viewed_live_count =  undefined;

        // const expirationDate = new Date(new Date().getTime() + 60 * 40 * 1000);
        // storage.set("expirationDate", expirationDate.getTime());
        return { user, authenticated: true };
      }

    case LOGINPROF:
      if (!action.error) {
        user = {
          token: action.payload.access_token,
          firstName: action.payload.LOGINuser.first_name,
          lastName: action.payload.user.last_name,
          image: action.payload.user.image_url,
          account_type: action.payload.user.account_type,
          profile: {
            firstName: action.payload.user.first_name,
            lastName: action.payload.user.last_name,
            username: action.payload.user.username,
            email: action.payload.user.email,
            phone_number: action.payload.user.phone_number,
            birthday: action.payload.user.birthday,
            is_professeur: action.payload.user.is_professeur,
            is_school_admin: action.payload.user.is_school_admin,
            is_formator: action.payload.user.is_formator,
            is_tuteur: action.payload.user.is_tuteur,
            schools: action.payload.user.schools,
            gender: action.payload.user.gender,
            country: action.payload.user.country,
            city: action.payload.user.city,
            password_is_forgotten: action.payload.user.password_is_forgotten,
            success: action.payload.user.success,
            scope: action.payload.user.scope
          },
          has_children: action.payload.has_children
        };
        storage.set("session_user", user);
        return { user, authenticated: true };
      }
    case LOGOUT_SUCCESS:
      storage.remove("session_user");
      storage.remove("InfoProfile");
      return { authenticated: false, user: null };
    case MODIFIER:
      //storage.remove("session_user");
      user = { ...state.user, classLevel: action.classLevel };
      return {
        user,
        authenticated: true
      };

    default:
      return state;
  }
};
