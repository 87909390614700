import React, { Component } from "react"
import Modal from 'react-responsive-modal'
import {images} from "../_resources";
import { withTranslation, Trans } from 'react-i18next';
import axios from "axios";
const instance = axios.create({ baseURL: process.env.REACT_APP_URL_WS });

class ModalCompteSuccess extends Component {
    constructor(props){
        super(props);
        this.state = {
            activation_code_error: "",
            phone_activated: false,
            message: ""
        };
    }
    handleCloseSuccess = () => {
        this.props.handleCloseSuccess()
    }
    activate_account = () => {
        if(!this.activation_code.value){
            this.setState({activation_code_error: this.props.t("Activation code is required")+"!"})
        }
        else if(!/^-{0,1}\d+$/.test(this.activation_code.value)){
            this.setState({activation_code_error: this.props.t("Activation code must be a number")+"!"})
        }
        else{
            instance.post(`/api/koolskools/v1/account/activate_phone/`, {
                sms_activation_key: this.activation_code.value,
                phone_number: this.props.phone_number
            }).then(response => {
                if(response.data.success){
                    this.setState({phone_activated: true, activation_code_error: "", message: response.data.message})
                }
                else{
                    this.setState({phone_activated: false, activation_code_error: response.data.msg})
                }
            })
            .catch(err => console.log("error", err));
        }
    }
    render(){
        return(
            <Modal open={this.props.success}
                onClose={this.handleCloseSuccess}
                showCloseIcon={false}
                center
                classNames={{
                    overlay: {dialogOverlay: "white"},
                    modal: {dialog: "white", dialogSuccess: "white"},
                }}>
                <div className="dialogSucce">
                    <a className="closeModal" onClick={() => this.handleCloseSuccess()}><img src={images.iconClose} /></a>
                    {this.state.phone_activated ?
                        <div>
                            <h5>{this.state.message}</h5>
                        </div>
                        :
                        <div>
                            <h5>{this.props.message || `${(this.props.with_phone || this.props.type === "sms") ? this.props.t("An SMS has been sent to you activate your account")+"." : this.props.t("An Email has been sent to you activate your account")+"."}`}</h5>
                            {(this.props.type === "sms" || this.props.with_phone) &&
                                <div className="code_input">
                                    <h5>{this.props.t("Activation Code")}</h5>
                                    <div className="form-control">
                                        <input type="text" ref={activation_code => (this.activation_code = activation_code)}/>
                                    </div>
                                    {this.state.activation_code_error &&
                                        <div className="textErrors">{this.state.activation_code_error}</div>
                                    }
                                    <div className="form-control submit">
                                        <button className="activate_phone" onClick={this.activate_account}>{this.props.t("Activate")}</button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </Modal>
        )
    }
}
export default withTranslation('translations')(ModalCompteSuccess);
