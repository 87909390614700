import React, { Component } from "react";
import MediaQuery from "react-responsive";
export default class extends Component {
  render() {
    return (
      <>
        <MediaQuery query="(min-width: 1200px)">
          <div
            className="before-one"
            style={{
              borderLeft: `${this.props.clientWidth}px solid #80c9cb`,
              borderBottom: `${this.props.clientHeight - 80}px solid #fff`
            }}
          />
        </MediaQuery>

        <MediaQuery query="(max-width: 1199px)">
          <div
            className="before-one"
            style={{
              borderLeft: `${this.props.clientWidth + 300}px solid #80c9cb`,
              borderBottom: `${this.props.heightMobile}px solid #fff`
            }}
          />
        </MediaQuery>

        {/*<MediaQuery query="(max-width: 767px)">
          <div
            className="before-one"
            style={{
              borderLeft: `${this.props.clientWidth + 900}px solid #80c9cb`,
              borderBottom: `${this.props.clientHeight - 250}px solid #fff`
            }}
          />
        </MediaQuery>*/}
      </>
    );
  }
}
