import React, { Component } from "react";
import Modal from "react-responsive-modal";

import purple from "@material-ui/core/colors/purple";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { register_global_api } from "services/api";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation, Trans } from 'react-i18next';
import RegisterFormComponent from './RegisterFormComponent'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: purple[500] }, // Purple and green play nicely together.
    secondary: { main: "#00b3a9" } // This is just green.A700 as hex.
  }
});

class ModalRegisterGlobal extends Component {

  render() {
    return (
      <>
      <Modal
        open={this.props.open}
        onClose={this.props.handleCloseRegister}
        showCloseIcon={false}
        center
        classNames={{
          overlay: { dialogOverlay: "white" },
          modal: {
            dialog: "white",
            dialogConnexion: "white",
            dialogRegister: "white"
          }
        }}
      >
        <div className="modalConnexion">
          <div className="modalConnexionTop">
            <h2>{ this.props.t('REGISTER') }</h2>
            <span
              className="schoolClose"
              onClick={this.props.handleCloseRegister}
            >
              &times;
            </span>
          </div>
          <RegisterFormComponent
            pages={this.props.pages}
            handleOpenSuccess={(msg) => {
              this.props.handleCloseRegister();
              this.props.handleOpenSuccess(msg);
            }}
            handleOpenTermsAndCondition={this.props.handleOpenTermsAndCondition}
            />
        </div>
      </Modal>
      </>
    );
  }
}

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(ModalRegisterGlobal)));
