import localforage from 'localforage';
import { get,set } from "services/storage";
import { check_group_version,  school_payment_api_store, school_students_store_api} from "services/cms/api";
import moment from 'moment';

const event = new CustomEvent("refresh_students");

export const init_database  = (schools, update) => {
    global.all_students = [];
    localforage.getItem('group_' + schools[0].group_id, (err, value) => {
      if(value){
        global.all_students = {...global.all_students, ...value}
        check_group_version({group_id: schools[0].group_id}).then(res => {
          let students_version  = get("students_version"),
            payments_version  = get("payments_version"),
            settings_version  = get("settings_version");  
          if(parseInt(students_version) < parseInt(res.students_version))
            refresh_group(schools, false);    
          else{
            if(parseInt(payments_version) < parseInt(res.payments_version))
              refresh_payment(false, Object.keys(global.all_students).join(','))
            if(parseInt(settings_version) < parseInt(res.settings_version))
              refresh_setting(false, false)
            else
              document.dispatchEvent(event);
          }
        })
      }
      else
        refresh_group(schools, false);
    })
    if(!update)
      init_ws_notification()
}

const refresh_group = (schools, students_ids) => {
  console.log("refresh students")
  let school = get('school'),
    scholar_year = get('current_year'),
    data = {
        action: 'get_natif_all_students',
        scholar_year_id: scholar_year.id,
        group_id: school?school.group_id:schools[0].group_id
    }
  if(students_ids){
    if(students_ids != 'all')
      data.users_ids = students_ids + "";
    data.schools_ids = schools + "";
  }
  else
    data.schools_ids = schools.map(sc => sc.id).join(',')
  school_students_store_api(data).then(res => {
    set("students_version", res.students_version);  
    let levels = get('levels'),
      all_levels = get('koolskools_levels') || [],
      all_students = res.students,
      data2 = {
        action: 'get_levels_transfer_month',
        users_ids: Object.keys(all_students).join(','),
        scholar_year_id: scholar_year.id,
      }
    if(Object.keys(all_students).length > 0){
      school_students_store_api(data2).then(async res => {
        res.students.forEach(row => {
          let std = all_students[row[0]];
          std.parent = false;
          std.parent2 = false;
          std.transfer_levels.push({
            level_id: row[1],
            school_id: row[2],
            month: row[3]
          });
          let level = levels.find(lvl => lvl.level_id == std.level_id);
          if(!level){
            level = all_levels.find(l => l.level_id == std.level_id);
          }
          if(level)
            std.level = {
              level_id: level.level_id,
              name: level.name,
              group_id: level.group_id,
              order: level.order,
              order_school: level.order_school,
            }
        })
        let data4 = {
          action: "get_recap_payments_students",
          users_ids: Object.keys(all_students).join(','),
          scholar_year_id: scholar_year.id,
          group_id: school.group_id
        }
        await school_payment_api_store(data4).then(res=> {
          var last_type = false, last_student = false, last_month = false;
          set("settings_version", res.settings_version);
          res.students2.forEach(row => {
            let std = all_students[row[0]];
            
            let gray = row[3] > 0 && ((!std.is_active && moment(std.date_depart) <= (row[3]>0?moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-01`):moment(scholar_year.start_year + '-08-01')))
                  ||  (std.date_entering && row[3] > 0 && moment(std.date_entering) > moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-15`).endOf('month')));

            if(!gray){

              let transfer_month = std.transfer_levels.find(tr => tr.month == row[3]);

              if(transfer_month && transfer_month.level_id == row[1] && (!row[6] || transfer_month.school_id == row[6])){

                if(last_student != row[0]){
                  last_student = row[0];
                  last_type = false;
                  last_month = false;
                }

                //types
                if(!last_type || last_type.type_id != row[2]){
                  let type = std.types.find(t => t.slug == row[5] || t.type_id == row[2])
                  if(type)
                    last_type = type
                  else {
                    std.types.push({
                      slug: row[5],
                      type_id: row[2],
                      school_id: row[6],
                      is_active: true,
                      months: []
                    })
                    last_type = std.types[std.types.length - 1]
                  }
                }
                let month = last_type.months.find(m => m.month == row[3])
                if(month){
                  month.total_price = row[4]
                  month.total_base = row[7]
                }
                else
                  last_type.months.push({
                    level: row[1],
                    month: row[3],
                    total_price: row[4],
                    total_base: row[7],
                    payed_price: 0,
                    is_active: true
                  })
                //months
                if(!last_month || last_month.month != row[3]){
                  let month2 = std.months.find(t => t.month == row[3])
                  if(month2)
                    last_month = month2
                  else {
                    std.months.push({
                      month: row[3],
                      types: []
                    })
                    last_month = std.months[std.months.length - 1]
                  }
                }
                let type2 = last_month.types.find(t => t.type_id == row[2] || t.slug == row[5])
                if(type2)
                  type2.to_pay = row[4]
                else
                  last_month.types.push({
                    type_id: row[2],
                    school_id: row[6],
                    slug: row[5],
                    to_pay: row[4],
                    payed: 0,
                    is_active: true
                  })
              }
            }
          })
        });


        let data3 = {
            action: "get_payment_students",
            users_ids: Object.keys(all_students).join(','),
            scholar_year_id: scholar_year.id,
            group_id: school.group_id
        }
        await school_payment_api_store(data3).then(res=> {
          if(!res.error){
            var last_type = false, last_student = false, last_month = false;
            set("payments_version", res.payments_version);
            res.students2.forEach(row => {
              let std = all_students[row[0]];

              let gray = row[3] > 0 &&  ((!std.is_active && moment(std.date_depart) <= (row[3]>0?moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-01`):moment(scholar_year.start_year + '-08-01')))
                  ||  (std.date_entering && row[3] > 0 && moment(std.date_entering) > moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-15`).endOf('month')));

              if(!gray){
                  if(last_student != row[0]){
                      last_student = row[0];
                      last_type = false;
                      last_month = false;
                  }
                  //types
                  if(!last_type || last_type.type_id != row[2]){
                  let type = std.types.find(t => t.slug == row[5] || t.type_id == row[2])
                  if(type)
                      last_type = type
                  else {
                      std.types.push({
                        slug: row[5],
                        type_id: row[2],
                        school_id: row[6],
                        is_active: false,
                        months: []
                      })
                      last_type = std.types[std.types.length - 1]
                  }
                  }
                  let month = last_type.months.find(m => m.month == row[3])
                  if(month)
                      month.payed_price += row[4]
                  else
                      last_type.months.push({
                          level: row[1],
                          month: row[3],
                          total_price: 0,
                          total_base: 0,
                          payed_price: row[4],
                          is_active: false
                      })

                  //months
                  if(!last_month || last_month.month != row[3]){
                      let month2 = std.months.find(t => t.month == row[3])
                      if(month2)
                          last_month = month2
                      else {
                          std.months.push({
                            month: row[3],
                            types: []
                          })
                          last_month = std.months[std.months.length - 1]
                      }
                  }
                  let type2 = last_month.types.find(t => t.type_id == row[2] || t.slug == row[5])
                  if(type2)
                      type2.payed += row[4]
                  else
                      last_month.types.push({
                          type_id: row[2],
                          school_id: row[6],
                          slug: row[5],
                          to_pay: 0,
                          payed: row[4],
                          is_active: false
                      })
              }
            })
          }
        });

        let data5 = {
          action: "get_students_parents",
          users_ids: Object.keys(all_students).join(','),
          // scholar_year_id: scholar_year.id,
          // group_id: school.group_id
        }

        await school_students_store_api(data5).then(res => {
          res.parents.forEach(row => {
            if(row[1] || row[7]){
              let std = all_students[row[0]];
              if(row[2])
                std.parent = {
                  id: row[1],
                  user_id: row[2],
                  name: row[3] + " " + row[4],
                  last_name: row[3],
                  first_name: row[4],
                  email: row[5],
                  phone_number: row[6]
                }
              if(row[8])
                std.parent2 = {
                  id: row[7],
                  user_id: row[8],
                  name: row[9] + " " + row[10],
                  last_name: row[9],
                  first_name: row[10],
                  email: row[11],
                  phone_number: row[12]
                }
            }
          })
        })

        if(students_ids){
          Object.keys(all_students).forEach(user_id => {
            global.all_students[user_id] = all_students[user_id];
          })
        }
        else
          global.all_students = all_students;
        document.dispatchEvent(event);
        localforage.setItem('group_' + school.group_id, global.all_students)
      })
    }
    else if(students_ids != "all"){
      if(students_ids){
        students_ids.split(',').forEach(user_id => {
          delete global.all_students[user_id];
        })
        document.dispatchEvent(event);
      }
      localforage.setItem('group_' + school.group_id, global.all_students)
    }
  })
}

const refresh_payment = async (payment_id, students_ids) => {
  console.log("refresh payments")
  let scholar_year = get('current_year'),
  school = get('school'),
  data3 = {
    action: "get_payment_students",
    users_ids: students_ids,
    scholar_year_id: scholar_year.id,
    group_id: school.group_id
  }
  if(payment_id)
    data3.payment_id = payment_id;

  await school_payment_api_store(data3).then(res=> {
    if(!res.error){
      var last_type = false, last_student = false, last_month = false;
      set("payments_version", res.payments_version);
      // console.log(payment_id)
      // console.log(students_ids)
      if(!payment_id){
        students_ids.split(',').forEach(std_id => {
          if(!payment_id && last_student != std_id){
            let std = global.all_students[std_id];
            std.types.forEach(t => {
              t.months.forEach(m => {
                m.payed_price = 0;
              }) 
            })
            std.months.forEach(m => {
              m.types.forEach(t => {
                t.payed = 0;
              }) 
            })
            last_student = std_id;
          }
        })
      }

      last_student = false;

      res.students2.sort((a, b) => a[0] - b[0]).forEach(row => {
          let std = global.all_students[row[0]];

          let gray = row[3] > 0 &&  ((!std.is_active && moment(std.date_depart) <= (row[3]>0?moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-01`):moment(scholar_year.start_year + '-08-01')))
              ||  (std.date_entering && row[3] > 0 && moment(std.date_entering) > moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-15`).endOf('month')));

          if(!gray){
              if(last_student != row[0]){
                  last_student = row[0];
                  last_type = false;
                  last_month = false;
              }
              //types
              if(!last_type || last_type.type_id != row[2]){
                let type = std.types.find(t => t.slug == row[5] || t.type_id == row[2])
                if(type)
                    last_type = type
                else {
                    std.types.push({
                      slug: row[5],
                      type_id: row[2],
                      school_id: row[6],
                      is_active: false,
                      months: []
                    })
                    last_type = std.types[std.types.length - 1]
                }
              }
              let month = last_type.months.find(m => m.month == row[3])
              if(month)
                  month.payed_price += row[4]
              else
                  last_type.months.push({
                      level: row[1],
                      month: row[3],
                      total_price: 0,
                      total_base: 0,
                      is_active: false,
                      payed_price: row[4]
                  })

              //months
              if(!last_month || last_month.month != row[3]){
                  let month2 = std.months.find(t => t.month == row[3])
                  if(month2)
                      last_month = month2
                  else {
                      std.months.push({
                        month: row[3],
                        types: []
                      })
                      last_month = std.months[std.months.length - 1]
                  }
              }
              let type2 = last_month.types.find(t => t.type_id == row[2] || t.slug == row[5])
              if(type2)
                  type2.payed += row[4]
              else
                  last_month.types.push({
                      type_id: row[2],
                      school_id: row[6],
                      slug: row[5],
                      to_pay: 0,
                      is_active: false,
                      payed: row[4]
                  })
          }
      })
    }
  });

  document.dispatchEvent(event);
  localforage.setItem('group_' + school.group_id, global.all_students)
}

const refresh_setting = async (students_ids, school_id) => {
  console.log("refresh settings")
  let scholar_year = get('current_year'),
    school = get('school'),
    users_ids = "";
  if(students_ids)
    users_ids = students_ids;
  else if(school_id)
    users_ids = Object.keys(global.all_students).filter(std_key => global.all_students[std_key].school_id == school_id)
  else
    users_ids = Object.keys(global.all_students)

  let data4 = {
    action: "get_recap_payments_students",
    users_ids: users_ids.join(','),
    scholar_year_id: scholar_year.id,
    group_id: school.group_id
  }
  await school_payment_api_store(data4).then(res=> {
    var last_type = false, last_student = false, last_month = false;
    set("settings_version", res.settings_version);
    res.students2.sort((a, b) => a[0] - b[0]).forEach(row => {
      let std = global.all_students[row[0]];
      if(last_student != row[0]){
        if(last_student){
          global.all_students[last_student].types = global.all_students[last_student].types.filter(t => t.is_ok).map(t => {
            t.months = t.months.filter(m => m.is_ok)
            return t;
          });
  
          global.all_students[last_student].months = global.all_students[last_student].months.filter(m => m.is_ok).map(m => {
            m.types = m.types.filter(t => t.is_ok)
            return m;
          });
        }
        std.types.forEach(t => {
          t.is_ok = false;
          t.months.forEach(m => {
            m.is_ok = false;
          }) 
        })
        std.months.forEach(m => {
          m.is_ok = false;
          m.types.forEach(t => {
            t.is_ok = false;
          }) 
        })
      }
      let gray = row[3] > 0 && ((!std.is_active && moment(std.date_depart) <= (row[3]>0?moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-01`):moment(scholar_year.start_year + '-08-01')))
            ||  (std.date_entering && row[3] > 0 && moment(std.date_entering) > moment(`${row[3]>=9?scholar_year.start_year:scholar_year.start_year + 1}-${row[3]}-15`).endOf('month')));

      if(!gray){

        let transfer_month = std.transfer_levels.find(tr => tr.month == row[3]);

        if(transfer_month && transfer_month.level_id == row[1] && (!row[6] || transfer_month.school_id == row[6])){

          if(last_student != row[0]){
            last_student = row[0];
            last_type = false;
            last_month = false;
          }

          //types
          if(!last_type || last_type.type_id != row[2]){
            let type = std.types.find(t => t.slug == row[5] || t.type_id == row[2])
            if(type)
              last_type = type
            else {
              std.types.push({
                slug: row[5],
                type_id: row[2],
                school_id: row[6],
                months: [],
                is_active: true
              })
              last_type = std.types[std.types.length - 1]
            }
          }
          last_type.is_ok = true;
          let month = last_type.months.find(m => m.month == row[3])
          if(month){
            month.total_price = row[4]
            month.total_base = row[7]
            month.is_ok = true;
          }
          else
            last_type.months.push({
              level: row[1],
              month: row[3],
              total_price: row[4],
              total_base: row[7],
              payed_price: 0,
              is_active: true,
              is_ok: true
            })
          
          //months
          if(!last_month || last_month.month != row[3]){
            let month2 = std.months.find(t => t.month == row[3])
            
            if(month2)
              last_month = month2
            else {
              std.months.push({
                month: row[3],
                types: []
              })
              last_month = std.months[std.months.length - 1]
            }

            last_month.is_ok = true
          }
          let type2 = last_month.types.find(t => t.type_id == row[2] || t.slug == row[5])
          
          if(type2){
            type2.to_pay = row[4]
            type2.is_ok = true;
          }
          else
            last_month.types.push({
              type_id: row[2],
              school_id: row[6],
              slug: row[5],
              to_pay: row[4],
              payed: 0,
              is_active: true,
              is_ok: true
            })
        }
      }
    })

    if(last_student){
      global.all_students[last_student].types = global.all_students[last_student].types.filter(t => t.is_ok).map(t => {
        t.months = t.months.filter(m => m.is_ok)
        return t;
      });

      global.all_students[last_student].months = global.all_students[last_student].months.filter(m => m.is_ok).map(m => {
        m.types = m.types.filter(t => t.is_ok)
        return m;
      });
    }
  });
  
  document.dispatchEvent(event);
  localforage.setItem('group_' + school.group_id, global.all_students)
}

const init_ws_notification = () => {
  let scholar_year = get('current_year');
  if(global.ws_notification){
    global.ws_notification.onopen = e => {
      console.log(new Date(), "ws connection success")
    }

    global.ws_notification.onmessage = e => {
      let data = JSON.parse(e.data)
      console.log(new Date(), data)
      if(data['action'] == 'update_setting' && parseInt(data['scholar_year_id']) == scholar_year.id)
        refresh_setting([data['student_id']], false)
      if(data['action'] == 'update_setting2' && parseInt(data['scholar_year_id']) == scholar_year.id)
        refresh_setting(data['students_ids'].split(','), false)
      else if(data['action'] == 'update_global_setting' && parseInt(data['scholar_year_id']) == scholar_year.id)
        refresh_setting(false, data['school_id'])
      else if(data['action'] == 'update_payment' && parseInt(data['scholar_year_id']) == scholar_year.id)
        refresh_payment(data['payment_id'], data['students_ids'])
      else if(data['action'] == 'remove_payment' && parseInt(data['scholar_year_id']) == scholar_year.id)
        refresh_payment(false, data['students_ids'])
      else if(data['action'] == 'update_student' && parseInt(data['scholar_year_id']) == scholar_year.id)
        refresh_group(data['school_id'], data['students_ids'] + "")
      // else if(data['action'] == 'add_student' && data['scholar_year_id'] == scholar_year.id)
      //   refresh_student(false)
    }

    global.ws_notification.onclose = (e) => {
      console.log(new Date(), 'Socket is closed. Reconnect will be attempted.', e.reason);
      // setTimeout(() => {
      let user = get('session_user'),
        school = get('school');
      if(user){
        global.ws_notification = new WebSocket(
          `${process.env.REACT_APP_URL_MESSAGING}/ws/users/?user_id=${user.profile.user_id}&group_id=${school.group_id}`
        );
        init_ws_notification();
      }
      else{
        global.ws_notification = false;
      }
      // }, 500);
    };

  }
}
